import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { MessageList } from "../components/MessageList";
import SearchPanel from "../components/SearchPanel";

import { store } from "../context/store";

import { useActions } from "../context/actions";
import { useKeyPress } from "../useKeyPress";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { sortData, scrollToTop, addDelay, findNode } from "../utils";

import { Poll } from "../components/Poll";
import { Form } from "react-bootstrap";

import {
  filterType,
  sortColumn,
  columnType,
  hotKeyMap,
  ALL_HOTKEYS,
} from "../types";
import { Message } from "../components/Message";
import { Playlist } from "../components/Playlist";

import fireflyNav from "../assets/fireflyNav.png";
import loadingimg from "../assets/loading_spinner.png";

export const ViewGroup = () => {
  // Hooks
  const actions = useActions();
  const { state } = useContext(store);
  // refer to the context state as just context to avoid
  // confusion between context and component state
  const context = state;
  const filterRef = useRef<any>();
  const { id } = useParams<{ id: string }>();

  // Data
  const [gridData, setGridData] = useState<any | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [filter, setFilter] = useState<filterType>("Inbox");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [allGroupFilter, setAllGroupFilter] = useState(false);
  const [loadingGroupMessages, setLoadingGroupMessages] = useState(false);

  // Modals

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showPlayList, setShowPlayList] = useState<boolean>(false);
  const [activeMessage, setActiveMessage] = useState<any | null>(null);

  const [seekTime, setSeekTime] = useState(0);
  const [showTranscripts, setShowTranscripts] = useState(false);

  //////////////////////////////////////////////////////////////////
  // Sorting
  //////////////////////////////////////////////////////////////////
  const defaultSort: sortColumn = {
    column: {
      title: "Sent On",
      value: "CreatedDate",
      className: "ffDate",
      sortType: "date",
      visible: true,
    },
    direction: "desc",
  };

  const [sort, setSort] = useState<sortColumn>(defaultSort);

  //////////////////////////////////////////////////////////////////
  // Polling
  //////////////////////////////////////////////////////////////////
  const MAX_POLLING_COUNT: number = 10;
  const [pollingInterval, setPollingInterval] = useState<number>(0);
  const [pollingCount, setPollingCount] = useState<number>(0);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // if we are not polling set the interval to zero which will
  // stop polling, otherwise set it to POLLING_INTERVAL
  useEffect(() => {
    const POLLING_INTERVAL: number = 500 * 60 * 1;
    let _pollingInterval = context.enablePolling ? POLLING_INTERVAL : 0;
    setPollingInterval(_pollingInterval);
  }, [context.enablePolling]);

  Poll(() => {
    let _pollingCount = pollingCount + 1;
    console.log("polling count", _pollingCount, context.enablePolling);
    setPollingCount(_pollingCount);
    if (_pollingCount >= MAX_POLLING_COUNT) {
      actions.SetEnablePolling(false);
    } else {
      // only call getgriddata when enable polling is set
      // because that method will set enable polling to true
      // if there exists messages in not sent status
      if (context.enablePolling) {
        if (
          filter !== "Inbox" &&
          filter !== "ADO" &&
          filter !== "Sent" &&
          filter !== "Public"
        ) {
          //Group message polling
          handleGroupMessages(filter);
        }
      }
    }
  }, pollingInterval);

  //////////////////////////////////////////////////////////////////
  // Hot Keys
  //////////////////////////////////////////////////////////////////

  const [hotKey, setHotKey] = useState<{ value: ALL_HOTKEYS }>({
    value: ALL_HOTKEYS.Empty,
  });

  const hotKeyMap: hotKeyMap = {
    F: ALL_HOTKEYS.New,
    ArrowUp: ALL_HOTKEYS.ArrowUp,
    ArrowDown: ALL_HOTKEYS.ArrowDown,
    ArrowLeft: ALL_HOTKEYS.ArrowLeft,
    ArrowRight: ALL_HOTKEYS.ArrowRight,
    Enter: ALL_HOTKEYS.View,
    " ": ALL_HOTKEYS.Check,
    A: ALL_HOTKEYS.CheckAll,
    C: ALL_HOTKEYS.Copy,
    K: ALL_HOTKEYS.Filter,
    T: ALL_HOTKEYS.PDF,
  };

  useKeyPress(
    (key: string) => {
      switch (hotKeyMap[key]) {
        case ALL_HOTKEYS.Filter:
          filterRef.current.focus();
          break;
        default:
          // we need to wrap in an object for react to register a change
          setHotKey((prev: any) => ({ ...prev, value: hotKeyMap[key] }));
      }
    },
    Object.keys(hotKeyMap),
    hotKeyMap
  );

  //////////////////////////////////////////////////////////////////
  // Group ID passed to page
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!gridData && context.isLoggedIn && context.isSetup && id) {
      setIsAllSelected(false);
      let x: any = id;
      setFilter(x);
      setAllGroupFilter(false);
      handleGroupMessages(x);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, context.isLoggedIn, context.isSetup, id]);

  //////////////////////////////////////////////////////////////////
  // Group Messages
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (
      context.groupMessages &&
      Array.isArray(context.groupMessages) &&
      filter !== "Inbox" &&
      filter !== "ADO" &&
      filter !== "Sent" &&
      filter !== "Public"
    ) {
      let filteredGridData = [...context.groupMessages];

      // Filter based on search string
      if (searchString)
        filteredGridData = filteredGridData.filter((entry: any) =>
          Object.values(entry).some(
            (val) =>
              typeof val === "string" &&
              val.toLowerCase().includes(searchString.toLowerCase())
          )
        );

      // Restore the checked rows that pass the filter criteria
      // this will also reset checks when user selects a different
      // left nav (filter) item
      if (gridData) {
        let foundCount = 0;
        let checkedIds = gridData
          .filter((entry: any) => {
            return entry.checked;
          })
          .map((item: any) => item.Id);

        filteredGridData = filteredGridData.map((item: any) => {
          if (checkedIds.includes(item.Id)) {
            item.checked = true;
            foundCount++;
          } else item.checked = false;
          return item;
        });

        // handle the select all checkbox
        setIsAllSelected(foundCount === filteredGridData.length);
        setCheckCount(foundCount);
      }

      setGridData(sortData(sort, filteredGridData));
      setLoadingGroupMessages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, context.groupMessages, sort, filter, allGroupFilter]);

  //////////////////////////////////////////////////////////////////
  // event handlers
  //////////////////////////////////////////////////////////////////
  const handleSort = (column: columnType) => {
    if (gridData) {
      if (column.sortType !== "none") {
        const direction = sort.direction === "asc" ? "desc" : "asc";
        setSort({ column, direction });
      }
    }
  };

  const handleSearch = ({ target }: any) => {
    let { value } = target;
    setSearchString(value);

    // scroll to the top on page change
    scrollToTop();
  };

  const handleGroupMessages = (id: string) => {
    context.groupMessages = {};
    setLoadingGroupMessages(true);
    actions.GetMessagesByGroup(id);
  };

  const handleDelete = (id: string) => {
    return;
  };

  const handleDownload = (id: string) => {
    return;
  };

  const getPlayList = () => {
    // let checkCount = gridData.reduce((total: number, curr: any) => {
    //   return curr.checked ? total + 1 : total;
    // }, 0);
    if (gridData) {
      let playList = gridData
        .filter((entry: any) => {
          return entry.checked;
        })
        .map((item: any) => {
          return {
            src: [item.StreamingUrl],
            thumbnail: item.ThumbnailUrl,
            title: item.Subject,
          };
        });

      return playList;
    } else return null;
  };
  const handleView = (id: string) => {
    if (gridData) {
      let checkCount = gridData.reduce((total: number, curr: any) => {
        return curr.checked ? total + 1 : total;
      }, 0);

      if (checkCount > 1) {
        setShowPlayList(true);
        return;
      }

      let messages = [...gridData];
      let message = findNode(messages, id);

      // sometimes you receive a message not directed to you so we have to look it up
      if (!message)
        actions.GetMessage(id).then((response: any) => {
          delayShow(response);
        });
      // it's a message that should be in your list
      else {
        // un-bold the message
        if (message.New) {
          message.New = false;
          setGridData(messages);
        }

        delayShow(message);
      }
    } else {
      actions.GetMessage(id).then((response: any) => {
        delayShow(response);
      });
    }
  };

  const handleTranscriptPDF = (id: string) => {
    if (gridData) {
      let checkCount = gridData.reduce((total: number, curr: any) => {
        return curr.checked ? total + 1 : total;
      }, 0);

      if (checkCount > 1) {
        let selectedMessages = gridData.filter((entry: any) => {
          return entry.checked;
        });

        let transcripts = "";

        selectedMessages.forEach((message: any) => {
          if (message.Transcript) {
            transcripts += message.Transcript.trim() + "\n\n";
          }
        });

        var docDefinition = {
          content: [transcripts],
        };

        pdfMake.createPdf(docDefinition).download(`FF-Transcripts.pdf`);
        return;
      }

      let messages = [...gridData];
      let message = findNode(messages, id);

      if (message.Transcript) {
        var docDefinitionMessage = {
          content: [message.Transcript.trim()],
        };

        pdfMake
          .createPdf(docDefinitionMessage)
          .download(`FF-${message.Id}.pdf`);
      }
    }
  };

  const handleTree = (id: string) => {
    if (gridData) {
      let _messages = [...gridData];

      let message = findNode(_messages, id);

      if (message) {
        let _expandClass =
          message.ExpandClass === "collapsed" ? "expanded" : "collapsed";
        message.ExpandClass = _expandClass;
        message.Replies.forEach((child: any) => {
          child.Hidden = _expandClass === "collapsed" ? true : false;
        });
        setGridData(_messages);
      }
    }
  };

  const handleViewedBy = (message: any) => {
    let viewMsgRequest: object = [
      {
        Type: "email",
        Name: `${context.account.EmailAddress}`,
        UserId: `${context.account.UserId}`,
      },
    ];

    actions.UpdateMessage(message.Id, viewMsgRequest).then((response: any) => {
      console.log(`Message viewed by: ${context.account.EmailAddress}`);
    });
  };

  const handleReply = (id: string) => {
    return;
  };

  // handles the checkboxes
  const onCheckBoxChange = (checkName: string, isChecked: boolean) => {
    let isAllChecked = checkName === "all" && isChecked;
    let isAllUnChecked = checkName === "all" && !isChecked;
    const checked = isChecked;

    const checkList = gridData.map((row: { Id: string }, index: any) => {
      if (isAllChecked || row.Id === checkName) {
        return Object.assign({}, row, {
          checked,
        });
      } else if (isAllUnChecked) {
        return Object.assign({}, row, {
          checked: false,
        });
      }

      return row;
    });

    let _isAllSelected =
      checkList.findIndex(
        (item: { checked: boolean }) => item.checked === false
      ) === -1 || isAllChecked;

    // get the number of checked items
    let _checkCount = checkList.reduce((total: number, curr: any) => {
      return curr.checked ? total + 1 : total;
    }, 0);
    setCheckCount(_checkCount);

    setGridData(checkList);
    setIsAllSelected(_isAllSelected);
  };

  const delayShow = (message: any) => {
    // delay before showing
    addDelay(
      200,
      () => {
        setShowMessage(true);
      },
      () => {
        setActiveMessage(message);
      }
    );
  };
  return (
    <>
      <div className="topright">
        <Link
          className="navLink ml-2"
          to="/list"
          target="_blank"
          title="Powered by Firefly"
        >
          <img width="125px" alt="FireFly" src={fireflyNav} />
        </Link>

        <SearchPanel
          searchString={searchString}
          handleSearch={handleSearch}
          filterRef={filterRef}
          setGridData={setGridData}
          isAllGroupFilter={allGroupFilter}
          filter={filter}
          handleGroupMessages={handleGroupMessages}
        />

        <article className="column-toggles-panel">
          <header>
            <Form.Check
              inline
              label="Show Message Transcripts"
              type="switch"
              id="showTranscriptsSwitch"
              checked={showTranscripts}
              onChange={(event: any) => {
                setShowTranscripts(event.target.checked);
              }}
            />
          </header>
        </article>
      </div>

      {(loadingGroupMessages || !Array.isArray(gridData)) && (
        <img
          className={`${
            loadingGroupMessages ? "workingRev mt-9  center-me" : "workingSide"
          }`}
          alt="working"
          src={loadingimg}
        />
      )}

      <main className="content">
        {gridData && (
          <MessageList
            messages={gridData}
            handleSort={handleSort}
            handleView={handleView}
            handleTree={handleTree}
            handleReply={handleReply}
            handleDelete={handleDelete}
            handleCheck={onCheckBoxChange}
            handleDownload={handleDownload}
            sort={sort}
            hotKey={hotKey}
            isAllSelected={isAllSelected}
            filter={filter + searchString}
            checkCount={checkCount}
            keywordSearch={searchString}
            showTranscripts={showTranscripts}
            handleTranscriptPDF={handleTranscriptPDF}
          />
        )}
      </main>
      <Modal
        show={showMessage || showPlayList}
        onHide={() => {
          setShowMessage(false);
          setShowPlayList(false);

          // refresh if it's a new message
          if (activeMessage && !findNode(gridData, activeMessage.Id)) {
            //refresh for either Personal or Group messages
            if (
              filter !== "Inbox" &&
              filter !== "ADO" &&
              filter !== "Sent" &&
              filter !== "Public"
            ) {
              //Group message
              handleGroupMessages(filter);
            }
          }
        }}
        animation={false}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header className="img-grid" closeButton>
          <h5 className="modal-title">
            {activeMessage && showMessage ? (
              <>
                {activeMessage.Subject}
                {activeMessage.From && <> &nbsp; ({activeMessage.From})</>}
              </>
            ) : (
              <>Playing {checkCount} Fireflies</>
            )}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {activeMessage && showMessage ? (
            <Message
              message={activeMessage}
              seekTime={seekTime}
              handleReply={handleReply}
              handleViewedBy={handleViewedBy}
              keywordSearch={searchString}
            />
          ) : (
            <Playlist srcList={getPlayList()} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
