import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Form, Col } from "react-bootstrap";

import FireFly from "../components/FireFly";

import { useKeyPress } from "../useKeyPress";
import { useActions } from "../context/actions";
import {
  NewMessageProps,
  hotKeyMap,
  ALL_HOTKEYS,
  TeamMember,
  Group,
} from "../types";

import { store } from "../context/store";
import EmailList from "../components/EmailList";

import { addDelay } from "../utils";

import {
  useRecordWebcam,
  CAMERA_STATUS,
  CAPTURE_TYPE,
} from "../components/react-record-webcam/src";

import type { RecordWebcamHook } from "../components/react-record-webcam/src";

import MultipleLevelSelection from "../components/MultipleLevelSelection";

let localFileBlob: Blob | undefined = undefined;
let localFileIs4K: boolean = false;

export const NewMessage = forwardRef(
  (
    { onSave, message, suggestions, autoRecordType }: NewMessageProps,
    ref: any
  ) => {
    const recordWebcam: RecordWebcamHook = useRecordWebcam();
    const actions = useActions();
    const { state } = useContext(store);
    // refer to the context state as just context to avoid
    // confusion between context and component state
    const context = state;

    const [validated, setValidated] = useState(false);
    const [invalidWorkItemAssign, setInvalidWorkItemAssign] = useState(false);
    const [email, setEmail] = useState<string[]>([]);
    const [subject, setSubject] = useState<string>("");
    const [workItemId, setWorkItemId] = useState<string>("");
    const [workItemType, setWorkItemType] = useState<string>("STORY");
    const [disableProjects, setdisableProjects] = useState(false);
    const [workItemAssign, setWorkItemAssign] = useState<string>("UNASSIGNED");
    const [teamMember, setTeamMember] = useState<TeamMember>();
    const [groups, setGroups] = useState<Group[] | undefined>(context.groups);
    const [noGroup, setNoGroup] = useState(false);
    const [autoStartRecord, setAutoStartRecord] = useState(
      autoRecordType ?? false
    );
    const [target, setTarget] = useState<string>("email");
    const [projectName, setProjectName] = useState<string>(
      context && context.projects ? context.projects[0].Name : ""
    );

    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [hotKey, setHotKey] = useState<ALL_HOTKEYS>(ALL_HOTKEYS.Empty);
    const [useAI, setUseAI] = useState<boolean>(
      JSON.parse(localStorage.getItem("ffUseAI") as string)
    );

    const hotKeyMap: hotKeyMap = {
      R: ALL_HOTKEYS.Record,
      X: ALL_HOTKEYS.Stop,
      T: ALL_HOTKEYS.Retake,
      C: ALL_HOTKEYS.Camera,
      S: ALL_HOTKEYS.Screen,
      L: ALL_HOTKEYS.Local,
      A: ALL_HOTKEYS.Audio,
      P: ALL_HOTKEYS.Pause,
      M: ALL_HOTKEYS.Resume,
    };

    useKeyPress(
      (key: string) => {
        setHotKey(hotKeyMap[key]);
      },
      Object.keys(hotKeyMap),
      hotKeyMap
    );

    useEffect(() => {
      if (message) {
        setTarget(message.Targets[0].Type);

        if (message.Targets[0].Type === "project") {
          setWorkItemId(message.Targets[0].Id);
          setWorkItemType(message.Targets[0].WorkItemType);
          setWorkItemAssign(
            message.Targets[0].AssignToUser !== "" ? "ASSIGNTO" : "UNASSIGNED"
          );
        }

        // get all emails that aren't yours
        const { EmailAddress } = context.account;

        let emails: string[] = message.Targets.filter(
          (target: { Type: string; Name: any }) =>
            target.Type === "email" && target.Name !== EmailAddress
        ).map((target: any) => {
          return target.Name;
        });
        //console.log(emails, EmailAddress, message.Targets);

        // only add the from if it isn't your email
        if (message.From !== EmailAddress) emails.push(message.From);

        setEmail(emails ?? ["noreply@murphyandassoc.com"]);

        //Get groups that are assigned to this FF
        let groups: Group[] = message.Targets.filter(
          (target: { Type: string; Name: any }) => target.Type === "group"
        ).map((group: any) => {
          return Object.assign(group, { IsSelected: true });
        });

        setGroups(groups);

        let newSubject: string = message.Subject;

        if (newSubject.toUpperCase().startsWith("RE:")) {
          setSubject(message.Subject);
        } else {
          setSubject("RE: " + message.Subject);
        }

        //console.log(message);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(() => {
      // Trigger the hotkey when the component mounts
      if (autoStartRecord) {
        if (recordWebcam.status === CAMERA_STATUS.OPEN) {
          switch (autoRecordType) {
            case CAPTURE_TYPE.CAMERA:
              triggerHotkey("C");
              addDelay(200, () => {
                triggerHotkey("R");
              });
              setAutoStartRecord(false);
              break;
            case CAPTURE_TYPE.SCREEN:
              triggerHotkey("S");
              setAutoStartRecord(false);
              break;
            case CAPTURE_TYPE.IMPORT:
              triggerHotkey("L");
              setAutoStartRecord(false);
              break;
            case CAPTURE_TYPE.AUDIO:
              triggerHotkey("A");
              addDelay(200, () => {
                triggerHotkey("R");
              });
              setAutoStartRecord(false);
              break;
            default:
              break;
          }
        }
      }
    }, [autoStartRecord, recordWebcam.status, autoRecordType]);

    // enable parent to call child function
    useImperativeHandle(ref, () => ({
      close() {
        if (recordWebcam.status === CAMERA_STATUS.RECORDING) {
          recordWebcam.stopStream();
        }

        if (
          recordWebcam.status === CAMERA_STATUS.OPEN ||
          recordWebcam.status === CAMERA_STATUS.AUDIO
        ) {
          recordWebcam.close();
        }
      },
    }));

    //Azure Storage config
    const { BlobServiceClient } = require("@azure/storage-blob");
    const { v1: uuidv1 } = require("uuid");
    const appConfig = require("@azure/app-configuration");

    const connectionString =
      process.env["REACT_APP_APPCONFIG_CONNECTION_STRING"] ||
      "<connection string>";

    const appConfigclient = new appConfig.AppConfigurationClient(
      connectionString
    );

    const getAzureAppConfigSetting = async (settingKey: string) => {
      let retrievedSetting = await appConfigclient.getConfigurationSetting({
        key: settingKey,
        label:
          process.env["REACT_APP_ENVIRONMENT"] === "DEV"
            ? null
            : process.env["REACT_APP_ENVIRONMENT"],
      });

      return retrievedSetting.value as string;
    };

    const triggerHotkey = (hotKey: string) => {
      const customEvent = new KeyboardEvent("keydown", {
        key: hotKey,
        ctrlKey: false,
      });
      window.dispatchEvent(customEvent);
    };

    const saveMessage = async (storageLocator: string | undefined) => {
      var _targets =
        target === "public"
          ? [
              {
                type: target,
                name: "public",
                id: "",
              },
            ]
          : target === "email"
          ? email.map((e) => ({
              type: "email",
              name: e,
              id: "",
            }))
          : target === "group"
          ? groups
              ?.filter((e) => e.IsSelected === true)
              .map((e) => ({
                type: "group",
                name: e.Name,
                id: e.Id,
              }))
          : [
              {
                type: target,
                name: projectName,
                id: workItemId,
                workItemType: workItemType,
                assignToUser: teamMember ? teamMember.email : "",
              },
            ];

      let msgRequest: object = {
        id: storageLocator,
        userId: context.account.UserId,
        from: context.account.EmailAddress,
        subject,
        recordingType: recordWebcam.captureType,
        incomingLocation: storageLocator,
        targets: _targets,
        parentId: message ? message.Id : null,
        aiEnabled: useAI,
        is4K:
          recordWebcam.captureType === CAPTURE_TYPE.IMPORT
            ? localFileIs4K
            : false,
      };

      console.log(msgRequest);

      actions.CreateMessage(msgRequest).then(function (data) {
        console.log("Created message:", data);
      });
    };

    const uploadFile = async (): Promise<string | undefined> => {
      try {
        setShowUpload(true);
        const uploadSASEndpoint: string = await getAzureAppConfigSetting(
          "FireflyWeb:UploadSASEndpoint"
        );

        console.log("Uploading file...");

        // Create a unique name for the blob
        const messageId = uuidv1();

        const blobServiceClient = new BlobServiceClient(uploadSASEndpoint);
        const containerName = "incoming";
        const containerClient =
          blobServiceClient.getContainerClient(containerName);

        const blobName = messageId + "=FF=" + context.account.UserId;

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        console.log(localFileBlob);

        let blob: any =
          recordWebcam.captureType === CAPTURE_TYPE.IMPORT
            ? localFileBlob
            : await recordWebcam.getRecording();

        console.log(`Blob size ${blob.size}} - Type: ${blob.type}`);
        let fileBytes: number = parseInt(blob.size);
        let uploadedBytes: number = 0;

        const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
          blob,
          {
            blockSize: 4 * 1024 * 1024, // 4MB block size
            concurrency: 20, // 20 concurrency
            onProgress: (ev: any) => {
              console.log(ev);
              uploadedBytes = ev.loadedBytes;
              setProgress(Math.round((uploadedBytes / fileBytes) * 100));
            },
            blobHTTPHeaders: { blobContentType: blob.type },
          }
        );
        console.log(uploadBlobResponse);
        return messageId;
      } catch (error: any) {
        console.log(error.message);
      }
    };

    const handleSubmit = (event: any) => {
      const form = event.currentTarget;

      if (
        form.checkValidity() === false ||
        (target === "email" && email.length === 0) ||
        (target === "project" &&
          workItemAssign === "ASSIGNTO" &&
          teamMember === undefined) ||
        (target === "group" &&
          groups?.filter((e) => e.IsSelected === true).length === 0)
      ) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        if (
          target === "project" &&
          workItemAssign === "ASSIGNTO" &&
          teamMember === undefined
        ) {
          setInvalidWorkItemAssign(true);
        } else {
          setInvalidWorkItemAssign(false);
        }

        if (
          target === "group" &&
          groups?.filter((e) => e.IsSelected === true).length === 0
        ) {
          setNoGroup(true);
        } else {
          setNoGroup(false);
        }
      } else {
        event.preventDefault();
        uploadFile().then((storageLocator) => {
          saveMessage(storageLocator).then(() => {
            setValidated(false);
            var counter = setInterval(() => {
              clearInterval(counter);
              setShowUpload(false);
              if (onSave) {
                if (storageLocator) onSave(storageLocator);
              }
            }, 1000);
            console.log("Saved Message to DB...");
          });
        });
      }

      setValidated(true);
      event.preventDefault();
    };

    const handleLocalFile = (blob: Blob, is4K: boolean) => {
      localFileBlob = blob;
      localFileIs4K = is4K;
      recordWebcam.setStatus(CAMERA_STATUS.PREVIEW);
    };

    const handleAISetting = (aiEnabled: boolean) => {
      if (aiEnabled != null) setUseAI(aiEnabled);
    };

    const handleGroupsOnChange = (grp: Group) => {
      const updatedCheckedState = groups?.map((item, index) =>
        item.Id === grp.Id
          ? Object.assign(item, { IsSelected: !item.IsSelected })
          : item
      );

      setGroups(updatedCheckedState);
    };

    const getTeamMembersByParentId = (
      parentId: string | number
    ): TeamMember[] => {
      let cat = context.teams as TeamMember[];

      if (parentId === 0) {
        cat = cat.filter((teamMember) => teamMember.name === `${projectName}`);
      } else {
        cat = cat.filter((teamMember) => teamMember.parentId === `${parentId}`);
      }

      return cat;
    };

    return (
      <>
        <div className="row">
          {/* <h3>Create Firefly</h3> */}
          <FireFly
            recordWebcam={recordWebcam}
            uploading={showUpload}
            progress={progress}
            hotKey={hotKey}
            getLocalFile={handleLocalFile}
            setAISetting={handleAISetting}
            aiActive={useAI}
          />
        </div>
        {recordWebcam.status === CAMERA_STATUS.PREVIEW && (
          <div className="row">
            <div className="col-sm">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="videoMsg">
                  <Form.Row className="mt-4">
                    <Form.Group as={Col} controlId="subject">
                      <Form.Control
                        required={useAI && target !== "project" ? false : true}
                        className="message-box"
                        type="text"
                        placeholder={
                          useAI && target !== "project"
                            ? "Subject will be provided by AI, if not entered"
                            : target === "project"
                            ? "Subject (Title of ADO Work Item)"
                            : "Subject"
                        }
                        onChange={(event: any) =>
                          setSubject(event.target.value)
                        }
                        value={subject}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a subject.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="target">
                      <Form.Control
                        as="select"
                        className="message-box"
                        onChange={(event: any) => {
                          setTarget(event.target.value);
                        }}
                        disabled={message ? true : false}
                        value={target}
                      >
                        {context && context.projects && (
                          <option value="project">ADO</option>
                        )}

                        <option value="email">Email</option>
                        <option value="public">Public</option>
                        {context && context.groups && (
                          <option value="group">Group</option>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  {target === "group" && noGroup && (
                    <Form.Row>
                      <Col>
                        <span className="error">Select at least one Group</span>
                      </Col>
                    </Form.Row>
                  )}

                  {target === "project" && (
                    <>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          name="workItemType"
                          className="radioGroupHighlight text-center"
                        >
                          <Form.Check
                            inline
                            label="User Story"
                            name="groupWItemType"
                            type="radio"
                            // eslint-disable-next-line eqeqeq
                            checked={workItemType === "STORY"}
                            onChange={(event: any) => {
                              setWorkItemType(event.target.value);
                            }}
                            value={"STORY"}
                            disabled={message ? true : false}
                            id="inline-radio-1"
                          />
                          <Form.Check
                            inline
                            label="Bug"
                            name="groupWItemType"
                            type="radio"
                            checked={workItemType === "BUG"}
                            onChange={(event: any) => {
                              setWorkItemType(event.target.value);
                            }}
                            value={"BUG"}
                            disabled={message ? true : false}
                            id="inline-radio-2"
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          name="workItemAssign"
                          className="radioGroupHighlight text-center"
                        >
                          <Form.Check
                            inline
                            label="Unassigned"
                            name="groupWItemAssign"
                            type="radio"
                            // eslint-disable-next-line eqeqeq
                            checked={workItemAssign === "UNASSIGNED"}
                            onChange={(event: any) => {
                              setWorkItemAssign(event.target.value);
                              setTeamMember(undefined);

                              if (event.target.value === "ASSIGNTO") {
                                setdisableProjects(true);
                              } else {
                                setdisableProjects(false);
                              }
                            }}
                            value={"UNASSIGNED"}
                            disabled={message ? true : false}
                            id="inline-radio-assign-1"
                          />
                          <Form.Check
                            inline
                            label="Assign To"
                            name="groupWItemAssign"
                            type="radio"
                            checked={workItemAssign === "ASSIGNTO"}
                            onChange={(event: any) => {
                              setWorkItemAssign(event.target.value);

                              if (event.target.value === "ASSIGNTO")
                                setdisableProjects(true);
                              else setdisableProjects(false);
                            }}
                            value={"ASSIGNTO"}
                            disabled={message ? true : false}
                            id="inline-radio-assign-2"
                          />
                        </Form.Group>

                        <Form.Group as={Col} name="selectAudio">
                          <Form.Control
                            as="select"
                            className="message-box"
                            placeholder="select board"
                            onChange={(event: any) => {
                              setProjectName(event.target.value);
                            }}
                            value={projectName}
                            disabled={message || disableProjects ? true : false}
                          >
                            {context &&
                              context.projects &&
                              context.projects.map((_target: any) => (
                                <option key={_target.Name} value={_target.Name}>
                                  {_target.Name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} name="assignUser">
                          {workItemAssign === "ASSIGNTO" && !message && (
                            <>
                              <MultipleLevelSelection
                                initialItems={getTeamMembersByParentId(0)}
                                getItemKey={(item) => item.Id}
                                getItemLabel={(item) => item.name}
                                getNestedItems={(item) =>
                                  getTeamMembersByParentId(item.Id)
                                }
                                hasNestedItems={(_, level) => level < 3}
                                isValid={!invalidWorkItemAssign}
                                isEqual={(item, item2) =>
                                  item?.Id === item2?.Id
                                }
                                placeholder="Choose team member"
                                onChange={(item) => {
                                  setTeamMember(item);
                                  setInvalidWorkItemAssign(false);
                                }}
                              />
                              {invalidWorkItemAssign && (
                                <div className="mls-invalid">
                                  Please select a team member
                                </div>
                              )}
                            </>
                          )}

                          {workItemAssign === "ASSIGNTO" && message && (
                            <Form.Label>
                              Assigned To: {message.Targets[0].AssignToUser}
                            </Form.Label>
                          )}
                        </Form.Group>
                      </Form.Row>
                    </>
                  )}
                  <Form.Row>
                    {target === "email" && (
                      <Col>
                        <EmailList
                          emails={email}
                          onChange={(_emails: string[]) => {
                            setEmail(_emails);
                          }}
                          validating={validated}
                          suggestions={suggestions}
                        />
                      </Col>
                    )}
                    {target === "group" && (
                      <>
                        <Form.Group as={Col} name="assignGroups">
                          {context.groups &&
                            context.groups.map(
                              (_group: Group, index: number) => (
                                <Form.Check
                                  inline
                                  key={_group.Id}
                                  className={noGroup ? "" : ""}
                                  label={_group.Name}
                                  name={_group.Id}
                                  type="switch"
                                  checked={_group.IsSelected}
                                  onChange={() => handleGroupsOnChange(_group)}
                                  value={_group.Id}
                                  disabled={message ? true : false}
                                  id={_group.Id}
                                />
                              )
                            )}
                        </Form.Group>
                      </>
                    )}
                    <Col xs="auto">
                      <Button variant="success" type="submit" className="mb-2">
                        Send it!
                      </Button>
                    </Col>
                  </Form.Row>
                </Form.Group>
              </Form>
            </div>
          </div>
        )}
      </>
    );
  }
);
