type RecordingType = "video" | "audio";
type RecordingMimeType =
  | "video/mp4"
  | "audio/webm"
  | "video/webm;codecs=vp9"
  | "video/webm;codecs=vp8"
  | "video/mp4;codecs=avc1,mp4a.40.2"
  | "video/mp4;codecs=avc1,opus"
  | "video/x-matroska;codecs=avc1"
  | "video/webm;codecs=h264";

export type DeviceTypes = {
  [key in RecordingType]: MediaDeviceInfo[];
};
type exactSource = {
  exact: string;
};

export type MediaSources = {
  audioSource: string | undefined;
  videoSource: string | undefined;
} | null;

export type AppSettings = {
  sources: MediaSources;
  durationSeconds: number;
  useAI: boolean;
  defaultVideoSourceId: string;
  defaultAudioSourceId: string;
};

export type CaptureConsrtaints = {
  audio: { deviceId?: exactSource | undefined };
  video?: {
    deviceId?: exactSource | undefined;
    width: number;
    height: number;
  };
};
export type RecorderOptions = {
  type: RecordingType;
  mimeType: RecordingMimeType;
  video?:
    | {
        minWidth: number;
        minHeight: number;
        maxWidth: number;
        maxHeight: number;
        minAspectRatio: number;
      }
    | undefined;

  timeSlice: number;
  onTimeStamp(timestamp: any, timestamps: any): void;
};

export type WebcamRenderProps = {
  status: string;
  captureType: string;
  isWebcamOn: boolean;
  isPreview: boolean;
  isRecording: boolean;
  openCamera(): void;
  closeCamera(): void;
  retake(): void;
  start(): void;
  stop(): void;
  pause(): void;
  resume(): void;
  download(): void;
  getRecording(): Blob | undefined;
  changeCaptureType(event: any): void;
};

export type RecordWebcamHook = {
  close(): void;
  download(): void;
  open(): void;
  retake(): void;
  getRecording(): void;
  start(): void;
  stop(): void;
  stopStream(): void;
  pause(): void;
  resume(): void;
  changeCaptureType(event: any): void;
  changeAudioSource(event: any): void;
  changeVideoSource(event: any): void;
  changeSources(sources: MediaSources): void;
  setStatus(status: CAMERA_STATUS): void;
  setDuration(duration: number, callback: () => void): void;
  getDevices(): Promise<DeviceTypes>;
  status: CAMERA_STATUS;
  captureType: CAPTURE_TYPE;
  audioSource: string | undefined;
  videoSource: string | undefined;
  sources: MediaSources;
  secondsRecorded: number;
  streamState: string;
  previewRef: React.RefObject<HTMLVideoElement>;
  webcamRef: React.RefObject<HTMLVideoElement>;
  cameraError: string | undefined;
};

export type Recorder = {
  startRecording(): Promise<void>;
  stopRecording(): Promise<void>;
  pauseRecording(): Promise<void>;
  resumeRecording(): Promise<void>;
  getDataURL(): Promise<string>;
  getBlob(): Promise<Blob>;
  setRecordingDuration(duration: number, callback: () => void): void;
  stream: any;
  secondaryStreams: any;
};

export enum CAMERA_STATUS {
  INIT = "INIT",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  RECORDING = "RECORDING",
  PREVIEW = "PREVIEW",
  LOCAL = "LOCAL",
  AUDIO = "AUDIO",
  ERROR = "ERROR",
  PAUSED = "PAUSED",
}

export enum CAPTURE_TYPE {
  SCREEN = "SCREEN",
  CAMERA = "CAMERA",
  IMPORT = "IMPORT",
  AUDIO = "AUDIO",
}

export enum BUTTON_LABELS {
  OPEN = "Open camera",
  CLOSE = "Close camera",
  START = "Start recording",
  STOP = "Stop recording",
  PAUSE = "Pause recording",
  RESUME = "Resume recording",
  RETAKE = "Retake recording",
  DOWNLOAD = "Download recording",
}

export enum NAMESPACES {
  CSS = "react-record-webcam",
}
