import { useContext, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { useAPI } from "../api";
import { protectedResources } from "../../authConfig";
import { store } from "../store";

import { expireToken } from "../token";
import { ManagedGroup } from "../../types";

export const useActions = () => {
  // const { instance, accounts, inProgress } = useMsal();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) || undefined;

  const globalState = useContext(store);
  const context = globalState.state;
  const api = useAPI();

  const { dispatch } = globalState;

  // useEffect(() => {
  //   if (account && inProgress === "none") {
  //     getAccessToken(GetToken).catch((error: any) => {
  //       console.log(error);
  //       // in case if silent token acquisition fails, fallback to an interactive method
  //       if (error instanceof InteractionRequiredAuthError) {
  //         if (account && inProgress === "none") {
  //           getAccessToken(GetTokenPopup).catch(() => {
  //             instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  //           });
  //         }
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [account, inProgress, instance]);

  useEffect(() => {
    if (!context.isLoggedIn) {
      Login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, context.isLoggedIn]);

  useEffect(() => {
    if (context.isLoggedIn && !context.isSetup) {
      Setup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isLoggedIn, context.isSetup]);

  // calls the original function if there is an error calls the
  // recovery function and then the original function again
  // const retryOnce = (func: any, recoverFunc: any) => {
  //   return func().catch((err: any) => {
  //     console.log(`err=${err}`);

  //     return recoverFunc(err)
  //       .then(() => func())
  //       .catch((error: any) => {
  //         // error on retry - either a session timeout auth error, or an API error
  //         console.log(`error=${error}`);

  //         // if we have an auth error try to popup, otherwise just logout
  //         if (JSON.stringify(err).includes("AuthError"))
  //           return instance
  //             .acquireTokenPopup({
  //               scopes: protectedResources.apiLogin.scopes,
  //             })
  //             .catch(() =>
  //               instance.logoutRedirect({ postLogoutRedirectUri: "/" })
  //             );
  //         else instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  //       });
  //   });
  // };

  // gets a new token if the current one is expired
  // const refreshTokenOnUnauthorizedError = (err: any) => {
  //   console.log(JSON.stringify(err));

  //   // check if we can just get another token
  //   if (JSON.stringify(err).includes("Unauthorized")) {
  //     expireToken();
  //     return getAccessToken(GetToken);
  //   } else {
  //     return Promise.reject(err);
  //   }
  // };

  // return the token promise
  const GetToken = async () => {
    //try {
    return instance
      .acquireTokenSilent({
        scopes: protectedResources.apiLogin.scopes,
        account: account,
      })
      .catch((error: any) => {
        // fallback to interaction when silent call fails
        return instance
          .acquireTokenRedirect({
            scopes: protectedResources.apiLogin.scopes,
            account: account,
          })
          .then((tokenResponse: any) => {
            return tokenResponse;
          })
          .catch((error: any) => {
            console.log(error);
          });
      });

    // } catch (error) {
    //   // error on retry - either a session timeout auth error, or an API error
    //   // if we have an auth error try to popup, otherwise just logout
    //   console.log("wowza");

    //   if (JSON.stringify(error).includes("AADB2C90077"))
    //     return instance
    //       .acquireTokenRedirect({
    //         scopes: protectedResources.apiLogin.scopes,
    //       })
    //       .catch(() => instance.logoutRedirect({ postLogoutRedirectUri: "/" }));
    //   else instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    // }
  };

  const Login = async () => {
    return GetToken()
      .then((token: any) => {
        api.Login(token.accessToken).then((response: any) => {
          dispatch({
            type: "login",
            payload: response,
          });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const Setup = async () => {
    return GetToken()
      .then((token: any) => {
        api.Setup(token.accessToken).then((response: any) => {
          dispatch({
            type: "setup",
            payload: response,
          });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const GetGridData = async () => {
    return GetToken()
      .then((token: any) => {
        return api.GetMessages(token.accessToken).then((messages: any) => {
          dispatch({ type: "messages", payload: messages });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const DeleteMessage = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api
          .DeleteMessage(token.accessToken, id)
          .then((messages: any) => {
            dispatch({ type: "deleteMessage", payload: id });
          });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const DownloadMessage = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api.DownloadMessage(token.accessToken, id);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const GetTranscriptUrl = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api.GetTranscriptUrl(token.accessToken, id);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const AddEmail = (email: string) => {
    dispatch({ type: "addEmail", payload: email });
  };

  const GetMessage = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api.GetMessages(token.accessToken, id);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const GetAllGroupMessagesForUser = async () => {
    return GetToken()
      .then((token: any) => {
        return api
          .GetGroupMessagesForUser(token.accessToken)
          .then((messages: any) => {
            dispatch({ type: "groupMessages", payload: messages });
          });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const GetMessagesByGroup = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api
          .GetMessagesByGroup(token.accessToken, id)
          .then((messages: any) => {
            dispatch({ type: "groupMessages", payload: messages });
          });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const CreateMessage = async (body: any) => {
    return GetToken()
      .then((token: any) => {
        return api.CreateMessage(token.accessToken, body);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const UpdateMessage = async (id: string, body: object) => {
    return GetToken()
      .then((token: any) => {
        return api.UpdateMessage(token.accessToken, id, body);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const SetEnablePolling = (enabled: boolean) => {
    dispatch({ type: "setEnablePolling", payload: enabled });
  };

  const GetManagedUsers = async () => {
    return GetToken()
      .then((token: any) => {
        return api.GetManagedUsers(token.accessToken).then((users: any) => {
          dispatch({ type: "managedUsers", payload: users });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const GetManagedGroups = async () => {
    return GetToken()
      .then((token: any) => {
        return api.GetManagedGroups(token.accessToken).then((groups: any) => {
          dispatch({ type: "managedGroups", payload: groups });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const CreateGroup = async (group: ManagedGroup) => {
    return GetToken()
      .then((token: any) => {
        return api.CreateGroup(token.accessToken, group).then((groups: any) => {
          dispatch({ type: "managedGroups", payload: groups });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const DeleteGroup = async (id: string) => {
    return GetToken()
      .then((token: any) => {
        return api.DeleteGroup(token.accessToken, id).then((groups: any) => {
          dispatch({ type: "managedGroups", payload: groups });
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const UpdateGroupMembership = async (id: string, members: object) => {
    return GetToken()
      .then((token: any) => {
        return api
          .UpdateGroupMembership(token.accessToken, id, members)
          .then((users: any) => {
            dispatch({ type: "managedUsers", payload: users });
          });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const ExpireToken = () => expireToken();

  return {
    Login,
    Setup,
    GetGridData,
    DeleteMessage,
    GetMessage,
    SetEnablePolling,
    AddEmail,
    CreateMessage,
    UpdateMessage,
    DownloadMessage,
    ExpireToken,
    GetMessagesByGroup,
    GetAllGroupMessagesForUser,
    GetManagedUsers,
    GetManagedGroups,
    CreateGroup,
    DeleteGroup,
    UpdateGroupMembership,
    GetTranscriptUrl,
  };
};
