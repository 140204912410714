import { useEffect } from "react";
import { hotKeyMap } from "./types";

export function useKeyPress(
  callback: (code: string) => void,
  keyCodes: string[],
  keyMap?: hotKeyMap
): void {
  const handler = (event: KeyboardEvent) => {
    const { key } = event;
    // if we don't have a key then exit
    if (!key) return;

    let _key = key.length === 1 ? key.toUpperCase() : key;

    const handleEvent: boolean = !(event.target instanceof HTMLInputElement);

    if (keyMap && keyMap[_key] && handleEvent) event.preventDefault();

    if (keyCodes.includes(_key) && handleEvent) {
      callback(_key);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
