import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "react-tippy";

import VideoPlayer from "./video-player";
import loadingimg from "../assets/loading_spinner.png";

const Clip = ({ index, rowNum, thumbnail, title, player, setIndex }: any) => {
  const scrollRef = useRef<any>();

  useEffect(() => {
    if (index === rowNum && scrollRef.current)
      scrollRef.current.scrollIntoView({ block: "start", inline: "nearest" });
  }, [index, rowNum, scrollRef]);

  return (
    <div
      ref={scrollRef}
      className={`img-wrapper ${index === rowNum ? "active" : ""}`}
      style={{
        padding: 5,
        margin: 5,
        background: index === rowNum ? "#99eed7" : "white",
        minWidth: 180,
        cursor: "pointer",
      }}
      key={rowNum}
      onClick={() => {
        if (player) player.playlist(rowNum);
        if (setIndex) setIndex(rowNum);
      }}
    >
      <img
        style={{ width: 170, height: 100 }}
        src={thumbnail}
        alt="firefly"
        title={title}
      />
      <div
        className="text-truncate"
        style={{
          fontSize: "smaller",
          fontWeight: index === rowNum ? "bold" : "normal",
        }}
        title={title}
      >
        {title}
      </div>
    </div>
  );
};

export const Playlist = ({ srcList }: any) => {
  const [index, setIndex] = useState<number>(0);
  const [player, setPlayer] = useState<any | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  return (
    <>
      <div className="video-placeholder-frame">
        <div className="video-placeholder">
          {!ready && (
            <img className="center-me working" alt="FireFly" src={loadingimg} />
          )}
          <div
            className="video-player"
            style={{
              display: `${ready ? "block" : "none"}`,
              position: "relative",
              top: "50%",
              //  transform: "translateY(-50%)",
            }}
          >
            <div className="azure-mp-container">
              <VideoPlayer
                srcList={srcList}
                src={[]}
                track={[]}
                enablePlaybackSpeed={true}
                autoLoop={false}
                seekTime={0}
                onEvent={(eventType: string) => {
                  switch (eventType) {
                    case "next":
                      setIndex((next) => next + 1);
                      setStatus("playing");
                      break;
                    case "prev":
                      setIndex((prev) => prev - 1);
                      break;
                    case "playing":
                      setStatus(eventType);
                      setReady(true);
                      break;
                    case "pause":
                      setStatus(eventType);
                      setReady(true);
                      break;
                    case "lastVideoEnded":
                      setStatus("pause");
                      break;
                  }
                  console.log(eventType);
                }}
                onPlayer={(_player: any) => {
                  setPlayer(_player);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: 5,
        }}
      >
        {player && status && (
          <>
            <div className="alignMiddle">
              <Tooltip
                trigger="mouseenter"
                title="previous firefly"
                position="top"
                theme="dark"
              >
                <button
                  className="media-btn"
                  onClick={() => player.prev()}
                  disabled={player.pl ? player.pl.current === 0 : true}
                  title="previous firefly"
                >
                  <i className="material-icons">skip_previous</i>
                </button>
              </Tooltip>{" "}
              <Tooltip
                trigger="mouseenter"
                title="restart playlist"
                position="top"
                theme="dark"
              >
                <button
                  className="media-btn"
                  onClick={() => {
                    setIndex(0);
                    player.stop();
                  }}
                  title="reset"
                >
                  <i className="material-icons">replay</i>
                </button>
              </Tooltip>{" "}
              <Tooltip
                trigger="mouseenter"
                title={`${status === "playing" ? "pause" : "play"}`}
                position="top"
                theme="dark"
              >
                <button
                  className="media-btn"
                  onClick={() =>
                    status === "playing" ? player.pause() : player.play()
                  }
                  title={`${status === "playing" ? "pause" : "play"}`}
                >
                  {status === "playing" ? (
                    <i className="material-icons">pause</i>
                  ) : (
                    <i className="material-icons">play_arrow</i>
                  )}
                </button>
              </Tooltip>{" "}
              <Tooltip
                trigger="mouseenter"
                title="next firefly"
                position="top"
                theme="dark"
              >
                <button
                  className="media-btn"
                  onClick={() => player.next()}
                  disabled={
                    player.pl
                      ? player.pl.current === player.pl.videos.length - 1
                      : false
                  }
                  title="next firefly"
                >
                  <i className="material-icons">skip_next</i>
                </button>
              </Tooltip>{" "}
              <Tooltip
                trigger="mouseenter"
                title="fullscreen"
                position="top"
                theme="dark"
              >
                <button
                  className="media-btn"
                  onClick={() => player.enterFullscreen()}
                  title="fullscreen"
                >
                  <i className="material-icons">fullscreen</i>
                </button>
              </Tooltip>
            </div>
            <div className="alignRight" style={{ fontSize: "smaller" }}>
              playing {player && player.pl ? player.pl.current + 1 : 1} of{" "}
              {srcList.length}
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflowX: "auto",
          flexDirection: "column",
          padding: 5,
          height: 162,
        }}
      >
        {srcList.map((src: any, rowNum: number) => (
          <Clip
            key={rowNum}
            index={index}
            rowNum={rowNum}
            thumbnail={src.thumbnail}
            title={src.title}
            player={player}
            setIndex={setIndex}
          />
        ))}
      </div>
    </>
  );
};
