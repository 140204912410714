import React from "react";

import "../styles/EmailList.css";

export interface EmailListProps {
  emails?: string[];
  validating?: boolean;
  suggestions?: string[];
  onChange?: (emails: string[]) => void;
}

export interface EmailListState {
  emails: string[];
  value?: string;
  focused?: boolean;
  validating?: boolean;
  error?: boolean;
  activeSuggestionIndex?: number;
  filteredSuggestions?: string[];
  showSuggestions?: boolean;
}

class EmailList extends React.Component<EmailListProps> {
  state = {
    emails: [],
    value: "",
    focused: false,
    validating: false,
    error: null,
    activeSuggestionIndex: 0,
    filteredSuggestions: [],
    showSuggestions: false,
  };

  emailInputRef = React.createRef<HTMLInputElement>();

  componentDidUpdate() {
    const { showSuggestions } = this.state;

    setTimeout(() => {
      if (showSuggestions) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }

  close = () => {
    this.setState({
      showSuggestions: false,
    });
  };

  static getDerivedStateFromProps(
    nextProps: EmailListProps,
    prevState: EmailListState
  ) {
    if (prevState.emails !== nextProps.emails) {
      return {
        //propsEmails: nextProps.emails || [],
        emails: nextProps.emails || [],
        inputValue: "",
        focused: false,
        validating: nextProps.validating,
      };
    }

    return { validating: nextProps.validating };
  }

  handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const { activeSuggestionIndex, filteredSuggestions, showSuggestions } =
      this.state;

    var value = this.state.value.trim();

    if (["Enter", "Tab", ",", ";"].includes(evt.key)) {
      evt.preventDefault();

      // hitting enter on a suggestion
      if (showSuggestions) {
        value = filteredSuggestions[activeSuggestionIndex]
          ? filteredSuggestions[activeSuggestionIndex]
          : value;
      }

      if (value && this.isValid(value)) {
        let _emails = [...this.state.emails, value];

        this.setState({
          emails: _emails,
          value: "",
          showSuggestions: false,
        });

        if (this.props.onChange) {
          this.props.onChange(_emails);
        }
      }
    }
    if (["Backspace"].includes(evt.key)) {
      if (!evt.currentTarget.value) {
        let _emails = [
          ...this.state.emails.slice(0, this.state.emails.length - 1),
        ];
        this.setState({
          emails: _emails,
          value: "",
        });

        if (this.props.onChange) {
          this.props.onChange(_emails);
        }
      }
    }

    if (["ArrowUp"].includes(evt.key)) {
      if (activeSuggestionIndex === 0) {
        return;
      }
      this.setState({
        activeSuggestionIndex: activeSuggestionIndex - 1,
      });
    }

    if (["ArrowDown"].includes(evt.key)) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({
        activeSuggestionIndex: activeSuggestionIndex + 1,
      });
    }

    this.handleEmpty();
  };

  handleEmpty = () => {
    var counter = setInterval(() => {
      clearInterval(counter);
      let _return =
        this.state.value === "" &&
        this.props.validating &&
        this.state.emails.length === 0;

      if (_return) {
        this.setState({
          error: "Please enter a valid email address.",
        });
      }
    }, 50);
  };

  handleChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const userInput = evt.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    let suggestions = this.props.suggestions;
    const unLinked = suggestions
      ? suggestions.filter(
          (suggestion) =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1 &&
            !this.isInList(suggestion.toLowerCase())
        )
      : [];

    this.setState({
      value: userInput,
      error: null,
      filteredSuggestions: unLinked,
      activeSuggestionIndex: 0,
      showSuggestions: true,
    });

    this.handleEmpty();
  };

  handleDelete = (item: string) => {
    let _emails = this.state.emails.filter((i) => i !== item);
    this.setState({
      emails: _emails,
    });

    if (this.props.onChange) this.props.onChange(_emails);
    if (_emails.length === 0) this.isValid("asdf");
  };

  handleOnFocus = () =>
    this.setState({
      focused: true,
    });

  handleOnBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    var value = e.currentTarget.value.trim();

    if (value && this.isValid(value)) {
      let _emails = [...this.state.emails, this.state.value];
      this.setState({
        emails: _emails,
        value: "",
      });

      if (this.props.onChange) {
        this.props.onChange(_emails);
      }
    }
    this.setState({ focused: false });
    //this.findEmailAddress(e.currentTarget.value, true);
  };

  handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");

    // eslint-disable-next-line no-useless-escape
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));
      let _emails = [...this.state.emails, ...toBeAdded];

      this.setState({
        emails: _emails,
      });

      if (this.props.onChange) {
        this.props.onChange(_emails);
      }
    }
  };

  handleOnClick = (e: any) => {
    this.setState({
      value: "",
      error: "",
      filteredSuggestions: [],
      activeSuggestionIndex: 0,
      showSuggestions: false,
    });

    let toBeAdded = e.target.innerText;

    if (!this.isInList(toBeAdded)) {
      let _emails = [...this.state.emails, toBeAdded];

      this.setState({
        emails: _emails,
      });

      if (this.props.onChange) {
        this.props.onChange(_emails);
      }
    }
  };

  isValid(email: string) {
    let error = null;

    if (email === "") return false;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = "Please enter a valid email address.";
    }

    if (error) {
      this.setState({ error });
      return false;
    }

    return true;
  }

  isInList = (email: string) => {
    // @ts-ignore
    return this.state.emails.includes(email);
  };

  isEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  SuggestionsListComponent = () => {
    const { filteredSuggestions, activeSuggestionIndex } = this.state;
    return filteredSuggestions.length ? (
      <div className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;

          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }

          return (
            <div
              className={`suggestion-item ${className}`}
              key={suggestion}
              onClick={this.handleOnClick}
            >
              {suggestion}
            </div>
          );
        })}
      </div>
    ) : null;
  };

  render() {
    const { focused, value, error, validating } = this.state;
    this.handleEmpty();
    return (
      <>
        <div
          className={`email-list ${focused ? "focused" : ""} ${
            validating ? (error ? "has-error" : "is-valid") : ""
          }`}
          onClick={() => {
            if (this.emailInputRef.current) {
              this.emailInputRef.current.focus();
            }
          }}
        >
          {this.state.emails.map((item) => (
            <div className="tag-item" key={item}>
              {item}
              <button
                type="button"
                className="button"
                onClick={() => this.handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}

          <input
            name="notemail"
            className={"input " + (error && " has-error")}
            value={value}
            autoComplete={"off"}
            placeholder="Enter email"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            onPaste={this.handlePaste}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            ref={this.emailInputRef}
          />
        </div>
        {this.state.showSuggestions && <this.SuggestionsListComponent />}
        {this.state.error && <p className="error">{this.state.error}</p>}
      </>
    );
  }
}

export default EmailList;
