/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { AppSettings, DeviceTypes } from "./react-record-webcam/src";
import type { RecordWebcamHook } from "./react-record-webcam/src";
import OpenAILogo from "../assets/openai.png";

interface SettingsProps {
  show: boolean;
  recordWebcam: RecordWebcamHook;
  cancel(): void;
  save(settings: AppSettings): void;
  showFirstTimeMessage: boolean;
}

export const SettingsDialog = ({
  show,
  recordWebcam,
  cancel,
  save,
  showFirstTimeMessage,
}: SettingsProps) => {
  const [devices, setDevices] = useState<DeviceTypes>();
  const [audioSource, setAudioSource] = useState<string | undefined>(undefined);
  const [videoSource, setVideoSource] = useState<string | undefined>(undefined);
  const [defaultVideoSourceId, setDefaultVideoSourceId] = useState<string>("");
  const [defaultAudioSourceId, setDefaultAudioSourceId] = useState<string>("");
  const [durationSeconds, setDurationSeconds] = useState<number>(
    localStorage.getItem("ffDefaultLength")
      ? (localStorage.getItem("ffDefaultLength") as unknown as number)
      : 120
  );
  const useAIStoredValue = localStorage.getItem("ffUseAI");
  const [useAI, setUseAI] = useState<boolean>(
    useAIStoredValue ? JSON.parse(useAIStoredValue) : true
  );

  const selectAudio = (event: any) => setAudioSource(event.target.value);
  const selectVideo = (event: any) => setVideoSource(event.target.value);

  // handle component state changes
  useEffect(() => {
    if (devices?.audio === undefined || devices?.audio[0].deviceId === "") {
      recordWebcam.getDevices().then((_devices) => {
        setDevices(_devices);
      });
    }
    if (recordWebcam?.audioSource) {
      setAudioSource(recordWebcam.audioSource);
    }
    if (recordWebcam?.videoSource) {
      setVideoSource(recordWebcam.videoSource);
    }

    if (devices && devices.video.length > 0) {
      setDefaultVideoSourceId(devices.video[0].deviceId);
    }

    if (devices && devices.audio.length > 0) {
      setDefaultAudioSourceId(devices.audio[0].deviceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, show]);

  const handleSave = () => {
    save({
      sources: { audioSource, videoSource },
      durationSeconds,
      useAI: useAI,
      defaultVideoSourceId,
      defaultAudioSourceId,
    });
    cancel();
  };

  return (
    <Modal
      show={show}
      onHide={cancel}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Body>
        {showFirstTimeMessage && (
          <div className="h6 text-light bg-dark pl-4">
            Please confirm your settings below and select Save Changes
          </div>
        )}
        <Form>
          <Form.Group as={Col} name="setDuration">
            <Form.Label>Max Duration (minutes)</Form.Label>
            <br />
            <Form.Check
              inline
              label="1 minute"
              name="group1"
              type="radio"
              // eslint-disable-next-line eqeqeq
              checked={durationSeconds == 60}
              onChange={(event: any) => {
                setDurationSeconds(parseInt(event.target.value));
              }}
              value={60}
              id="inline-radio-1"
            />
            <Form.Check
              inline
              label="2 minutes"
              name="group1"
              type="radio"
              // eslint-disable-next-line eqeqeq
              checked={durationSeconds == 120}
              onChange={(event: any) => {
                setDurationSeconds(parseInt(event.target.value));
              }}
              value={120}
              id="inline-radio-2"
            />
            <Form.Check
              inline
              label="5 minutes"
              name="group1"
              type="radio"
              // eslint-disable-next-line eqeqeq
              checked={durationSeconds == 300}
              onChange={(event: any) => {
                setDurationSeconds(parseInt(event.target.value));
              }}
              value={300}
              id="inline-radio-3"
            />
            <Form.Check
              inline
              label="10 minutes"
              name="group1"
              type="radio"
              // eslint-disable-next-line eqeqeq
              checked={durationSeconds == 600}
              onChange={(event: any) => {
                setDurationSeconds(parseInt(event.target.value));
              }}
              value={600}
              id="inline-radio-4"
            />
          </Form.Group>
          <Form.Group as={Col} name="selectVideo">
            <Form.Label>Video Source</Form.Label>
            {devices && (
              <Form.Control
                as="select"
                onChange={selectVideo}
                value={videoSource}
              >
                {devices.video.map((_device) => (
                  <option key={_device.deviceId} value={_device.deviceId}>
                    {_device.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group as={Col} name="selectAudio">
            <Form.Label>Audio Source</Form.Label>
            {devices && (
              <Form.Control
                as="select"
                onChange={selectAudio}
                value={audioSource}
              >
                {devices.audio.map((_device) => (
                  <option key={_device.deviceId} value={_device.deviceId}>
                    {_device.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>

          <Form.Group as={Col} name="selectAI">
            <Form.Check
              inline
              label="Use OpenAI for Summarization"
              type="switch"
              id="useAISwitch"
              checked={useAI}
              onChange={(event: any) => {
                setUseAI(event.target.checked);
              }}
            />
            <br />
            <span className="aiNote">
              AI-generated content denoted with
            </span>{" "}
            <img src={OpenAILogo} alt="OpenAI" title="OpenAI" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="ml-2" variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button className="ml-2 mr-3" variant="secondary" onClick={cancel}>
          Cancel
        </Button>
        <br />
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsDialog;
