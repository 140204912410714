import { Tooltip } from "react-tippy";

const SearchPanel = ({
  filterRef,
  searchString,
  handleSearch,
  setGridData,
  isAllGroupFilter,
  handleGroupMessages,
  filter,
}: any) => {
  const handleGroupSearch = () => {
    if (isAllGroupFilter) {
      //refresh the results for the Group from API
      handleGroupMessages();
    } else {
      if (
        filter !== "Inbox" &&
        filter !== "ADO" &&
        filter !== "Sent" &&
        filter !== "Public"
      ) {
        handleGroupMessages(filter);
      } else {
        setGridData(null);
      }
    }
  };

  return (
    <article className="search-panel">
      <header>
        <input
          ref={filterRef}
          className="input"
          type="text"
          id="test"
          placeholder="Filter by keywords here..."
          value={searchString}
          onChange={handleSearch}
        />

        <Tooltip
          trigger="mouseenter"
          title="refresh"
          position="top"
          theme="dark"
        >
          <button className={`info-btn`} onClick={handleGroupSearch}>
            <i className="material-icons">refresh</i>
          </button>
        </Tooltip>
      </header>
    </article>
  );
};

export default SearchPanel;
