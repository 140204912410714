import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal, Button } from "react-bootstrap";
import { Tooltip } from "react-tippy";

import { MessageListProps, columnType, ALL_HOTKEYS } from "../types";
import MessageListItem from "./MessageListItem";
import CheckBox from "./CheckBox";

import Pagination from "./Pagination";

import { scrollToTop, copyTextToClipboard } from "../utils";
import "../styles/Grid.css";
import "../styles/MessageList.css";

export const MessageList = ({
  messages,
  handleSort,
  handleView,
  handleTree,
  handleReply,
  handleDelete,
  handleCheck,
  filter,
  checkCount,
  handleDownload,
  sort,
  hotKey,
  isAllSelected,
  keywordSearch,
  showTranscripts,
  handleTranscriptPDF,
}: MessageListProps) => {
  // state objects
  const [postsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pageData, setPageData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState("");

  // used to select the first item when the filter changes
  useEffect(() => {
    if (pageData.length > 0) {
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  useEffect(() => {
    if (messages && Array.isArray(messages)) {
      setPageData(
        messages.slice(
          page * postsPerPage > messages.length ? 0 : page * postsPerPage,
          page * postsPerPage + postsPerPage > messages.length
            ? messages.length
            : page * postsPerPage + postsPerPage
        )
      );
    }
  }, [messages, page, postsPerPage]);

  // process hot keys
  useEffect(() => {
    const { value } = hotKey;
    let currPage = Math.ceil(messages.length / postsPerPage) - 1;
    let selectedMessage = pageData[selectedIndex];

    switch (value) {
      case ALL_HOTKEYS.ArrowUp:
        if (selectedIndex > 0) setSelectedIndex((prev) => prev - 1);
        // flip pages
        if (selectedIndex === 0 && page > 0) setPage((prev) => prev - 1);
        break;
      case ALL_HOTKEYS.ArrowDown:
        if (selectedIndex + 1 !== pageData.length)
          setSelectedIndex((prev) => prev + 1);
        // flip pages
        if (selectedIndex + 1 === pageData.length && page < currPage)
          setPage((prev) => prev + 1);
        break;
      case ALL_HOTKEYS.ArrowLeft:
        if (page > 0) setPage((prev) => prev - 1);
        break;
      case ALL_HOTKEYS.ArrowRight:
        if (page < currPage) setPage((prev) => prev + 1);
        break;
      case ALL_HOTKEYS.View:
        if (selectedMessage && selectedMessage.Status === "sent")
          handleView(selectedMessage.Id);
        break;
      case ALL_HOTKEYS.Delete:
        if (selectedMessage) execDelete(selectedMessage.Id, selectedIndex);
        break;
      case ALL_HOTKEYS.Download:
        if (selectedMessage && selectedMessage.Status === "sent")
          execDownload(selectedMessage.Id, selectedIndex);
        break;
      case ALL_HOTKEYS.Reply:
        if (selectedMessage && selectedMessage.Status === "sent")
          handleReply(selectedMessage.Id);
        break;
      case ALL_HOTKEYS.Copy:
        handleCopy(selectedIndex);
        break;
      case ALL_HOTKEYS.Check:
        if (selectedMessage)
          handleCheck(selectedMessage.Id, !selectedMessage.checked);
        break;
      case ALL_HOTKEYS.PDF:
        if (selectedMessage && selectedMessage.Status === "sent")
          handleTranscriptPDF(selectedMessage.Id);
        break;
      case ALL_HOTKEYS.CheckAll:
        handleCheck("all", !isAllSelected);
        break;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotKey]);

  const setCurrPage = ({ selected }: any) => {
    setPage(selected);

    // scroll to the top on page change
    scrollToTop();
  };

  const columns: columnType[] = [
    // {
    //   title: "Action",
    //   value: "action",
    //   className: "ffAction",
    //   sortType: "none",
    // },
    {
      title: "Checked",
      value: "checked",
      className: "img-frame-wrapper",
      sortType: "none",
      visible: true,
    },
    {
      title: "To/From",
      value: "Targets",
      className: "ffTargets",
      sortType: "string",
      visible: true,
    },
    {
      title: "Subject",
      value: "Subject",
      className: "ffMessage",
      sortType: "string",
      visible: true,
    },
    {
      title: "Message",
      value: "Transcript",
      className: "ffMessage",
      sortType: "string",
      visible: showTranscripts,
    },
    {
      title: "Sent On",
      value: "CreatedDate",
      className: "ffDate",
      sortType: "date",
      visible: true,
    },
  ];

  const setArrow = (column: columnType) => {
    let className = "sort-direction";

    if (column && sort.column)
      if (column.value === sort.column.value) {
        className += sort.direction === "asc" ? " asc" : " desc";
      }

    return className;
  };

  const handleCopy = (index: number, linkOnly: boolean = false) => {
    setSelectedIndex(index);
    let message = pageData[index];
    console.log(message);
    let baseURL =
      message.Targets.length === 0 || message.Targets[0].Type === "public"
        ? window.location.href.replace(/list/, "public")
        : window.location.href;
    let copyText = linkOnly
      ? baseURL + "/" + message.Id
      : `Link: ${baseURL}/${
          message.Id
        }\r\nSubject: ${message.Subject.trim()}\r\nTranscript: ${message.Transcript.trim()}`;

    copyTextToClipboard(copyText)
      .then(() => {
        console.log("Copied!");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showMessage = (id: string, index: number) => {
    setSelectedIndex(index);
    if (handleView) handleView(id);
  };

  const showReply = (id: string, index: number) => {
    setSelectedIndex(index);
    if (handleReply) handleReply(id);
  };

  const execDelete = (id: string, index: number) => {
    setSelectedIndex(index);
    setDeleteMessageId(id);
    setDeleteConfirm(true);
  };

  const execTranscriptPDF = (id: string, index: number) => {
    setSelectedIndex(index);

    if (handleTranscriptPDF) handleTranscriptPDF(id);
  };

  const execDownload = (id: string, index: number) => {
    setSelectedIndex(index);

    if (handleDownload) handleDownload(id);
  };

  const expandCollapse = (id: string) => {
    if (handleTree) handleTree(id);
  };

  return (
    <>
      <div>
        <Table responsive bordered hover>
          <thead className="cdBodyContainer">
            <tr>
              {columns.map(
                (x, i) =>
                  x.visible && (
                    <th
                      key={i}
                      className={`${x.className} ${
                        x.sortType !== "none" ? "sortable" : ""
                      }`}
                      onClick={() => {
                        handleSort(x);
                      }}
                    >
                      {x.value === "checked" ? (
                        <>
                          <Tooltip
                            trigger="mouseenter"
                            title={isAllSelected ? "uncheck all" : " check all"}
                            position="top"
                            theme="dark"
                          >
                            <CheckBox
                              name=""
                              value="all"
                              overlay={false}
                              tick={isAllSelected}
                              onCheck={(e: any) =>
                                handleCheck("all", e.target.checked)
                              }
                            />
                          </Tooltip>{" "}
                          {checkCount}{" "}
                        </>
                      ) : null}
                      {x.value === "action" ? "" : x.title}

                      <span className={setArrow(x)}></span>
                    </th>
                  )
              )}
              <th className="scrollColumn"></th>
            </tr>
          </thead>
          {pageData ? (
            <tbody className="cdBodyContainer" id="grid">
              {pageData.map((message: any, rowNum: number) => {
                return (
                  <MessageListItem
                    key={message.Id}
                    propKey={message.Id}
                    message={message}
                    columnInfo={columns}
                    rowNum={rowNum}
                    level={0}
                    active={rowNum === selectedIndex}
                    showMessage={showMessage}
                    expandCollapse={expandCollapse}
                    showReply={showReply}
                    execDelete={execDelete}
                    execDownload={execDownload}
                    setSelectedIndex={setSelectedIndex}
                    handleCopy={handleCopy}
                    onCheck={handleCheck}
                    keywordSearch={keywordSearch}
                    showTranscript={
                      columns.find((col) => col.value === "Transcript")?.visible
                    }
                    execTranscriptPDF={execTranscriptPDF}
                  />
                );
              })}
            </tbody>
          ) : (
            <tbody className="cdBodyContainer">
              <tr>
                <td colSpan={6} align="center" className="boldBlack"></td>
              </tr>
            </tbody>
          )}
        </Table>

        {messages.length && Array.isArray(messages) ? (
          <div className="alignPagination">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={messages.length}
              paginate={setCurrPage}
              currentPage={page}
            />
          </div>
        ) : null}
      </div>

      <Modal
        show={deleteConfirm}
        animation={false}
        onHide={() => {
          setDeleteMessageId("");
          setDeleteConfirm(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirm?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (handleDelete) handleDelete(deleteMessageId);
              setDeleteConfirm(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteMessageId("");
              setDeleteConfirm(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
