import React, { useRef, useEffect, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Form, Col, Button } from "react-bootstrap";

import { useActions } from "../context/actions";

import { sortData, scrollToTop } from "../utils";

// Components
import { ManagedUserList } from "../components/ManagedUserList";
import SearchPanel from "../components/SearchPanel";
import { CreateGroupDialog } from "../components/CreateGroupDialog";

import { sortColumn, columnType, ManagedGroup } from "../types";
import { store } from "../context/store";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string | undefined;
    webkitdirectory?: string | undefined;
  }
}

export const ManageGroups = () => {
  const actions = useActions();
  const { state } = useContext(store);

  // refer to the context state as just context to avoid
  // confusion between context and component state
  const context = state;
  const filterRef = useRef<any>();

  // Data
  const [gridUserData, setGridUserData] = useState<any | null>(null);
  const [gridGroupData, setGridGroupData] = useState<any | null>(null);
  //const [loadingManagedGroups, setLoadingManagedGroups] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [searchString, setSearchString] = useState<string>("");
  const [checkCount, setCheckCount] = useState(0);
  const [selectedReset, setSelectedReset] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [membershipDirty, setMembershipDirty] = useState(false);
  const [membershipDirtyItems, setMembershipDirtyItems] = useState<string[]>(
    []
  );

  // Modals
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const appConfig = require("@azure/app-configuration");

  const connectionString =
    process.env["REACT_APP_APPCONFIG_CONNECTION_STRING"] ||
    "<connection string>";

  const appConfigclient = new appConfig.AppConfigurationClient(
    connectionString
  );

  const getAzureAppConfigSetting = async (settingKey: string) => {
    let retrievedSetting = await appConfigclient.getConfigurationSetting({
      key: settingKey,
      label:
        process.env["REACT_APP_ENVIRONMENT"] === "DEV"
          ? null
          : process.env["REACT_APP_ENVIRONMENT"],
    });

    return retrievedSetting.value as string;
  };

  //////////////////////////////////////////////////////////////////
  // Sorting
  //////////////////////////////////////////////////////////////////
  const defaultSort: sortColumn = {
    column: {
      title: "Name",
      value: "FullName",
      className: "ffMessage",
      sortType: "string",
      visible: true,
    },
    direction: "asc",
  };

  const [sort, setSort] = useState<sortColumn>(defaultSort);

  //////////////////////////////////////////////////////////////////
  // User Data
  //////////////////////////////////////////////////////////////////

  // Initially load the group and user data
  useEffect(() => {
    actions.GetManagedGroups();
    actions.GetManagedUsers();
  }, []);

  useEffect(() => {
    // do any necessary processing for the grid
    setGridGroupData(context.managedGroups);

    // Select the first group when the list is updated
    if (context.managedGroups && context.managedGroups.length > 0) {
      setSelectedGroup(context.managedGroups[0].Id);
      setSelectedGroupName(context.managedGroups[0].Name);
    }

    // Clear the dirty items since we refreshed groups
    setMembershipDirtyItems([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.managedGroups]);

  // Track if the user membership is dirty
  useEffect(() => {
    setMembershipDirty(membershipDirtyItems.length > 0);
  }, [membershipDirtyItems]);

  // Clear membership dirty when selected group changes or membership is reloaded after save
  useEffect(() => {
    setMembershipDirtyItems([]);
  }, [selectedGroup, context.managedUsers]);

  //////////////////////////////////////////////////////////////////
  // Search
  //////////////////////////////////////////////////////////////////

  // triggered whenever the underlying data is changed
  useEffect(() => {
    if (context.managedUsers && Array.isArray(context.managedUsers)) {
      // always start from context which contains all the raw data
      let processedGridData = [...context.managedUsers];
      let foundCount = 0;

      // Check items based on left navigation
      processedGridData = processedGridData.map((item: any) => {
        // Check the items that match the selected group
        if (item.Groups) {
          let hasGroup = item.Groups.map((target: any) => {
            return target.Id;
          }).includes(selectedGroup);

          if (hasGroup) {
            item.checked = true;
            foundCount++;
          } else {
            item.checked = false;
          }
        } else {
          item.checked = false;
        }

        return item;
      });

      // Filter based on search string
      // Note that we always display if checked!
      if (searchString) {
        processedGridData = processedGridData.filter((entry: any) =>
          Object.values(entry).some(
            (val) =>
              entry.checked ||
              (typeof val === "string" &&
                val.toLowerCase().includes(searchString.toLowerCase()))
          )
        );
      }

      // handle the select all checkbox
      setIsAllSelected(foundCount === processedGridData.length);
      setCheckCount(foundCount);

      console.log(processedGridData);

      setGridUserData(sortData(sort, processedGridData, true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchString,
    context.managedUsers,
    context.managedGroups,
    selectedGroup,
    sort,
  ]);

  //////////////////////////////////////////////////////////////////
  // event handlers
  //////////////////////////////////////////////////////////////////

  const handleManagedGroups = (id?: string) => {
    actions.GetManagedUsers();
  };

  const handleSort = (column: columnType) => {
    if (gridUserData) {
      if (column.sortType !== "none") {
        const direction = sort.direction === "asc" ? "desc" : "asc";
        setSort({ column, direction });
      }
    }
  };

  const handleSearch = ({ target }: any) => {
    let { value } = target;
    setSearchString(value);

    // scroll to the top on page change
    scrollToTop();
  };

  // handles the checkboxes
  const handleCheckBoxChange = (checkName: string, isChecked: boolean) => {
    let isAllChecked = checkName === "all" && isChecked;
    let isAllUnChecked = checkName === "all" && !isChecked;
    const checked = isChecked;

    const checkList = gridUserData.map((row: { Id: string }, index: any) => {
      if (isAllChecked || row.Id === checkName) {
        return Object.assign({}, row, {
          checked,
        });
      } else if (isAllUnChecked) {
        return Object.assign({}, row, {
          checked: false,
        });
      }

      return row;
    });

    let _isAllSelected =
      checkList.findIndex(
        (item: { checked: boolean }) => item.checked === false
      ) === -1 || isAllChecked;

    // get the number of checked items
    let _checkCount = checkList.reduce((total: number, curr: any) => {
      return curr.checked ? total + 1 : total;
    }, 0);
    setCheckCount(_checkCount);

    setGridUserData(checkList);
    setIsAllSelected(_isAllSelected);

    // Track if the user membership list is dirty
    const index = membershipDirtyItems.indexOf(checkName, 0);
    if (index < 0) {
      setMembershipDirtyItems([...membershipDirtyItems, checkName]);
    } else {
      setMembershipDirtyItems(
        membershipDirtyItems.filter((item) => item != checkName)
      );
    }
  };

  const handleGroupCreate = () => {
    setShowCreate(!showCreate);
  };

  const handleShowDeleteConfirm = () => {
    setShowDeleteConfirm(true);
  };

  const handleGroupDelete = () => {
    actions.DeleteGroup(selectedGroup);
    setShowDeleteConfirm(false);
  };

  const handleGroupSave = (group: ManagedGroup) => {
    actions.CreateGroup(group);
    setShowCreate(false);
  };

  const handleMembershipSave = () => {
    const members = gridUserData.filter((item: any) => item.checked);

    actions.UpdateGroupMembership(selectedGroup, members);
    // Hack to get the selected index to reset when we save
    setSelectedReset(!selectedReset);

    // scroll to the top on page change
    scrollToTop();
  };

 // Check if authorized as admin before rendering management page
 if (!context.account || !context.account.IsAdmin) {
  return <Redirect to={`/list`} />;
  } else
  return (
    <>
      <div className="filterLink mt-2" style={{ minWidth: 150 }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <span style={{ verticalAlign: "middle", color: "#3c4858" }}>
            Return
          </span>
          <i
            className="material-icons ml-1"
            style={{ verticalAlign: "middle" }}
          >
            keyboard_return
          </i>
        </Link>
      </div>
      <div className="topright">
        <Button
          variant="secondary"
          className="ml-1 ali"
          style={{ display: "inline-block" }}
          //disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
          onClick={() => {
            handleGroupCreate();
          }}
        >
          Create Group
        </Button>

        <Button
          variant="secondary"
          className="ml-1 ali"
          style={{ display: "inline-block" }}
          disabled={checkCount > 0 || membershipDirty}
          onClick={() => {
            handleShowDeleteConfirm();
          }}
        >
          Delete Group
        </Button>

        <Button
          variant="secondary"
          className="ml-1 ali"
          style={{ display: "inline-block" }}
          disabled={!membershipDirty}
          onClick={() => {
            handleMembershipSave();
          }}
        >
          Update Members
        </Button>

        <SearchPanel
          searchString={searchString}
          handleSearch={handleSearch}
          filterRef={filterRef}
          setGridData={setGridUserData}
          isAllGroupFilter={""}
          filter={""}
          handleGroupMessages={handleManagedGroups}
        />
      </div>

      <div className="sidebar">
        {gridGroupData && gridGroupData.map && (
          <div>
            <div className="boldBlack navTitle mt-2">Groups</div>

            {gridGroupData.map((group: any) => (
              <div
                style={{ minWidth: 150 }}
                key={group.Id}
                title={group.Name}
                onClick={() => {
                  setSelectedGroup(group.Id);
                  setSelectedGroupName(group.Name);
                  handleManagedGroups(group.Id);
                  scrollToTop();
                }}
                className={`filterLink mt-2 ${
                  group.Id === selectedGroup ? "filterSelected boldBlack" : ""
                }`}
              >
                {group.Name}
              </div>
            ))}
          </div>
        )}
      </div>

      <main className="content">
        <h3>Manage Groups</h3>
        <div>
          {gridUserData && (
            <ManagedUserList
              items={gridUserData}
              checkCount={checkCount}
              selectedReset={selectedReset}
              selectedGroup={selectedGroup}
              isAllSelected={isAllSelected}
              sort={sort}
              keywordSearch={searchString}
              handleSort={handleSort}
              handleCheck={handleCheckBoxChange}
            />
          )}
        </div>
      </main>

      <CreateGroupDialog
        show={showCreate}
        cancel={() => {
          setShowCreate(false);
        }}
        save={handleGroupSave}
      />

      <Modal
        show={showDeleteConfirm}
        animation={false}
        onHide={() => {
          setShowDeleteConfirm(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirm?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting group: "{selectedGroupName}" are you sure?</p>
          <p>Group Id {selectedGroup}</p>
          <p></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleGroupDelete();
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowDeleteConfirm(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
