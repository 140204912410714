import React from "react";

export interface RollOverImageProps {
  enabled: boolean;
  altText: string;
  title?: string;
  normal: string;
  normalHover?: string;
  disabled?: string;
  disabledHover?: string;
  onClick?: () => void;
}

export const RollOverImage = (props: RollOverImageProps) => {
  return (
    <img
      alt={props.altText}
      title={props.title}
      src={props.enabled ? props.normal : props.disabled}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      onMouseOver={(e) => {
        e.currentTarget.src = props.enabled
          ? props.normalHover
            ? props.normalHover
            : props.normal
          : props.disabledHover
          ? props.disabledHover
          : props.normal;
      }}
      onMouseOut={(e) => {
        e.currentTarget.src = props.enabled
          ? props.normal
          : props.disabled
          ? props.disabled
          : props.normal;
      }}
    />
  );
};
