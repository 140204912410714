import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "../context/api";
import { Message } from "../components/Message";

import { Helmet } from "react-helmet";

export const PublicMessage = () => {
  // Hooks
  const api = useAPI();
  const { id } = useParams<{ id: string }>();

  const [message, setMessage] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [popup, setPopup] = useState(false);

  // Passed in ID?
  useEffect(() => {
    // show the message

    /*
                <img
                  src={message.ThumbnailUrl}
                  className={`img-wrapper clickable`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="fire fly"
                  title="View Firefly..."
                  onClick={() => {
                    if (showMessage) {
                      console.log(message);
                      showMessage(message.Id, rowNum);
                    }
                  }}
                />
    */
    if (id) {
      api
        .GetPublicMessage(id)
        .then((response: any) => {
          setMessage(response);
          console.log(response);
        })
        .catch((error: any) => {
          setError("Sorry, we could not find your firefly");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setPopup(window.location.href.includes("?popup=true"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "./iframeResizer.contentWindow.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // This will run one time after the component mounts
  // useEffect(() => {
  //   const onPageLoad = () => {
  //     let message = {
  //       height: document.body.scrollHeight,
  //       width: document.body.scrollWidth,
  //     };

  //     console.log(message);
  //     // window.top refers to parent window
  //     window.top.postMessage(message, "*");
  //   };

  //   // Check if the page has already loaded
  //   if (document.readyState === "complete") {
  //     onPageLoad();
  //   } else {
  //     window.addEventListener("load", onPageLoad);
  //     // Remove the event listener when component unmounts
  //     return () => window.removeEventListener("load", onPageLoad);
  //   }
  // }, []);

  return (
    <div className="public-ff-wrapper">
      {message && (
        <Helmet>
          <meta property="og:image" content={message.ThumbnailUrl} />
          <meta property="og:title" content={"Firefly"} />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:description"
            content={
              message.Summary && message.Summary.length > 0
                ? message.Summary.slice(0, 100) + "..."
                : message.Subject.slice(0, 100) + "..."
            }
          />
        </Helmet>
      )}

      <div
        className={`${
          !popup ? "center-me public-ff" : "center-me public-ff-embed"
        }`}
      >
        <div className="video-placeholder-frame">
          {message && (
            <div className="video-placeholder">
              <Message message={message} handleReply={null} />
            </div>
          )}
          {error && <div className="warning">{error}</div>}
        </div>
      </div>
    </div>
  );
};
