import React from "react";
import { CAMERA_STATUS } from "./react-record-webcam/src";
import "../styles/StatusBar.css";

interface StatusBarProps {
  status: CAMERA_STATUS;
}

// memoize this component so it does render multiple times
export const StatusBar = React.memo(({ status }: StatusBarProps) => {
  return (
    <>
      {status === "RECORDING" && <div className="statusBarUpload" />}
      {status !== "RECORDING" && status !== "PREVIEW" && (
        <div className="statusBarStatic" />
      )}
      {status === "PREVIEW" && <div className="statusBarPreview" />}
    </>
  );
});
