import React from "react";
import Table from "react-bootstrap/Table";

import ImportFileListItem from "./ImportFileListItem";
import { ImportFileListProps } from "../types";

import "../styles/ImportFileList.css";

export const ImportFileList = ({
  items,
  handleDelete,
  handleSubject,
}: ImportFileListProps) => {
  return (
    <Table responsive bordered hover>
      <thead className="cdBodyContainer">
        <tr>
          <th className="impFileName">File</th>
          <th className="impFileSize">Size</th>
          <th className="impFileDate">Date</th>
          <th className="impFileSubject">Subject</th>
          <th className="impFileStatus">Status</th>
          <th className="scrollColumn"></th>
        </tr>
      </thead>
      {items ? (
        <tbody className="cdBodyContainer" id="gridImport">
          {items.map((item: any) => (
            <ImportFileListItem
              key={item.id}
              item={item}
              handleDelete={handleDelete}
              handleSubject={handleSubject}
            />
          ))}
        </tbody>
      ) : (
        <tbody className="cdBodyContainer">
          <tr>
            <td colSpan={6} align="center" className="boldBlack"></td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};
