import { useRef, useEffect, useContext, useState } from "react";
import "react-tippy/dist/tippy.css";
import ActionBar from "../components/ActionBar";
import { Tooltip } from "react-tippy";
import CheckBox from "./CheckBox";
import { store } from "../context/store";

import { RollOverImage } from "../components/RollOverImage";
import normal from "../assets/eye.png";
import normalHover from "../assets/eye_hover.png";
import disabled from "../assets/eye_not_viewed.png";
import disabledHover from "../assets/eye_not_viewed_hover.png";

import placeholder from "../assets/placeholder.png";

import Highlighter from "react-highlight-words";
import OpenAILogo from "../assets/openai.png";

const MessageListItem = ({
  propKey,
  message,
  columnInfo,
  rowNum,
  level,
  active,
  showMessage,
  expandCollapse,
  showReply,
  execDelete,
  setSelectedIndex,
  handleCopy,
  onCheck,
  execDownload,
  keywordSearch,
  showTranscript,
  execTranscriptPDF,
}: any) => {
  const paddingLeft = 5;
  const toolRef = useRef<any>();
  const scrollRef = useRef<any>();
  const { state } = useContext(store);
  // refer to the context state as just context to avoid
  // confusion between context and component state
  const context = state;

  const [keywords, setKeywords] = useState<(string | RegExp)[]>([]);

  useEffect(() => {
    if (active && scrollRef.current) scrollRef.current.scrollIntoViewIfNeeded();
  }, [active, scrollRef]);

  useEffect(() => {
    if (keywordSearch)
      setKeywords(keywordSearch.split(/\s/).filter((word: any) => word));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordSearch]);

  const displayDate = (_date: Date) => {
    let _dateLines = _date.toLocaleString().split(",");

    return _dateLines.length ? (
      <>
        {_dateLines[0]}
        <br /> {_dateLines[1]}
      </>
    ) : null;
  };
  if (message.Hidden || message.Status === "delete") return null;

  return (
    <>
      <tr
        id={message.Path}
        key={`${propKey}_row`}
        className={`mt-2 ${message.New ? "newRow" : ""} tableHeight ${
          active || message.checked ? "active" : ""
        }`}
        onClick={() => {
          setSelectedIndex(rowNum);
        }}
        ref={scrollRef}
      >
        <td className="img-frame-wrapper">
          <div
            style={{
              paddingLeft: paddingLeft * level,
              position: "relative",
            }}
            className="img-grid"
          >
            {message.HasChildren ? (
              <div
                className={`${message.ExpandClass} normalText`}
                style={{
                  paddingLeft: paddingLeft * level,
                  paddingRight: 10,
                }}
                data-id={message.Id}
                onClick={() => {
                  if (expandCollapse) expandCollapse(message.Id);

                  // TODO Pin the scrolling!!!!!!!!!!!

                  // const _table = document.getElementById("grid");
                  // const pos = _table ? _table.scrollTop : 0;
                  // console.log(pos);

                  // var counter = setInterval(() => {
                  //   clearInterval(counter);
                  //   if (_table) _table.scrollTo(0, pos);
                  // }, 50);
                }}
              ></div>
            ) : // need to comment out the 'false' condition when supporting threading
            message.ParentId && false ? (
              <div
                style={{ paddingLeft: paddingLeft * level, paddingRight: 10 }}
              >
                &#8627;
              </div>
            ) : context && context.desktop ? (
              <div style={{ paddingRight: 15 }}>
                {" "}
                <CheckBox
                  key={message.rowNum}
                  name={""}
                  value={message.Id}
                  tick={message.checked}
                  overlay={false}
                  onCheck={(e) => {
                    setSelectedIndex(rowNum);
                    onCheck(message.Id, e.target.checked);
                  }}
                />{" "}
              </div>
            ) : null}

            {message.Status === "sent" && message.ThumbnailUrl && (
              <div>
                {context && !context.desktop ? (
                  <div style={{ paddingRight: 15 }}>
                    {" "}
                    <CheckBox
                      key={message.rowNum}
                      name={""}
                      value={message.Id}
                      tick={message.checked}
                      overlay={true}
                      onCheck={(e) => {
                        setSelectedIndex(rowNum);
                        onCheck(message.Id, e.target.checked);
                      }}
                    />{" "}
                  </div>
                ) : null}
                <img
                  src={message.ThumbnailUrl}
                  className={`img-wrapper clickable`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="fire fly"
                  title="View Firefly..."
                  onClick={() => {
                    if (showMessage) {
                      console.log(message);
                      showMessage(message.Id, rowNum);
                    }
                  }}
                />
                {context && !context.desktop ? (
                  <div className="subject-line-clamp" title={message.Subject}>
                    {message.Subject}
                  </div>
                ) : null}
                {message.RecordingDuration ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      textAlign: "center",
                      marginTop: 2,
                    }}
                  >
                    {message.RecordingDuration}
                  </div>
                ) : null}
              </div>
            )}
            {(message.Status !== "sent" ||
              (message.Status === "sent" && !message.ThumbnailUrl)) && (
              <div>
                <img
                  src={placeholder}
                  className={`img-wrapper ${
                    level > 1 ? "ml-" + level * 2 : ""
                  }`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="fire fly"
                  title="Working.."
                />
              </div>
            )}
          </div>
        </td>

        <td className="ffTargets">
          <div className="gridwrapper">
            <div className="one">
              <span className="makeBold">From:</span> {message.From}
            </div>
            <div className="two">
              <span className="makeBold">To:</span>
              {message["Targets"].map((target: any, i: number) => {
                return (
                  <span className="two" key={`${propKey}_target_${i}`}>
                    {" "}
                    {target.Name}{" "}
                    {target.Type === "project" ? (
                      <>
                        <a
                          href={`https://dev.azure.com/murphyassoc/${target.Name}/_workitems/edit/${target.Id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          #{target.Id}
                        </a>
                        <br />
                      </>
                    ) : target.Type === "public" ? (
                      ""
                    ) : (
                      <br />
                    )}
                  </span>
                );
              })}
            </div>

            <div className="three">
              <Tooltip
                trigger="mouseenter"
                html={
                  message["ViewedList"] ? (
                    message["ViewedList"]
                  ) : (
                    <div>Not viewed</div>
                  )
                }
                arrow={true}
                position="top"
                theme="light"
              >
                <RollOverImage
                  enabled={message["ViewedList"] ? true : false}
                  normal={normal}
                  normalHover={normalHover}
                  disabled={disabled}
                  disabledHover={disabledHover}
                  altText="viewed"
                />
              </Tooltip>
            </div>
          </div>
        </td>

        <td className="ffMessage">
          {message.AIEnabled && message.Subject === "" ? (
            <div className="line-clamp">
              <Tooltip
                trigger="mouseenter"
                html={message["Summary"]}
                arrow={true}
                position="top"
                theme="light"
                className="summaryExcerpt line-clamp"
              >
                <img src={OpenAILogo} alt="OpenAI" title="OpenAI" />
                <span>{message["Summary"]}</span>
              </Tooltip>
            </div>
          ) : (
            message["Subject"]
          )}
        </td>

        {showTranscript && (
          <td className="ffMessage">
            <div className="line-clamp">
              <Highlighter
                highlightClassName="highlight-keywords"
                searchWords={keywords}
                autoEscape={true}
                textToHighlight={message["Transcript"]}
                caseSensitive={false}
                highlightStyle={{ fontWeight: "normal", cursor: "pointer" }}
              />
            </div>
          </td>
        )}

        <td className="ffDate">
          <div className="gridwrapper">
            <div className="one">{displayDate(message["CreatedDate"])}</div>
            <div className="row two">
              <div
                className={`status-${
                  message["Status"] === "sent" ? "sent" : "other"
                }`}
              ></div>
              {message["Status"]}
            </div>
            <div className="three">
              <ActionBar
                ref={toolRef}
                position="left"
                theme="light"
                title="this is a tool tip"
                disabled={
                  message.Status !== "sent" && message.Status !== "submitted"
                }
                interactive
                html={
                  <div
                    className="actionBar"
                    onMouseLeave={() => {
                      if (toolRef.current) toolRef.current.hover(false);
                    }}
                    onMouseEnter={() => {
                      if (toolRef.current) toolRef.current.hover(true);
                    }}
                  >
                    <strong
                      className="navLink mr-1"
                      onClick={() => {
                        if (showMessage) showMessage(message.Id, rowNum);
                        if (toolRef.current) toolRef.current.close();
                      }}
                      style={{ cursor: "pointer", alignContent: "start" }}
                    >
                      View
                    </strong>
                    {!message.Group && (
                      <>
                        |
                        <strong
                          className="navLink ml-1 mr-1"
                          onClick={() => {
                            if (showReply) showReply(message.Id, rowNum);
                            if (toolRef.current) toolRef.current.close();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Reply
                        </strong>
                      </>
                    )}
                    |
                    <strong
                      className="navLink mr-1 ml-1"
                      onClick={() => {
                        handleCopy(rowNum);

                        if (toolRef.current) toolRef.current.close();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Copy Text
                    </strong>
                    |{" "}
                    <strong
                      className="navLink mr-1 ml-1"
                      onClick={() => {
                        handleCopy(rowNum, true);

                        if (toolRef.current) toolRef.current.close();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Copy Link
                    </strong>
                    {!message.Group && (
                      <>
                        |
                        <strong
                          className="navLink mr-1 ml-1"
                          onClick={() => {
                            if (execDownload) {
                              execDownload(message.Id, rowNum);
                              if (toolRef.current) toolRef.current.close();
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Download
                        </strong>
                      </>
                    )}
                    {message.Group &&
                      message.UserId === context.account.UserId && (
                        <>
                          |
                          <strong
                            className="navLink mr-1 ml-1"
                            onClick={() => {
                              if (execDelete) {
                                execDelete(message.Id, rowNum);
                                if (toolRef.current) toolRef.current.close();
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Delete
                          </strong>
                        </>
                      )}
                    {!message.Group && message.Public && (
                      <>
                        |
                        <strong
                          className="navLink mr-1 ml-1"
                          onClick={() => {
                            if (execDelete) {
                              execDelete(message.Id, rowNum);
                              if (toolRef.current) toolRef.current.close();
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Delete
                        </strong>
                      </>
                    )}
                    <>
                      |
                      <strong
                        className="navLink mr-1 ml-1"
                        onClick={() => {
                          if (execTranscriptPDF) {
                            execTranscriptPDF(message.Id, rowNum);
                            if (toolRef.current) toolRef.current.close();
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        PDF
                      </strong>
                    </>
                  </div>
                }
              >
                <button
                  className={`standardButton ${
                    message.Status === "sent" ? "" : "disabled"
                  }`}
                  disabled={message.Status !== "sent"}
                >
                  +
                </button>
              </ActionBar>
            </div>
          </div>
        </td>
      </tr>
      {message.HasChildren
        ? message.Replies.map((childMessage: any) => {
            return (
              <MessageListItem
                key={childMessage.Id}
                propKey={childMessage.Id}
                message={childMessage}
                columnInfo={columnInfo}
                rowNum={rowNum}
                level={level + 1}
                showMessage={showMessage}
                expandCollapse={expandCollapse}
                showReply={showReply}
                execDelete={execDelete}
                execDownload={execDownload}
                execTranscriptPDF={execTranscriptPDF}
              />
            );
          })
        : null}

      {active && (
        <script>
          console.log({message.Path}); document.getElementById({message.Path}
          ).scrollIntoView();
        </script>
      )}
    </>
  );
};

export default MessageListItem;
