import React from "react";
import record from "./record";
import { saveFile } from "./utils";
import {
  RecorderOptions,
  Recorder,
  RecordWebcamHook,
  CAMERA_STATUS,
  CAPTURE_TYPE,
  DeviceTypes,
  CaptureConsrtaints,
  MediaSources,
} from "./types";

const DEFAULT_OPTIONS: RecorderOptions = {
  type: "video",
  mimeType: "video/x-matroska;codecs=avc1",
  timeSlice: 1000,
  video: {
    minWidth: 1280,
    minHeight: 720,
    maxWidth: 1280,
    maxHeight: 720,
    // maxWidth: 1920,
    // maxHeight: 1080,
    minAspectRatio: 1.77,
  },
  onTimeStamp: (timestamp: any, timestamps: any) => {},
};

const DEFAULT_AUDIO_ONLY_OPTIONS: RecorderOptions = {
  type: "audio",
  mimeType: "audio/webm",
  timeSlice: 1000,
  onTimeStamp: (timestamp: any, timestamps: any) => {},
};

type useRecordWebcamArgs = {
  downloadFileName?: string;
  recordingLength?: number;
  options?: RecorderOptions;
};

export function useRecordWebcam(args?: useRecordWebcamArgs): RecordWebcamHook {
  const webcamRef = React.useRef<HTMLVideoElement>(null);
  const previewRef = React.useRef<HTMLVideoElement>(null);
  const [status, setStatus] = React.useState<CAMERA_STATUS>(
    CAMERA_STATUS.CLOSED
  );
  const [captureType, setCaptureType] = React.useState<CAPTURE_TYPE>(
    CAPTURE_TYPE.CAMERA
  );

  const [secondsRecorded, setSecondsRecorded] = React.useState<number>(0);
  const [streamState, setStreamState] = React.useState<string>("inactive");

  const [cameraError, setCameraError] = React.useState<string | undefined>("");

  const [recorder, setRecorder] = React.useState<Recorder | null>(null);

  const lastPauseTimeRef = React.useRef<number | null>(null);
  const [isRecording, setIsRecording] = React.useState(false);
  const startTimeRef = React.useRef<number | null>(null);

  const [audioSource, setAudioSource] = React.useState<string | undefined>(
    undefined
  );
  const [videoSource, setVideoSource] = React.useState<string | undefined>(
    undefined
  );

  const [sources, setSources] = React.useState<MediaSources>(null);

  const openCamera = async () => {

    DEFAULT_OPTIONS.onTimeStamp = onTimeStamp;

    const constraints: CaptureConsrtaints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: {
        deviceId: videoSource ? { exact: videoSource } : undefined,
        width: 1280,
        height: 720,
      },
    };

    setCameraError("");

    const recorderInit = await record(
      args?.options || DEFAULT_OPTIONS,
      captureType,
      constraints
    );

    setRecorder(recorderInit);

    if (webcamRef?.current) {
      webcamRef.current.srcObject = recorderInit.stream;
      // @ts-ignore
      addStreamStopListener(recorderInit.stream, (event) => {
        setStreamState(event);
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 100)); //1700
  };

  const openMicrophone = async () => {
    DEFAULT_AUDIO_ONLY_OPTIONS.onTimeStamp = onTimeStamp;

    const constraints: CaptureConsrtaints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
    };

    const recorderInit = await record(
      args?.options || DEFAULT_AUDIO_ONLY_OPTIONS,
      captureType,
      constraints
    );

    setRecorder(recorderInit);

    if (webcamRef?.current) {
      webcamRef.current.srcObject = recorderInit.stream;

      // @ts-ignore
      addStreamStopListener(recorderInit.stream, (event) => {
        setStreamState(event);
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 100)); //1700
  };

  //adding event listener
  const addStreamStopListener = (
    stream: MediaStream,
    callback: (event: string) => void
  ) => {
    stream.addEventListener(
      "ended",
      () => {
        callback("ended");
        callback = () => {};
      },
      false
    );
    stream.addEventListener(
      "inactive",
      () => {
        callback("inactive");
        callback = () => {};
      },
      false
    );

    stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.addEventListener(
        "ended",
        () => {
          callback("ended track");
          callback = () => {};
          // stop().then((resolve) => {
          //   callback = () => {};
          // });
        },
        false
      );
      track.addEventListener(
        "inactive",
        () => {
          callback("inactive");
          callback = () => {};
        },
        false
      );
    });

    if (stream.getVideoTracks().length > 0) {
      stream.getVideoTracks()[0].onended = () => {
        callback("onended");
      };
    }
  };

  const resetPreview = () => {
    if (previewRef?.current) {
      previewRef.current.removeAttribute("src");
      previewRef.current.load();
    }
  };

  const stopStream = () => {
    if (recorder?.stream.id) recorder.stream.stop();

    if (recorder?.secondaryStreams) {
      for (let i = 0; i < recorder?.secondaryStreams.length; i++) {
        recorder?.secondaryStreams[i]
          .getTracks()
          .forEach((track: any) => track.stop());
        recorder?.secondaryStreams[i].stop();
      }
    }
  };

  const close = (error?: any) => {
    resetPreview();
    setStatus(CAMERA_STATUS.CLOSED);
    stopStream();

    if (typeof error !== "undefined") {
      if (error.message.includes("Permission denied")) {
        setCameraError("Screen Share was cancelled by user.");
      } else {
        setCameraError(error);
      }
    }
  };

  const open = async () => {
    try {
      setStatus(CAMERA_STATUS.INIT);
      setSecondsRecorded(0);

      if (captureType === CAPTURE_TYPE.AUDIO) {
        await openMicrophone();
        setStatus(CAMERA_STATUS.AUDIO);
      } else {
        if (status !== CAMERA_STATUS.INIT) {
          await openCamera();
        }

        setStatus(CAMERA_STATUS.OPEN);
      }
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);

      setCameraError(error);
      console.error({ error });
      close(error);
    }
  };

  const stop = async () => {
    try {
      if (recorder?.stopRecording) {
        await recorder.stopRecording();
        const blob = await recorder.getBlob();
        const preview = window.URL.createObjectURL(blob);
        if (previewRef.current) {
          previewRef.current.src = preview;
        }
        stopStream();
        setStatus(CAMERA_STATUS.PREVIEW);
        return;
      }
      throw new Error("Stop recording error!");
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);
      setCameraError(error);
      console.error({ error });
    }
  };

  const pause = async () => {
    try {
      if (recorder?.pauseRecording) {
        await recorder.pauseRecording();

        if (isRecording) {
          updateSecondsRecorded();
          setIsRecording(false);
        }

        setStatus(CAMERA_STATUS.PAUSED);
        return;
      }
      throw new Error("Pause recording error!");
    } catch (error) {
      setStatus(CAMERA_STATUS.ERROR);
      console.error({ error });
    }
  };

  const resume = async () => {
    try {
      if (recorder?.resumeRecording) {
        await recorder.resumeRecording();

        if (!isRecording) {
          startTimeRef.current = new Date().getTime();
          setIsRecording(true);
        }

        setStatus(CAMERA_STATUS.RECORDING);
        return;
      }
      throw new Error("Resume recording error!");
    } catch (error) {
      setStatus(CAMERA_STATUS.ERROR);
      console.error({ error });
    }
  };

  const start = async () => {
    try {
      if (recorder?.startRecording) {
        await recorder.startRecording();
        setStatus(CAMERA_STATUS.RECORDING);

        setSecondsRecorded(0);
        totalRecordingTimeRef.current = 0;
        startTimeRef.current = null;

        //TODO: use set duration here
        if (args?.recordingLength) {
          const length = args.recordingLength * 1000;
          await new Promise((resolve) => setTimeout(resolve, length));
          await stop();
          stopStream();
        }
        return;
      }
      throw new Error("Recorder not initialized!");
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);
      setCameraError(error);
      console.error({ error });
    }
  };

  const retake = async () => {
    try {
      setSecondsRecorded(0);
      totalRecordingTimeRef.current = 0;
      startTimeRef.current = null;

      await close();
      await open();
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);
      setCameraError(error);
      console.error({ error });
    }
  };

  const download = async () => {
    try {
      if (recorder?.getBlob) {
        const blob = await recorder.getBlob();
        const filename = args?.downloadFileName
          ? `${args.downloadFileName}.mp4`
          : `${new Date().getTime()}.mp4`;
        saveFile(filename, blob);
        return;
      }
      throw new Error("Error downloading file!");
    } catch (error: any) {
      setCameraError(error);
      setStatus(CAMERA_STATUS.ERROR);
      console.error({ error });
    }
  };

  const getRecording = async () => {
    try {
      return await recorder?.getBlob();
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);
      setCameraError(error);
      console.error({ error });
      return;
    }
  };

  const changeCaptureType = (event: any) => {
    setCaptureType(event.target.value);
    resetPreview();

    if (event.target.value === CAPTURE_TYPE.IMPORT) {
      setStatus(CAMERA_STATUS.LOCAL);
    }

    if (event.target.value === CAPTURE_TYPE.AUDIO) {
      setStatus(CAMERA_STATUS.AUDIO);
    }
  };

  const totalRecordingTimeRef = React.useRef<number>(0);
  const updateSecondsRecorded = () => {
    const now = new Date().getTime();
    if (startTimeRef.current !== null) {
      totalRecordingTimeRef.current += now - startTimeRef.current;
    }
    startTimeRef.current = now;
    const duration = totalRecordingTimeRef.current / 1000;
    setSecondsRecorded(Math.floor(duration));
  };

  const onTimeStamp = (timestamp: any, timestamps: any) => {
    if (lastPauseTimeRef.current === null) {
      updateSecondsRecorded();
    }
  };

  const setDuration = (duration: number, callback: () => void) => {
    try {
      if (recorder?.setRecordingDuration) {
        recorder.setRecordingDuration(duration, callback);
        return;
      }
      throw new Error("Error setting duration!");
    } catch (error: any) {
      setStatus(CAMERA_STATUS.ERROR);
      setCameraError(error);
      console.error({ error });
    }
  };

  const getDevices = async () => {
    var deviceInfos = await navigator.mediaDevices.enumerateDevices();
    const audio = deviceInfos.filter(
      (device: MediaDeviceInfo) => device.kind === "audioinput"
    );
    const video = deviceInfos.filter(
      (device: MediaDeviceInfo) => device.kind === "videoinput"
    );

    var deviceTypes: DeviceTypes = {
      video,
      audio,
    };

    return deviceTypes;
  };

  const changeAudioSource = (event: any) => {
    setAudioSource(event.target.value);
    console.log(event.target.value);
  };
  const changeVideoSource = (event: any) => {
    setVideoSource(event.target.value);
  };

  const changeSources = (sources: MediaSources) => {
    setAudioSource(sources?.audioSource);
    setVideoSource(sources?.videoSource);

    setSources(sources);
  };

  return {
    close,
    download,
    open,
    previewRef,
    retake,
    getRecording,
    start,
    status,
    captureType,
    audioSource,
    videoSource,
    sources,
    stop,
    stopStream,
    webcamRef,
    changeCaptureType,
    setStatus,
    secondsRecorded,
    streamState,
    setDuration,
    getDevices,
    changeAudioSource,
    changeVideoSource,
    changeSources,
    cameraError,
    pause,
    resume,
  };
}
