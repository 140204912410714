import { sortColumn } from "./types";

export function isChromeOnIOS(): boolean {
  const userAgent = window.navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isChrome = /CriOS/.test(userAgent);

  return isIOS && isChrome;
}

export const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  return isSafari;
};

export const convertISO8601ToSeconds = (input: string): number => {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?$/;
  var hours = 0,
    minutes = 0,
    seconds = 0,
    totalseconds = 0;

  if (reptms.test(input)) {
    var matches = reptms.exec(input);
    if (matches) {
      if (matches[1]) hours = Number(matches[1]);
      if (matches[2]) minutes = Number(matches[2]);
      if (matches[3]) seconds = Number(matches[3]);
    }
    totalseconds = hours * 3600 + minutes * 60 + seconds;
  }

  return totalseconds;
};

export const secondsToHms = (seconds: number) => {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? "h" : "h") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m" : "m ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const addDelay = (
  interval: number,
  callback: () => void,
  initializer?: () => void
) => {
  if (initializer) initializer();

  var counter = setInterval(() => {
    clearInterval(counter);
    if (callback) callback();
  }, interval);
};

export const findNode = (
  messages: any,
  id: string,
  callback?: (parent: string) => void
) => {
  let message = messages.find(({ Id }: any) => Id === id);

  if (!message) {
    let children = messages.filter((_message: any) => _message.HasChildren);

    for (let el of children) {
      message = findNode(el.Replies, id);
      if (message) {
        if (callback) callback(el);
        break;
      }
    }
    if (message) {
      return message;
    }
  }
  return message;
};

export const processRow = (row: any, myEmail: string, callback: () => void) => {
  if (row.Status !== "delete") {
    row.HasChildren =
      row.Replies &&
      row.Replies.length &&
      row.Replies.find((message: any) => {
        return message.Status !== "delete";
      });

    row.Inbox = !row.Sent;

    row.New =
      Array.isArray(row["Viewers"]) &&
      row["Viewers"].length === 0 &&
      row["From"] &&
      row.Inbox;

    // store react element to be used for tool tips
    row.ViewedList =
      Array.isArray(row["Viewers"]) && row["Viewers"].length > 0 ? (
        <>
          Viewed by:
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {row["Viewers"].map(
              (viewer: any, i: number) =>
                viewer.Name !== "undefined" && (
                  <li key={`${row["Id"]}_view_${i}`}>{viewer.Name}</li>
                )
            )}
          </ul>
        </>
      ) : null;

    // flatten targets
    row.Audience =
      Array.isArray(row["Targets"]) && row["Targets"].length > 0
        ? row["Targets"]
            .map((target: any) => {
              return target.Type === "project"
                ? target.Name + target.Id
                : target.Name;
            })
            .join()
        : "";

    // is this an ADO ff?
    row.ADO =
      Array.isArray(row["Targets"]) && row["Targets"].length > 0
        ? row["Targets"]
            .map((target: any) => {
              return target.Type;
            })
            .includes("project")
        : false;

    // is this a Public ff?
    row.Public =
      Array.isArray(row["Targets"]) && row["Targets"].length > 0
        ? row["Targets"]
            .map((target: any) => {
              return target.Type;
            })
            .includes("public")
        : false;

    // is this a Group ff?
    row.Group =
      Array.isArray(row["Targets"]) && row["Targets"].length > 0
        ? row["Targets"]
            .map((target: any) => {
              return target.Type;
            })
            .includes("group")
        : false;

    row.Sent = row.ADO ? false : row.Sent;

    //special case requested by Joel -- FF sent to yourself shows up in Inbox
    let targetIncludesOnlyYourself =
      Array.isArray(row["Targets"]) && row["Targets"].length === 1
        ? row["Targets"]
            .map((target: any) => {
              return target.Name.toLowerCase();
            })
            .includes(myEmail) &&
          row["From"] &&
          row["From"].toLowerCase() === myEmail
        : false;

    row.Inbox = targetIncludesOnlyYourself ? true : row.Inbox;

    // convert date
    if (row["CreatedDate"]) row["CreatedDate"] = new Date(row["CreatedDate"]);

    // convert durcation
    if (row["RecordingDuration"])
      row["RecordingDuration"] = secondsToHms(
        convertISO8601ToSeconds(row["RecordingDuration"])
      );

    // walk children
    if (row.HasChildren) {
      row.ExpandClass = "expanded";
      row.Hidden = false;
      row.Replies.forEach((childRow: any, i: number) => {
        childRow.Path = row.Path + `/${i}`;
        childRow.Hidden = false;
        processRow(childRow, myEmail, callback);
      });
    } else row.ExpandClass = "";

    row.checked = false;

    if ((row.Status === "processing" || row.Status === "submitted") && callback)
      callback();

    return row;
  }
  return null;
};

export const processRows = (
  rows: any,
  myEmail: string,
  callback: () => void
) => {
  // augment the results to include Sent, Inbox, New, ADO, and Group flags

  rows.forEach((row: any, i: number) => {
    // rows with a null from are those you sent and being processed by the pipeline
    row.Sent =
      !row["From"] || row["From"].toLowerCase() === myEmail.toLowerCase();
    row.Path = `${i}`;

    processRow(row, myEmail.toLowerCase(), callback);
  });

  return rows;
};

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const scrollToTop = () => {
  // scroll to the top on page change
  const _table = document.getElementById("grid");
  if (_table) _table.scrollTo(0, 0);
};

export const sortData = (
  sortColumn: sortColumn,
  data: any,
  checkedFirst: boolean = false
) => {
  let sortedMessages = [...data];
  sortedMessages.sort((a: any, b: any) => {
    if (Array.isArray(a[sortColumn.column.value])) {
      const stringA = a[sortColumn.column.value][0].Name.toUpperCase();
      const stringB = b[sortColumn.column.value][0].Name.toUpperCase();
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      // equal
      return 0;
    }

    if (sortColumn.column.sortType === "string") {
      const stringA = a[sortColumn.column.value]
        ? a[sortColumn.column.value].toUpperCase()
        : "A";
      const stringB = b[sortColumn.column.value]
        ? b[sortColumn.column.value].toUpperCase()
        : "A";
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      // equal
      return 0;
    }

    if (sortColumn.column.sortType === "number") {
      return a[sortColumn.column.value] - b[sortColumn.column.value];
    }

    if (sortColumn.column.sortType === "date") {
      const dateA = Date.parse(a[sortColumn.column.value]);
      const dateB = Date.parse(b[sortColumn.column.value]);
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      // equal
      return 0;
    }

    return 0;
  });

  if (sortColumn.direction === "desc") {
    sortedMessages.reverse();
  }

  if (checkedFirst) {
    sortedMessages.sort((a: any, b: any) => {
      const aChecked = a["checked"] ? a["checked"] : 0;
      const bChecked = b["checked"] ? b["checked"] : 0;

      if (aChecked > bChecked) {
        return -1;
      }
      if (aChecked < bChecked) {
        return 1;
      }
      // equal
      return 0;
    });
  }

  return sortedMessages;
};
