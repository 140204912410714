import "../styles/Checkbox.css";

export interface CheckBoxProps {
  name: string;
  value: string;
  tick: boolean;
  overlay: boolean;
  onCheck: (e: any) => void;
}

export default function CheckBox({
  name,
  value,
  tick,
  overlay,
  onCheck,
}: CheckBoxProps) {
  return (
    <label
      className={`styledCheckbox ${tick || false ? "checked" : "unchecked"} ${
        overlay ? "overlay" : null
      }`}
    >
      <input
        className="hiddenCheckbox"
        name={name}
        type="checkbox"
        value={value}
        checked={tick || false}
        onChange={onCheck}
      />

      <svg
        visibility={tick || false ? "visible" : "hidden"}
        viewBox={value === "all" ? "0 3 24 24" : "0 7 24 24"}
        fill="none"
        stroke=" white"
        strokeWidth="3px"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>

      <span style={{ marginLeft: 10 }}>{name}</span>
    </label>
  );
}
