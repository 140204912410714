// token handling
let refreshPromise: Promise<unknown> | undefined;

export const expireToken = () => {
  console.log("expired!!!");
  refreshPromise = undefined;
};

export const getAccessToken = async (GetToken: any) => {
  if (refreshPromise) {
    return refreshPromise;
  } else {
    refreshPromise = new Promise((resolve, reject) => {
      GetToken()
        .then((response: any) => {
          resolve(response.accessToken);
        })
        .catch((err: any) => {
          expireToken();
          reject(err);
        });
    });

    return refreshPromise;
  }
};
