import { State, Action } from "../types";
import { processRows } from "../../utils";
import { uniqueEmails, addUniqueEmail } from "../../_utils";

import { TeamMember } from "../../types";

export default function reducer(state: State, action: Action) {
  switch (action.type) {
    case "login":
      const account = action.payload;

      return {
        ...state,
        isLoggedIn: true,
        // isSetup: false,
        enablePolling: false,
        account,
        // projects,
        // groups,
      };
    case "setup":
      // @ts-ignore
      let _targets: any =
        state.account && state.account.AvailableTargets
          ? state.account.AvailableTargets
          : [];

      // @ts-ignore
      let _workItemTeams: any =
        action.payload &&
        action.payload.WorkItemTeams &&
        action.payload.WorkItemTeams.length > 0
          ? action.payload.WorkItemTeams
          : [];

      const proj = _targets.filter((p: any) => p.Type === "project");

      const adoProjects = proj.map((item: any, i: any) => {
        return {
          Id: (i + 1).toString(),
          name: item.Name,
          parentId: "0",
        };
      });

      const adoTeams = adoProjects
        .map((adoProject: any) => {
          let project = _workItemTeams.filter(
            (team: { Id: any; Name: any; Members: []; Project: any }) =>
              team.Project === adoProject.name
          );

          let adoTeam = project.map((team: any) => {
            return {
              Id: team.Id,
              name: team.Name,
              parentId: adoProject.Id,
            };
          });

          return adoTeam;
        })
        .flat();

      const adoTeamMembers = adoTeams
        .map((adoTeam: any) => {
          let teamWithMembers = _workItemTeams.filter(
            (team: { Id: any; Name: any; Members: []; Project: any }) =>
              team.Id === adoTeam.Id
          );

          let members = teamWithMembers
            .map((team: any) => {
              let teamMembers = team.Members.map((member: any) => {
                return {
                  Id: member.Id,
                  name: member.DisplayName,
                  parentId: team.Id,
                  email: member.Email,
                };
              });

              return teamMembers;
            })
            .flat();

          return members;
        })
        .flat();

      const categories: TeamMember[] = [
        ...adoProjects,
        ...adoTeams,
        ...adoTeamMembers,
      ];

      _workItemTeams = categories;

      if (!_targets)
        return {
          ...state,
          isLoggedIn: true,
          isSetup: true,
          enablePolling: false,
          // account,
          projects: undefined,
        };

      // get the list of projects
      let projects: any | undefined =
        _targets.length > 0 &&
        _targets.filter((_target: any) => {
          return _target.Type === "project";
        });

      // get the list of Groups
      let groupsRaw: any | undefined =
        _targets.length > 0 &&
        _targets.filter((_target: any) => {
          return _target.Type === "group";
        });

      let groups = groupsRaw
        ? groupsRaw.map((grp: any) => {
            return { ...grp, IsSelected: false };
          })
        : undefined;

      if (
        !Array.isArray(groups) ||
        (Array.isArray(groups) && groups.length === 0)
      )
        groups = undefined;

      if (
        !Array.isArray(projects) ||
        (Array.isArray(projects) && projects.length === 0)
      )
        projects = undefined;

      if (
        !Array.isArray(_workItemTeams) ||
        (Array.isArray(_workItemTeams) && _workItemTeams.length === 0)
      )
        _workItemTeams = undefined;

      if (_workItemTeams) {
        return {
          ...state,
          //isLoggedIn: true,
          isSetup: true,
          //enablePolling: false,
          // account,
          projects,
          teams: _workItemTeams,
          groups,
        };
      }

      return {
        ...state,
        isSetup: true,
        // account,
        projects,
        groups,
      };
    case "logout":
      return {
        ...state,
        isLoggedIn: false,
        isSetup: false,
        // enablePolling: false,
        // account: {},
        // messages: undefined,
        // uniqueEmails: undefined,
        // projects: undefined,
        // token: undefined,
      };
    case "messages":
      let _email = state.account ? state.account.EmailAddress : "";
      let _enablePolling = state.enablePolling;

      // decorate rows
      let _rows = processRows(action.payload, _email, () => {
        if (!_enablePolling) _enablePolling = true;
      });

      // distinct list of emails
      let _uniqueEmails = uniqueEmails(_rows);

      return {
        ...state,
        messages: _rows,
        uniqueEmails: _uniqueEmails,
        enablePolling: _enablePolling,
      };

    case "deleteMessage":
      let Id = action.payload;

      let gridData = state.messages.filter(
        (message: { Id: any }) => message.Id !== Id
      );

      console.log(state.messages, gridData);
      let groupGridData = {};

      if (state.groupMessages && Array.isArray(state.groupMessages)) {
        groupGridData = state.groupMessages.filter(
          (message: { Id: any }) => message.Id !== Id
        );
        console.log(state.groupMessages, groupGridData);
      }

      return {
        ...state,
        messages: gridData,
        groupMessages: groupGridData,
      };

    case "addEmail":
      return {
        ...state,
        uniqueEmails: addUniqueEmail(state.uniqueEmails, action.payload),
      };

    case "setEnablePolling":
      return {
        ...state,
        enablePolling: action.payload,
      };

    case "mediaquery":
      console.log(action.payload);
      return {
        ...state,
        desktop: action.payload,
      };

    case "groupMessages":
      let _groupEmail = state.account ? state.account.EmailAddress : "";
      let _groupEnablePolling = state.enablePolling;

      let _groupRows = processRows(action.payload, _groupEmail, () => {
        if (!_groupEnablePolling) _groupEnablePolling = true;
      });

      return {
        ...state,
        groupMessages: _groupRows,
        enablePolling: _groupEnablePolling,
      };

    case "managedUsers":
      let managedUserRows: any = action.payload;
      managedUserRows.forEach((row: any, i: number) => {
        // convert date
        if (row["LastLoginDate"])
          row["LastLoginDate"] = new Date(row["LastLoginDate"]);
        row["FullName"] = row["Name"]
          ? `${row["Name"]["FirstName"]} ${row["Name"]["LastName"]}`.trim()
          : "Unknown";
      });

      return {
        ...state,
        managedUsers: managedUserRows,
      };

    case "managedGroups":
      let managedGroupRows: any = action.payload;
      return {
        ...state,
        managedGroups: managedGroupRows,
      };

    default:
      return state;
  }
}
