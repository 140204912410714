import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ currentPage, postsPerPage, totalPosts, paginate }) => {
  return (
    <ReactPaginate
      previousLabel={"<<"}
      nextLabel={">>"}
      pageCount={Math.ceil(totalPosts / postsPerPage)}
      onPageChange={paginate}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      activeClassName={"active"}
      forcePage={currentPage}
    />
  );
};

export default Pagination;
