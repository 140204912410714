import { useRef, forwardRef, useEffect, useState, useContext } from "react";
import { NewMessage } from "./NewMessage";
import { Modal, Button, Container } from "react-bootstrap";

import { NewMessageProps } from "../types";
import { addDelay } from "../utils";
import { store } from "../context/store";
import { useActions } from "../context/actions";

export const LaunchNewMessage = forwardRef(
  (
    { onSave, message, suggestions, autoRecordType }: NewMessageProps,
    ref: any
  ) => {
    const actions = useActions();
    const childRef = useRef<any>();
    const { state } = useContext(store);
    // refer to the context state as just context to avoid
    // confusion between context and component state
    const context = state;
    const [showCreate, setShowCreate] = useState<boolean>(true);
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showSaveComplete, setShowSaveComplete] = useState<boolean>(false);

    useEffect(() => {
      // would need a new action here to get the unique emails
      // e.g. actions.GetUniqueEmails();
    }, []);

    return (
      <div className="public-ff-wrapper">
        <Modal
          show={showCreate}
          animation={false}
          onHide={() => {
            if (childRef.current) childRef.current.close();
            // wait for the close to finish before
            // destroying the object
            addDelay(50, () => {
              setShowCreate(false);
              setShowSaveComplete(false);
              setShowNew(true);
            });
          }}
          dialogClassName="new-modal custom-modal-dialog"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          size="lg"
          centered
        >
          <Modal.Header className="img-grid" closeButton>
            <h5 className="modal-title">Record a Firefly</h5>
          </Modal.Header>
          <Modal.Body>
            <NewMessage
              autoRecordType={autoRecordType}
              ref={childRef}
              suggestions={context.uniqueEmails}
              onSave={(id: string) => {
                // wait for the close to finish before
                // destroying the object
                addDelay(200, () => {
                  setShowCreate(false);
                  setShowSaveComplete(true);
                  setShowNew(true);
                });
              }}
            />
          </Modal.Body>
        </Modal>
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex flex-column">
            {showSaveComplete && <h3>Firefly Sent!</h3>}

            {showNew && (
              <Button
                variant="primary"
                className="ml-1 mr-1 mt-1"
                onClick={() => {
                  setShowCreate(true);
                  setShowSaveComplete(false);
                  setShowNew(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <span className="ml-2">New Firefly</span>
              </Button>
            )}
          </div>
        </Container>
      </div>
    );
  }
);
