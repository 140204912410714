import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { MessageList } from "../components/MessageList";
import SearchPanel from "../components/SearchPanel";

import { store } from "../context/store";

import { useActions } from "../context/actions";
import { useKeyPress } from "../useKeyPress";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {
  sortData,
  scrollToTop,
  addDelay,
  findNode,
  copyTextToClipboard,
} from "../utils";

import { Poll } from "../components/Poll";
import { Tooltip } from "react-tippy";
import { Form } from "react-bootstrap";

import {
  ALL_FILTERS,
  filterType,
  sortColumn,
  columnType,
  hotKeyMap,
  ALL_HOTKEYS,
} from "../types";
import { NewMessage } from "../pages/NewMessage";
import { Message } from "../components/Message";
import { Playlist } from "../components/Playlist";

import loadingimg from "../assets/loading_spinner.png";

export const Messages = () => {
  // Hooks
  const actions = useActions();
  const { state } = useContext(store);
  // refer to the context state as just context to avoid
  // confusion between context and component state
  const context = state;
  const childRef = useRef<any>();
  const filterRef = useRef<any>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  // Data
  const [gridData, setGridData] = useState<any | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [filter, setFilter] = useState<filterType>("Inbox");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [allGroupFilter, setAllGroupFilter] = useState(false);
  const [loadingGroupMessages, setLoadingGroupMessages] = useState(false);

  // Modals
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showPlayList, setShowPlayList] = useState<boolean>(false);
  const [activeMessage, setActiveMessage] = useState<any | null>(null);

  const [seekTime, setSeekTime] = useState(0);
  const [showTranscripts, setShowTranscripts] = useState(false);

  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  //////////////////////////////////////////////////////////////////
  // Sorting
  //////////////////////////////////////////////////////////////////
  const defaultSort: sortColumn = {
    column: {
      title: "Sent On",
      value: "CreatedDate",
      className: "ffDate",
      sortType: "date",
      visible: true,
    },
    direction: "desc",
  };

  const [sort, setSort] = useState<sortColumn>(defaultSort);

  //////////////////////////////////////////////////////////////////
  // Polling
  //////////////////////////////////////////////////////////////////
  const MAX_POLLING_COUNT: number = 10;
  const [pollingInterval, setPollingInterval] = useState<number>(0);
  const [pollingCount, setPollingCount] = useState<number>(0);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // if we are not polling set the interval to zero which will
  // stop polling, otherwise set it to POLLING_INTERVAL
  useEffect(() => {
    const POLLING_INTERVAL: number = 500 * 60 * 1;
    let _pollingInterval = context.enablePolling ? POLLING_INTERVAL : 0;
    setPollingInterval(_pollingInterval);
  }, [context.enablePolling]);

  Poll(() => {
    let _pollingCount = pollingCount + 1;
    console.log("polling count", _pollingCount, context.enablePolling);
    setPollingCount(_pollingCount);
    if (_pollingCount >= MAX_POLLING_COUNT) {
      actions.SetEnablePolling(false);
    } else {
      // only call getgriddata when enable polling is set
      // because that method will set enable polling to true
      // if there exists messages in not sent status
      if (context.enablePolling) {
        if (
          filter !== "Inbox" &&
          filter !== "ADO" &&
          filter !== "Sent" &&
          filter !== "Public"
        ) {
          //Group message polling
          isAllSelected ? handleGroupMessages() : handleGroupMessages(filter);
        } else {
          //Personal message polling
          actions.GetGridData();
        }
      }
    }
  }, pollingInterval);

  //////////////////////////////////////////////////////////////////
  // Hot Keys
  //////////////////////////////////////////////////////////////////

  const [hotKey, setHotKey] = useState<{ value: ALL_HOTKEYS }>({
    value: ALL_HOTKEYS.Empty,
  });

  const hotKeyMap: hotKeyMap = {
    F: ALL_HOTKEYS.New,
    ArrowUp: ALL_HOTKEYS.ArrowUp,
    ArrowDown: ALL_HOTKEYS.ArrowDown,
    ArrowLeft: ALL_HOTKEYS.ArrowLeft,
    ArrowRight: ALL_HOTKEYS.ArrowRight,
    Enter: ALL_HOTKEYS.View,
    " ": ALL_HOTKEYS.Check,
    A: ALL_HOTKEYS.CheckAll,
    "-": ALL_HOTKEYS.Delete,
    R: ALL_HOTKEYS.Reply,
    C: ALL_HOTKEYS.Copy,
    "1": ALL_HOTKEYS.Inbox,
    "2": ALL_HOTKEYS.Sent,
    "3": ALL_HOTKEYS.ADO,
    "4": ALL_HOTKEYS.Public,
    D: ALL_HOTKEYS.Download,
    K: ALL_HOTKEYS.Filter,
    T: ALL_HOTKEYS.PDF,
  };

  useKeyPress(
    (key: string) => {
      switch (hotKeyMap[key]) {
        case ALL_HOTKEYS.Inbox:
          setFilter(ALL_HOTKEYS.Inbox);
          break;
        case ALL_HOTKEYS.Sent:
          setFilter(ALL_HOTKEYS.Sent);
          break;
        case ALL_HOTKEYS.ADO:
          setFilter(ALL_HOTKEYS.ADO);
          break;
        case ALL_HOTKEYS.Public:
          setFilter(ALL_HOTKEYS.Public);
          break;
        case ALL_HOTKEYS.New:
          setShowCreate(true);
          break;
        case ALL_HOTKEYS.Filter:
          filterRef.current.focus();
          break;
        default:
          // we need to wrap in an object for react to register a change
          setHotKey((prev: any) => ({ ...prev, value: hotKeyMap[key] }));
      }
    },
    Object.keys(hotKeyMap),
    hotKeyMap
  );

  //////////////////////////////////////////////////////////////////
  // Search
  //////////////////////////////////////////////////////////////////

  // triggered whenever the underlying data is changed
  useEffect(() => {
    if (context.messages && Array.isArray(context.messages)) {
      // always start from context.messages which contains all data
      let filteredGridData = [...context.messages];

      // Filter based on search string
      if (searchString)
        filteredGridData = filteredGridData.filter((entry: any) =>
          Object.values(entry).some(
            (val) =>
              typeof val === "string" &&
              val.toLowerCase().includes(searchString.toLowerCase())
          )
        );

      // Filter based on left navigation
      filteredGridData = filteredGridData.filter((entry: any) =>
        // return only the rows where the filter property is set
        Object.entries(entry).some(([key, value]) => {
          return filter === key && value === true;
        })
      );

      // Restore the checked rows that pass the filter criteria
      // this will also reset checks when user selects a different
      // left nav (filter) item
      if (gridData) {
        let foundCount = 0;
        let checkedIds = gridData
          .filter((entry: any) => {
            return entry.checked;
          })
          .map((item: any) => item.Id);

        filteredGridData = filteredGridData.map((item: any) => {
          if (checkedIds.includes(item.Id)) {
            item.checked = true;
            foundCount++;
          } else item.checked = false;
          return item;
        });

        // handle the select all checkbox
        setIsAllSelected(foundCount === filteredGridData.length);
        setCheckCount(foundCount);
      }

      console.log(filteredGridData);
      setGridData(sortData(sort, filteredGridData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, context.messages, sort, filter]);

  //////////////////////////////////////////////////////////////////
  // Grid Data
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!gridData && context.isLoggedIn && context.isSetup) {
      setIsAllSelected(false);
      actions.GetGridData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, context.isLoggedIn, context.isSetup]);

  //////////////////////////////////////////////////////////////////
  // Passed in ID?
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    // if gridData have been returned and id specified
    // show the message
    if (context.isLoggedIn && id) {
      if (id) {
        handleView(id);
        history.push("/list");
      }
    }
    if (context.isLoggedIn && Array.isArray(gridData) && location) {
      if (location.pathname === "/new") {
        setShowCreate(true);
        history.push("/list");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, id, location, context.isLoggedIn]);

  //////////////////////////////////////////////////////////////////
  // Group Messages
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (
      context.groupMessages &&
      Array.isArray(context.groupMessages) &&
      filter !== "Inbox" &&
      filter !== "ADO" &&
      filter !== "Sent" &&
      filter !== "Public"
    ) {
      let filteredGridData = [...context.groupMessages];

      // Filter based on search string
      if (searchString)
        filteredGridData = filteredGridData.filter((entry: any) =>
          Object.values(entry).some(
            (val) =>
              typeof val === "string" &&
              val.toLowerCase().includes(searchString.toLowerCase())
          )
        );

      // Restore the checked rows that pass the filter criteria
      // this will also reset checks when user selects a different
      // left nav (filter) item
      if (gridData) {
        let foundCount = 0;
        let checkedIds = gridData
          .filter((entry: any) => {
            return entry.checked;
          })
          .map((item: any) => item.Id);

        filteredGridData = filteredGridData.map((item: any) => {
          if (checkedIds.includes(item.Id)) {
            item.checked = true;
            foundCount++;
          } else item.checked = false;
          return item;
        });

        // handle the select all checkbox
        setIsAllSelected(foundCount === filteredGridData.length);
        setCheckCount(foundCount);
      }

      setGridData(sortData(sort, filteredGridData));
      setLoadingGroupMessages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, context.groupMessages, sort, filter, allGroupFilter]);

  //////////////////////////////////////////////////////////////////
  // event handlers
  //////////////////////////////////////////////////////////////////
  const handleSort = (column: columnType) => {
    if (gridData) {
      if (column.sortType !== "none") {
        const direction = sort.direction === "asc" ? "desc" : "asc";
        setSort({ column, direction });
      }
    }
  };

  const handleSearch = ({ target }: any) => {
    let { value } = target;
    setSearchString(value);

    // scroll to the top on page change
    scrollToTop();
  };

  const handleGroupMessages = (id?: string) => {
    context.groupMessages = {};
    setLoadingGroupMessages(true);
    id ? actions.GetMessagesByGroup(id) : actions.GetAllGroupMessagesForUser();
  };

  const handleDelete = (id: string) => {
    if (gridData) {
      //message may be found in both .messages and .groupMessages if sent by you (Sent bucket)
      //regardless of where found as long as it's found in either, then just delete
      let message;
      let groupMessage;

      if (context.messages && Array.isArray(context.messages)) {
        message = findNode(context.messages, id);
      }

      if (context.groupMessages && Array.isArray(context.groupMessages)) {
        groupMessage = findNode(context.groupMessages, id);
      }

      if (message || groupMessage) {
        actions.DeleteMessage(message.Id);
      }
    }
  };

  const handleDownload = (id: string) => {
    if (gridData) {
      let message = findNode(context.messages, id);

      if (message) {
        //call Download API to return the SAS Url
        actions.DownloadMessage(message.Id).then((response: any) => {
          const link = document.createElement("a");
          link.href = response;
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        });
      }
    }
  };

  const getPlayList = () => {
    // let checkCount = gridData.reduce((total: number, curr: any) => {
    //   return curr.checked ? total + 1 : total;
    // }, 0);
    if (gridData) {
      let playList = gridData
        .filter((entry: any) => {
          return entry.checked;
        })
        .map((item: any) => {
          return {
            src: [item.StreamingUrl],
            thumbnail: item.ThumbnailUrl,
            title: item.Subject,
          };
        });

      return playList;
    } else return null;
  };
  const handleView = (id: string) => {
    if (gridData) {
      let checkCount = gridData.reduce((total: number, curr: any) => {
        return curr.checked ? total + 1 : total;
      }, 0);

      if (checkCount > 1) {
        setShowPlayList(true);
        return;
      }

      let messages = [...gridData];
      let message = findNode(messages, id);

      // sometimes you receive a message not directed to you so we have to look it up
      if (!message)
        actions.GetMessage(id).then((response: any) => {
          delayShow(response);
        });
      // it's a message that should be in your list
      else {
        // un-bold the message
        if (message.New) {
          message.New = false;
          setGridData(messages);
        }

        delayShow(message);
      }
    } else {
      actions.GetMessage(id).then((response: any) => {
        delayShow(response);
      });
    }
  };

  const handleTranscriptPDF = (id: string) => {
    if (gridData) {
      let checkCount = gridData.reduce((total: number, curr: any) => {
        return curr.checked ? total + 1 : total;
      }, 0);

      if (checkCount > 1) {
        let selectedMessages = gridData.filter((entry: any) => {
          return entry.checked;
        });

        let transcripts = "";

        selectedMessages.forEach((message: any) => {
          if (message.Transcript) {
            transcripts += message.Transcript.trim() + "\n\n";
          }
        });

        var docDefinition = {
          content: [transcripts],
        };

        pdfMake.createPdf(docDefinition).download(`FF-Transcripts.pdf`);
        return;
      }

      let messages = [...gridData];
      let message = findNode(messages, id);

      if (message.Transcript) {
        var docDefinitionMessage = {
          content: [message.Transcript.trim()],
        };

        pdfMake
          .createPdf(docDefinitionMessage)
          .download(`FF-${message.Id}.pdf`);
      }
    }
  };

  const handleTree = (id: string) => {
    if (gridData) {
      let _messages = [...gridData];

      let message = findNode(_messages, id);

      if (message) {
        let _expandClass =
          message.ExpandClass === "collapsed" ? "expanded" : "collapsed";
        message.ExpandClass = _expandClass;
        message.Replies.forEach((child: any) => {
          child.Hidden = _expandClass === "collapsed" ? true : false;
        });
        setGridData(_messages);
      }
    }
  };

  const handleViewedBy = (message: any) => {
    let viewMsgRequest: object = [
      {
        Type: "email",
        Name: `${context.account.EmailAddress}`,
        UserId: `${context.account.UserId}`,
      },
    ];

    actions.UpdateMessage(message.Id, viewMsgRequest).then((response: any) => {
      console.log(`Message viewed by: ${context.account.EmailAddress}`);
    });
  };

  const handleCopyGroupLink = (groupId: any) => {
    let baseURL = window.location.href.replace(/list/, "group");
    let copyText = baseURL + "/" + groupId;

    copyTextToClipboard(copyText)
      .then(() => {
        setShowCopiedTooltip(true);
        setTimeout(() => {
          setShowCopiedTooltip(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReply = (id: string) => {
    let message = findNode(gridData, id);

    // sometimes you receive a message not directed to you so we have to look it up
    if (!message)
      actions.GetMessage(id).then((response: any) => {
        // delay before showing
        addDelay(
          200,
          () => {
            console.log(activeMessage);

            setShowMessage(false);
            setShowCreate(true);
          },
          () => {
            setActiveMessage(response);
          }
        );
      });

    if (message) {
      // delay before showing
      addDelay(
        200,
        () => {
          console.log(activeMessage);

          setShowMessage(false);
          setShowCreate(true);
        },
        () => {
          setActiveMessage(message);
        }
      );
    }
  };

  // handles the checkboxes
  const onCheckBoxChange = (checkName: string, isChecked: boolean) => {
    let isAllChecked = checkName === "all" && isChecked;
    let isAllUnChecked = checkName === "all" && !isChecked;
    const checked = isChecked;

    const checkList = gridData.map((row: { Id: string }, index: any) => {
      if (isAllChecked || row.Id === checkName) {
        return Object.assign({}, row, {
          checked,
        });
      } else if (isAllUnChecked) {
        return Object.assign({}, row, {
          checked: false,
        });
      }

      return row;
    });

    let _isAllSelected =
      checkList.findIndex(
        (item: { checked: boolean }) => item.checked === false
      ) === -1 || isAllChecked;

    // get the number of checked items
    let _checkCount = checkList.reduce((total: number, curr: any) => {
      return curr.checked ? total + 1 : total;
    }, 0);
    setCheckCount(_checkCount);

    setGridData(checkList);
    setIsAllSelected(_isAllSelected);
  };

  const delayShow = (message: any) => {
    // delay before showing
    addDelay(
      200,
      () => {
        setShowMessage(true);
      },
      () => {
        setActiveMessage(message);
      }
    );
  };
  return (
    <>
      <div className="topleft">
        <button
          className="standardButton boldBlack"
          onClick={() => {
            setActiveMessage(null);
            setShowCreate(true);
          }}
        >
          + New Firefly
        </button>
      </div>

      <div className="topright">
        <Link to="/import" style={{ textDecoration: "none" }}>
          <Tooltip
            trigger="mouseenter"
            title="import"
            position="top"
            theme="dark"
          >
            <i
              className="material-icons mt-1"
              style={{ verticalAlign: "middle" }}
            >
              publish
            </i>
          </Tooltip>
        </Link>

        {filter !== "Inbox" &&
          filter !== "ADO" &&
          filter !== "Sent" &&
          filter !== "Public" &&
          !allGroupFilter && (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleCopyGroupLink(filter);
                }}
              >
                <Tooltip
                  trigger="mouseenter"
                  title="copy group link"
                  position="top"
                  theme="dark"
                >
                  <i
                    className="material-icons mt-1 ml-2"
                    style={{ verticalAlign: "middle" }}
                  >
                    link
                  </i>
                </Tooltip>
              </span>
              <Tooltip
                title="copied"
                trigger="manual"
                open={showCopiedTooltip}
                position="right"
                theme="dark"
              ></Tooltip>
            </>
          )}

        {context.account && context.account.IsAdmin && (
          <Link to="/manage/groups" style={{ textDecoration: "none" }}>
            <Tooltip
              trigger="mouseenter"
              title="Manage Groups"
              position="top"
              theme="dark"
            >
              <i
                className="material-icons mt-1"
                style={{ verticalAlign: "middle" }}
              >
                group
              </i>
            </Tooltip>
          </Link>
        )}

        <Link to="/search" style={{ textDecoration: "none" }}>
          <Tooltip
            trigger="mouseenter"
            title="Search"
            position="top"
            theme="dark"
          >
            <i
              className="material-icons mt-1"
              style={{ verticalAlign: "middle" }}
            >
              search
            </i>
          </Tooltip>
        </Link>

        <SearchPanel
          searchString={searchString}
          handleSearch={handleSearch}
          filterRef={filterRef}
          setGridData={setGridData}
          isAllGroupFilter={allGroupFilter}
          filter={filter}
          handleGroupMessages={handleGroupMessages}
        />

        <article className="column-toggles-panel messageTranscriptsToggle">
          <header>
            <Form.Check
              inline
              label="Show Message Transcripts"
              type="switch"
              id="showTranscriptsSwitch"
              checked={showTranscripts}
              onChange={(event: any) => {
                setShowTranscripts(event.target.checked);
              }}
            />
          </header>
        </article>
      </div>

      <div className="sidebar">
        <div className="boldBlack navTitle">Personal</div>

        {ALL_FILTERS.map((_filter, idx) => (
          <div
            style={{ minWidth: 150 }}
            key={idx}
            onClick={() => {
              setFilter(_filter);
              setAllGroupFilter(false);
              scrollToTop();
            }}
            className={`filterLink mt-2 ${
              _filter === filter && !allGroupFilter
                ? "filterSelected boldBlack"
                : ""
            } ${idx === 0 ? "" : ""}`}
          >
            <span className="bucket">
              {_filter === "Public" ? "My Public" : _filter}{" "}
            </span>
            <img
              className={`${
                !Array.isArray(gridData)
                  ? "workingLoading"
                  : "workingLoadingDone"
              }`}
              alt="working"
              src={loadingimg}
            />
          </div>
        ))}

        {context.groups && (
          <div>
            <div className="boldBlack navTitle mt-2">Groups</div>
            {context.groups?.map((group: any) => (
              <div
                style={{ minWidth: 150 }}
                key={group.Id}
                title={group.Name}
                onClick={() => {
                  setFilter(group.Id);
                  setAllGroupFilter(false);
                  handleGroupMessages(group.Id);
                  scrollToTop();
                }}
                className={`filterLink mt-2 ${
                  group.Id === filter && !allGroupFilter
                    ? "filterSelected boldBlack"
                    : ""
                }`}
              >
                <span className="bucket">{group.Name}</span>

                <img
                  className={`${
                    loadingGroupMessages &&
                    (filter === group.Id || allGroupFilter)
                      ? "workingLoading"
                      : "workingLoadingDone"
                  }`}
                  alt="working"
                  src={loadingimg}
                />
              </div>
            ))}
            <div
              style={{ minWidth: 150 }}
              onClick={() => {
                let x: any = 0;
                setFilter(x);
                setAllGroupFilter(true);
                handleGroupMessages();
                scrollToTop();
              }}
              className={`filterLink mt-2 ${
                allGroupFilter === true ? "filterSelected boldBlack" : ""
              }`}
            >
              All
            </div>
          </div>
        )}
      </div>
      <main className="content">
        {gridData && (
          <MessageList
            messages={gridData}
            handleSort={handleSort}
            handleView={handleView}
            handleTree={handleTree}
            handleReply={handleReply}
            handleDelete={handleDelete}
            handleCheck={onCheckBoxChange}
            handleDownload={handleDownload}
            sort={sort}
            hotKey={hotKey}
            isAllSelected={isAllSelected}
            filter={filter + searchString}
            checkCount={checkCount}
            keywordSearch={searchString}
            showTranscripts={showTranscripts}
            handleTranscriptPDF={handleTranscriptPDF}
          />
        )}
      </main>
      <Modal
        show={showMessage || showPlayList}
        onHide={() => {
          setShowMessage(false);
          setShowPlayList(false);

          // refresh if it's a new message
          if (activeMessage && !findNode(gridData, activeMessage.Id)) {
            //refresh for either Personal or Group messages
            if (
              filter !== "Inbox" &&
              filter !== "ADO" &&
              filter !== "Sent" &&
              filter !== "Public"
            ) {
              //Group message
              isAllSelected
                ? handleGroupMessages()
                : handleGroupMessages(filter);
            } else {
              //Personal message
              actions.GetGridData();
            }
          }
        }}
        animation={false}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header className="img-grid" closeButton>
          <h5 className="modal-title">
            {activeMessage && showMessage ? (
              <>
                {activeMessage.Subject}
                {activeMessage.From && <> &nbsp; ({activeMessage.From})</>}
              </>
            ) : (
              <>Playing {checkCount} Fireflies</>
            )}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {activeMessage && showMessage ? (
            <Message
              message={activeMessage}
              seekTime={seekTime}
              handleReply={handleReply}
              handleViewedBy={handleViewedBy}
              keywordSearch={searchString}
            />
          ) : (
            <Playlist srcList={getPlayList()} />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreate}
        animation={false}
        onHide={() => {
          if (childRef.current) childRef.current.close();

          // wait for the close to finish before
          // destroying the object
          addDelay(50, () => {
            setShowCreate(false);
          });
        }}
        dialogClassName="new-modal custom-modal-dialog"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header className="img-grid" closeButton>
          <h5 className="modal-title">Record a Firefly</h5>
        </Modal.Header>
        <Modal.Body>
          <NewMessage
            ref={childRef}
            message={activeMessage}
            suggestions={context.uniqueEmails}
            onSave={(id: string) => {
              // wait for the close to finish before
              // destroying the object
              addDelay(200, () => {
                setShowCreate(false);

                //refresh for either Personal or Group messages
                if (
                  filter !== "Inbox" &&
                  filter !== "ADO" &&
                  filter !== "Sent" &&
                  filter !== "Public"
                ) {
                  //Group message
                  isAllSelected
                    ? handleGroupMessages()
                    : handleGroupMessages(filter);
                } else {
                  //Personal message
                  actions.GetGridData();
                }

                // set polling
                actions.SetEnablePolling(true);
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
