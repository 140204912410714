import React , { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Tooltip } from "react-tippy";

import ManagedUserListItem from "./ManagedUserListItem";
import { ManagedUserListProps, columnType } from "../types";
import CheckBox from "./CheckBox";

import "../styles/ManagedUserList.css";

export const ManagedUserList = ({
  items,
  checkCount, 
  selectedReset,
  selectedGroup, 
  isAllSelected,
  sort,
  keywordSearch,
  handleSort,
  handleCheck,
}: ManagedUserListProps) => {

  // state objects
  const [selectedIndex, setSelectedIndex] = useState(0);

  // used to select the first item when the selected group changes or we save membership
  useEffect(() => {
     if (items.length > 0) {
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReset, selectedGroup]);

  const columns: columnType[] = [
    {
      title: "Checked",
      value: "checked",
      className: "img-frame-wrapper",
      sortType: "none",
      visible: true,
    },
    {
      title: "Name",
      value: "FullName",
      className: "mgdUserName",
      sortType: "string",
      visible: true,
    },
    {
      title: "Email",
      value: "EmailAddress",
      className: "mgdUserEmail",
      sortType: "string",
      visible: true,
    },
    {
      title: "Last Login",
      value: "LastLoginDate",
      className: "mgdUserLastLogin",
      sortType: "date",
      visible: true,
    },
  ];

  const setArrow = (column: columnType) => {
    let className = "sort-direction";

    if (column && sort.column)
      if (column.value === sort.column.value) {
        className += sort.direction === "asc" ? " asc" : " desc";
      }

    return className;
  };

  return (
    <Table responsive bordered hover>
      <thead className="cdBodyContainer">
        <tr>
          <th className="mgdUserCheck"> {
            (
              <>
                <Tooltip
                  trigger="mouseenter"
                  title={isAllSelected ? "uncheck all" : " check all"}
                  position="top"
                  theme="dark"
                >
                  <CheckBox
                    name=""
                    value="all"
                    overlay={false}
                    tick={isAllSelected}
                    onCheck={(e: any) =>
                      handleCheck("all", e.target.checked)
                    }
                  />
                </Tooltip>{" "}
                {checkCount}{" "} {checkCount == 1 ? "Member" : "Members"}
              </>
            ) 
          }

          </th>
          <th className="mgdUserName sortable" onClick={() => { handleSort(columns[1]); }}>Name <span className={setArrow(columns[1])}></span></th>
          <th className="mgdUserEmail sortable"  onClick={() => { handleSort(columns[2]); }}>Email <span className={setArrow(columns[2])}></span></th>
          <th className="mgdUserLastLogin sortable"  onClick={() => { handleSort(columns[3]); }}>Last Login <span className={setArrow(columns[3])}></span></th>
          <th className="scrollColumn"></th>
        </tr>
      </thead>
      {items ? (
        <tbody className="cdBodyContainer" id="gridImport">
          {items.map((item: any, rowNum: number) => (
            <ManagedUserListItem
              key={rowNum}
              item={item}
              rowNum={rowNum}
              active={rowNum === selectedIndex}
              setSelectedIndex={setSelectedIndex}
              handleCheck={handleCheck}
            />
          ))}
        </tbody>
      ) : (
        <tbody className="cdBodyContainer">
          <tr>
            <td colSpan={6} align="center" className="boldBlack"></td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};
