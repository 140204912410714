import React, { useState, useEffect } from "react";

import { NavigationBar } from "./NavigationBar";
import "../styles/PageLayout.css";

export const PageLayout = (props) => {
  const [popup, setPopup] = useState(false);

  //check if we need to hide nav
  useEffect(() => {
    setPopup(
      window.location.href.includes("?popup=true") ||
        window.location.href.includes("/group/")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  console.log(props);

  return (
    <>
      <div className={`${!popup ? "app" : ""}`}>
        {!popup && (
          <div className="header topNav">
            <NavigationBar />
          </div>
        )}
        {props.children}
      </div>
    </>
  );
};
