import React, { useRef, useEffect } from "react";

import { Button } from "react-bootstrap";

type LocalFileProps = {
  videoNode: React.RefObject<HTMLVideoElement>;
  selectFile(blob: Blob, is4K: boolean): void;
};

export const LocalFile = ({ videoNode, selectFile }: LocalFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  // handle component state changes
  useEffect(() => {
    var playSelectedFile = async function (this: any, event: any) {
      if (this.files[0] !== undefined && videoNode.current) {
        var file = this.files[0];
        var URL = window.URL || window.webkitURL;
        var fileURL = URL.createObjectURL(file);
        videoNode.current.src = fileURL;
        let isFile4K = await is4KResolution(file);

        selectFile(file, isFile4K);
      }
    };
    var inputNode = document.querySelector("#local-file");
    inputNode!.addEventListener("change", playSelectedFile, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoNode]);

  const onFileChange = (e: any) => {
    console.log("onChange");
  };

  const onBtnClick = () => {
    inputFileRef.current!.click();
  };

  const is4KResolution = async (file: File): Promise<boolean> => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    return new Promise<boolean>((resolve) => {
      video.addEventListener("loadedmetadata", () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve(width >= 3840 && height >= 2160);
      });
    });
  };

  return (
    <div>
      <input
        type="file"
        ref={inputFileRef}
        onChange={onFileChange}
        id="local-file"
        style={{ display: "none" }}
      />
      <Button variant="primary" className="ml-1 mr-1 mt-1" onClick={onBtnClick}>
        Select Local File...
      </Button>
    </div>
  );
};
export default LocalFile;
