import * as React from "react";
// for now we are not supporting playlist functionality - MKPlayer
//import loader from "./loader";
import * as MKPlayerSDK from "@mediakind/mkplayer";
import "@mediakind/mkplayer/mkplayer-ui.css";
import { RefObject } from "react";

declare const window: any;
export interface IVideoPlayerProps {
  readonly src: { src: string; type: string }[];
  readonly track: {
    src: string;
    srclang: string;
    label: string;
    kind: string;
  }[];
  enablePlaybackSpeed: boolean;
  autoLoop: boolean;
  seekTime: number;
  readonly srcList?: any[];

  onEvent?: (eventType: string) => void;
  onPlayer?: (_player: any) => void;
  displayVTT?: (t: any) => void;
}

const playerConfig = {
  key: "d0167b1c-9767-4287-9ddc-e0fa09d31e02",
  playback: {
    muted: false,
    autoplay: true,
  },
  ui: true
};

export default class VideoPlayer extends React.Component<IVideoPlayerProps> {
  videoNode: RefObject<any>;
  player: any;


  constructor(props: IVideoPlayerProps) {
    super(props);
    this.videoNode = React.createRef();

    window.playerComponent = this;
    window.displayVTT = props.displayVTT;
  }


  componentWillUnmount() {
    if (this.player) {
      this.player.unload();
    }
  }

  componentDidMount() {
    //this.initialization = loader().then(() => {

    this._createPlayer();
    this._setVideo();
    //});
  }

  _setVideo() {
    const { src, track, srcList } = this.props;
    //if (srcList) this.player.playlist(srcList);
    //else this.player.load(src);

    //do some remapping of endpoints until we migrate records in the DB
    // const oldEndpoint = "https://fireflytestams-uswe.streaming.media.azure.net";
    // const newEndpoint =
    //   "https://ep-mktestendpoint-murphymkio-dev.westus2.streaming.mediakind.com";

    // let hlsSrc = src[0].src.replace(oldEndpoint, newEndpoint);
    // if (!hlsSrc.includes("(format=m3u8-cmaf)")) {
    //   hlsSrc = hlsSrc.replace(
    //     ".ism/manifest",
    //     ".ism/manifest(format=m3u8-cmaf)"
    //   );
    // }

    // let dashSrc = src[0].src.replace(oldEndpoint, newEndpoint);
    // //remove any existing HLS format
    // dashSrc = dashSrc.replace("(format=m3u8-cmaf)", "");

    // if (!dashSrc.includes("(format=mpd-time-cmaf)")) {
    //   dashSrc = dashSrc.replace(
    //     ".ism/manifest",
    //     ".ism/manifest(format=mpd-time-cmaf)"
    //   );
    // }

    let hlsSrc = src[0].src;
    let dashSrc = hlsSrc.replace(
      "(format=m3u8-cmaf)",
      "(format=mpd-time-cmaf)"
    );

    const sourceConfig = {
      hls: hlsSrc,
      dash: dashSrc,
    };

    this.player.load(sourceConfig);
  }

  _createPlayer() {
    const { onEvent, onPlayer, autoLoop, seekTime } =
      this.props;

    const { src, track, srcList } = this.props;

    let cues: any = [];

    if (this.videoNode.current) {
      this.player = new MKPlayerSDK.MKPlayer(
        this.videoNode.current,
        playerConfig
      );

      this.player.on(MKPlayerSDK.MKPlayerEvent.SourceLoaded, (event: any) => {

        //add any available subtitles track
        this.player.subtitles.add(track[0]);
  
        //start playback
        if (!playerConfig.playback.autoplay) {
          this.player.play();
        }

      });

      this.player.on(MKPlayerSDK.MKPlayerEvent.PlaybackFinished, (event: any) => {
        if (autoLoop) {
          window.playerComponent.player.seek(0);
          window.playerComponent.player.play();
        }
      });

    
      this.player.on(MKPlayerSDK.MKPlayerEvent.SubtitleAdded, (event: any) => {
        window.playerComponent.player.subtitles.enable("sub1");
      });

      this.player.on(MKPlayerSDK.MKPlayerEvent.Ready, (event: any) => {
        //Turn Off subtitles by default
        window.playerComponent.player.subtitles.disable("sub1");
        if (onEvent) onEvent("ready");
      });

      this.player.on(MKPlayerSDK.MKPlayerEvent.CueParsed, (event: any) => {
        //build an object to hold the cue start and end times and text        

        let cue = {
          startTime: event.start,
          endTime: event.end,
          text: event.text,
        };

        //add the cue to the array
        cues.push(cue);

        window.displayVTT(cues);
      });

    }
    this.player.on(
      [
        "next",
        "prev",
        "stop",
        "lastVideoEnded",
        "pause",
        "playing",
        "ready",
        "start",
        "seek",
        "ended",
      ],
      (e: any) => {
        console.log(e);

        if (onEvent) onEvent(e.type);
      }
    );

    if (onPlayer) onPlayer(this.player);
  }

  render(): JSX.Element {
    return (
      <div>
        <div
          ref={this.videoNode}
          className="video-container bitmovinplayer-container"
          id="video-container"
        />
      </div>
    );
  }
}


