/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import OpenAILogo from "../assets/openai.png";
import AudioFFPlaceholder from "../assets/placeholder-audio.png";

import {
  CAMERA_STATUS,
  CAPTURE_TYPE,
  DeviceTypes,
  AppSettings,
} from "./react-record-webcam/src";

import { FireFlyProps, ALL_HOTKEYS } from "../types";

import "../styles/App.css";

import {
  ButtonGroup,
  ToggleButton,
  Button,
  ProgressBar,
} from "react-bootstrap";

import { isSafari } from "../utils";
import { StatusBar } from "./StatusBar";
import { SettingsDialog } from "./SettingsDialog";
import { LocalFile } from "./LocalFile";
import loadingimg from "../assets/loading_spinner.png";

import blank from "../assets/countdown_blank.png";

export const FireFly = ({
  recordWebcam,
  progress,
  uploading,
  hotKey,
  getLocalFile,
  setAISetting,
  aiActive,
}: FireFlyProps) => {
  const radios = [
    { name: "Video", value: CAPTURE_TYPE.CAMERA },
    { name: "Screen", value: CAPTURE_TYPE.SCREEN },
    { name: "Import", value: CAPTURE_TYPE.IMPORT },
    { name: "Audio", value: CAPTURE_TYPE.AUDIO },
  ];

  const [maxSeconds, setMaxSeconds] = useState<number>(60);
  const [countdown, setCountdown] = useState<number>(0);
  const [devices, setDevices] = useState<DeviceTypes>();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [isCameraMicAllowed, setIsCameraMicAllowed] = useState<boolean>(false);
  const [showSettingsFirstTimeUse, setShowSettingsFirstTimeUse] =
    useState<boolean>(false);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  const [useAI, setUseAI] = useState<boolean>(false);

  useEffect(() => {
    async function requestPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setIsCameraMicAllowed(true);
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream after checking the permissions
      } catch (error) {
        console.error("Error requesting media permissions:", error);
      }
    }

    requestPermissions();
    setIsSafariBrowser(isSafari());

    if (localStorage.getItem("ffAudioSource") != null) {
      let defaultLength = localStorage.getItem(
        "ffDefaultLength"
      ) as unknown as number;
      let defaultAudioSource = localStorage.getItem(
        "ffAudioSource"
      ) as unknown as string;
      let defaultVideoSource = localStorage.getItem(
        "ffVideoSource"
      ) as unknown as string;

      let defaultUseAI = JSON.parse(localStorage.getItem("ffUseAI") as string);

      if (defaultUseAI === null) {
        defaultUseAI = true;
        localStorage.setItem("ffUseAI", defaultUseAI.toString());
      }

      setUseAI(defaultUseAI);

      setMaxSeconds(defaultLength);

      recordWebcam.changeSources({
        audioSource: defaultAudioSource,
        videoSource: defaultVideoSource,
      });
    } else {
      if (isCameraMicAllowed) {
        setShowSettingsFirstTimeUse(true);
        setShowSettings(true);
      }
    }
  }, [isCameraMicAllowed]);

  // handle component state changes
  useEffect(() => {
    if (devices?.audio === undefined)
      recordWebcam.getDevices().then((_devices) => {
        setDevices(_devices);
      });

    if (
      localStorage.getItem("ffAudioSource") != null &&
      localStorage.getItem("ffVideoSource") != null
    ) {
      recordWebcam.changeSources({
        audioSource: localStorage.getItem("ffAudioSource") as string,
        videoSource: localStorage.getItem("ffVideoSource") as string,
      });
      setMaxSeconds(
        localStorage.getItem("ffDefaultLength") as unknown as number
      );
      setUseAI(JSON.parse(localStorage.getItem("ffUseAI") as string));
    }

    if (
      recordWebcam.status === CAMERA_STATUS.OPEN ||
      recordWebcam.status === CAMERA_STATUS.AUDIO ||
      recordWebcam.status === CAMERA_STATUS.PREVIEW
    )
      recordWebcam.close();
    if (recordWebcam.captureType !== CAPTURE_TYPE.IMPORT) {
      if (
        recordWebcam.videoSource !== undefined &&
        recordWebcam.audioSource !== undefined
      ) {
        recordWebcam.open();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recordWebcam.captureType,
    recordWebcam.audioSource,
    recordWebcam.videoSource,
  ]);

  useEffect(() => {
    if (progress === 100) {
      recordWebcam.close();
      var counter = setInterval(() => {
        clearInterval(counter);
        // if (recordWebcam.captureType === CAPTURE_TYPE.CAMERA)
        //   recordWebcam.retake();
        // else
        //   recordWebcam.changeCaptureType({
        //     target: { value: CAPTURE_TYPE.CAMERA },
        //   });
      }, 3000);
    }
  }, [progress]);

  // this is used to handle the case when the user hits 'stops sharing' from the browser UI
  useEffect(() => {
    if (recordWebcam.streamState === "ended track") {
      if (recordWebcam.status === CAMERA_STATUS.OPEN) {
        recordWebcam.changeCaptureType({
          target: { value: CAPTURE_TYPE.CAMERA },
        });
      }

      if (recordWebcam.status === CAMERA_STATUS.RECORDING) {
        recordWebcam.stop();
        recordWebcam.close();
      }
    }
  }, [recordWebcam.status, recordWebcam.streamState]);

  //handle change in AI setting
  useEffect(() => {
    if (useAI != null) setAISetting(useAI);
  }, [useAI]);

  // process hot keys
  useEffect(() => {
    switch (hotKey) {
      case ALL_HOTKEYS.Screen:
      case ALL_HOTKEYS.Camera:
      case ALL_HOTKEYS.Local:
      case ALL_HOTKEYS.Audio:
        if (recordWebcam.status !== CAMERA_STATUS.RECORDING)
          if (hotKey === ALL_HOTKEYS.Screen && isSafariBrowser) {
            //not supported
          } else {
            recordWebcam.changeCaptureType({
              target: { value: hotKey.toUpperCase() },
            });
          }

        break;
      case ALL_HOTKEYS.Record:
        if (
          recordWebcam.status !== CAMERA_STATUS.RECORDING &&
          recordWebcam.status !== CAMERA_STATUS.PREVIEW &&
          recordWebcam.status !== CAMERA_STATUS.LOCAL
        )
          handleStartRecording();

        break;
      case ALL_HOTKEYS.Retake:
        if (
          recordWebcam.status === CAMERA_STATUS.PREVIEW &&
          recordWebcam.captureType !== CAPTURE_TYPE.IMPORT
        )
          recordWebcam.retake();
        break;
      case ALL_HOTKEYS.Stop:
        if (recordWebcam.status === CAMERA_STATUS.RECORDING)
          recordWebcam.stop();
        break;
      case ALL_HOTKEYS.Pause:
        if (recordWebcam.status === CAMERA_STATUS.RECORDING)
          recordWebcam.pause();
        break;
      case ALL_HOTKEYS.Resume:
        if (recordWebcam.status === CAMERA_STATUS.PAUSED) recordWebcam.resume();
        break;
      default:
        return;
    }
  }, [hotKey]);

  const handleStartRecording = () => {
    var max = 2;
    var count = max + 1;
    var counter = setInterval(timer, 1000);
    setCountdown(3);

    function timer() {
      count = count - 1;
      if (count <= 0) {
        clearInterval(counter);
        recordWebcam.start();
      }
      setCountdown(count);
    }

    // record for a set duration
    recordWebcam.setDuration(maxSeconds * 1000, function (): void {
      recordWebcam.stop();
    });
  };

  const handleSaveSettings = (settings: AppSettings) => {
    localStorage.setItem(
      "ffDefaultLength",
      settings.durationSeconds.toString()
    );
    localStorage.setItem(
      "ffAudioSource",
      settings.sources?.audioSource
        ? settings.sources.audioSource
        : settings.defaultAudioSourceId
    );
    localStorage.setItem(
      "ffVideoSource",
      settings.sources?.videoSource
        ? settings.sources.videoSource
        : settings.defaultVideoSourceId
    );

    localStorage.setItem("ffUseAI", settings.useAI.toString());

    setUseAI(settings.useAI);
    setMaxSeconds(settings.durationSeconds);

    recordWebcam.changeSources({
      audioSource: settings.sources?.audioSource
        ? settings.sources.audioSource
        : settings.defaultAudioSourceId,
      videoSource: settings.sources?.videoSource
        ? settings.sources.videoSource
        : settings.defaultVideoSourceId,
    });
  };

  const handleSelectFile = (blob: Blob, is4K: boolean) => {
    // callback

    getLocalFile(blob, is4K);
  };

  return (
    <div className="col-sm">
      <div>
        <StatusBar status={recordWebcam.status} />
        {aiActive && (
          <div className="ai-indicator" data-label="AI" dir="ltr">
            <img
              src={OpenAILogo}
              alt="OpenAI Enabled"
              title="OpenAI Enabled"
              className="aiLogo"
            />
          </div>
        )}
        <div
          className="video-placeholder-frame"
          style={{
            display: `${
              recordWebcam.status === CAMERA_STATUS.CLOSED ||
              recordWebcam.status === CAMERA_STATUS.ERROR ||
              recordWebcam.status === CAMERA_STATUS.INIT
                ? "block"
                : "none"
            }`,
          }}
        >
          {!isCameraMicAllowed &&
            recordWebcam.status === CAMERA_STATUS.CLOSED && (
              <div className="camera-error text-danger text-center">
                Please grant permission to use the camera and microphone in your
                browser and reload the site.
              </div>
            )}

          {isCameraMicAllowed && recordWebcam.cameraError && (
            <div className="camera-error text-danger text-center">
              {recordWebcam.cameraError.toString()}
            </div>
          )}

          <div className="video-placeholder">
            <img
              className={`center-me ${
                recordWebcam.status === CAMERA_STATUS.INIT ? "working" : ""
              }`}
              alt="FireFly"
              style={{
                display: `${
                  recordWebcam.status === CAMERA_STATUS.CLOSED
                    ? "none"
                    : "block"
                }`,
              }}
              src={loadingimg}
            />
          </div>
        </div>
        <div className="shadow video-player">
          {recordWebcam.status === CAMERA_STATUS.RECORDING && (
            <div className="record-indicator" data-label="REC" dir="ltr"></div>
          )}

          <video
            ref={recordWebcam.webcamRef}
            className="video-player"
            style={{
              display: `${
                recordWebcam.status === CAMERA_STATUS.OPEN ||
                recordWebcam.status === CAMERA_STATUS.RECORDING ||
                recordWebcam.status === CAMERA_STATUS.PAUSED
                  ? "block"
                  : "none"
              }`,
            }}
            playsInline
            autoPlay
            muted
          />
          <video
            ref={recordWebcam.previewRef}
            className="video-player"
            style={{
              display: `${
                recordWebcam.status === CAMERA_STATUS.PREVIEW ||
                recordWebcam.status === CAMERA_STATUS.LOCAL ||
                recordWebcam.status === CAMERA_STATUS.AUDIO
                  ? "block"
                  : "none"
              }`,
            }}
            playsInline
            autoPlay
            controls
          />

          {recordWebcam.captureType === CAPTURE_TYPE.AUDIO && (
            <img
              className={`overlay`}
              src={AudioFFPlaceholder}
              alt="Audio-Only Firefly"
              style={{ zIndex: 99, height: "50%", top: "30%" }}
            />
          )}

          {countdown > 0 && (
            // <div className={`overlay countdown-${countdown}`}>{countdown}</div>
            <img
              className={`overlay countdown-${countdown}`}
              src={blank}
              alt="1"
            />
          )}

          {uploading && progress < 100 && (
            <div className="center-me uploadProgress">
              uploading...
              <ProgressBar animated now={progress} />
            </div>
          )}

          {progress === 100 && uploading && (
            <div className="center-me uploadProgress">upload complete!</div>
          )}
        </div>

        <StatusBar status={recordWebcam.status} />
      </div>

      <div className="row mt-2">
        {recordWebcam.status !== CAMERA_STATUS.RECORDING &&
          recordWebcam.status !== CAMERA_STATUS.PAUSED &&
          recordWebcam.status !== CAMERA_STATUS.PREVIEW &&
          recordWebcam.status !== CAMERA_STATUS.LOCAL && (
            <>
              <ButtonGroup toggle>
                {radios.map((radio, idx) =>
                  radio.value === CAPTURE_TYPE.SCREEN && isSafariBrowser ? (
                    <></>
                  ) : (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="secondary"
                      name="radio"
                      value={radio.value}
                      checked={recordWebcam.captureType === radio.value}
                      onChange={recordWebcam.changeCaptureType}
                      className={`mt-1 ${idx === 0 ? "" : "ml-1"}`}
                    >
                      {radio.name}
                    </ToggleButton>
                  )
                )}
              </ButtonGroup>

              <Button
                className="ml-1 mt-1"
                variant="secondary"
                onClick={() => {
                  setShowSettings(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-gear"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </svg>
              </Button>
            </>
          )}

        {recordWebcam.captureType === CAPTURE_TYPE.IMPORT && (
          <LocalFile
            videoNode={recordWebcam.previewRef}
            selectFile={handleSelectFile}
          />
        )}
        {recordWebcam.status !== CAMERA_STATUS.RECORDING &&
          recordWebcam.status !== CAMERA_STATUS.PAUSED &&
          recordWebcam.status !== CAMERA_STATUS.PREVIEW &&
          recordWebcam.status !== CAMERA_STATUS.LOCAL && (
            <Button
              variant="primary"
              className="ml-1 mr-1 mt-1"
              title="Record"
              onClick={() => handleStartRecording()}
            >
              Record
            </Button>
          )}
        {(recordWebcam.status === CAMERA_STATUS.RECORDING ||
          recordWebcam.status === CAMERA_STATUS.PAUSED) && (
          <Button
            variant="danger"
            className="ml-1 mr-1 mt-1"
            title="Stop"
            disabled={
              recordWebcam.status !== CAMERA_STATUS.RECORDING &&
              recordWebcam.status !== CAMERA_STATUS.PAUSED
            }
            onClick={recordWebcam.stop}
          >
            Stop recording
          </Button>
        )}

        {recordWebcam.status === CAMERA_STATUS.RECORDING && (
          <Button
            variant="danger"
            className="ml-1 mr-1 mt-1"
            title="Pause"
            disabled={recordWebcam.status !== CAMERA_STATUS.RECORDING}
            onClick={recordWebcam.pause}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pause-fill"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
            </svg>
          </Button>
        )}
        {recordWebcam.status === CAMERA_STATUS.PAUSED && (
          <Button
            variant="danger"
            className="ml-1 mr-1 mt-1"
            title="Resume"
            disabled={recordWebcam.status !== CAMERA_STATUS.PAUSED}
            onClick={recordWebcam.resume}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              className="bi bi-record-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            </svg>
          </Button>
        )}

        {recordWebcam.status === CAMERA_STATUS.PREVIEW &&
          recordWebcam.captureType !== CAPTURE_TYPE.IMPORT && (
            <>
              <Button
                variant="danger"
                className="ml-1 mr-1 mt-1"
                title="Re-Record"
                disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                onClick={() => {
                  recordWebcam.retake();
                }}
              >
                Re-Record
              </Button>
            </>
          )}
        {recordWebcam.status === CAMERA_STATUS.RECORDING && (
          <span
            className="alignRight mt-3"
            style={{ color: "red", textAlign: "center" }}
          >
            Recording...
          </span>
        )}
        {recordWebcam.status === CAMERA_STATUS.PAUSED && (
          <span
            className="alignRight mt-3"
            style={{ color: "red", textAlign: "center" }}
          >
            Paused
          </span>
        )}
        {(recordWebcam.status === CAMERA_STATUS.RECORDING ||
          recordWebcam.status === CAMERA_STATUS.PAUSED) && (
          <span
            className="alignRight mr-1 mt-3"
            style={{ fontSize: "smaller" }}
          >
            {maxSeconds - recordWebcam.secondsRecorded} secs remain
          </span>
        )}
      </div>

      <SettingsDialog
        show={showSettings}
        cancel={() => {
          setShowSettings(false);
        }}
        recordWebcam={recordWebcam}
        save={handleSaveSettings}
        showFirstTimeMessage={showSettingsFirstTimeUse}
      />
    </div>
  );
};

export default FireFly;
