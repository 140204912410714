import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Redirect } from "react-router-dom";

// this page is simply used to extract the id from the url
export const Home = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Redirect to="/list" />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h3 className="text-center"> Welcome to Firefly</h3>
      </UnauthenticatedTemplate>
    </>
  );
};
