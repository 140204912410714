import { useEffect, useRef } from "react";

export function Poll(callback, delay) {
  const savedCallback = useRef;
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== 0) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay]);
}
