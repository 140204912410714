// had to implement this outside of typescript because it doesn't support Set fully
export const uniqueEmails = (rows) => {
  let _emails = [];

  // walk the object getting emails
  rows.forEach((row) => {
    if (row.From) _emails.push(row.From.toLowerCase());

    row.Targets.forEach((target) => {
      if (target.Type === "email")
        _emails.push(target.Name.toLowerCase().trim());
    });
  });

  // spread and convert to a set to make unique
  let _unique = [...new Set(_emails)];

  return _unique;
};

export const addUniqueEmail = (emails, newEmail) => {
  emails.push(newEmail.toLowerCase().trim());
  return [...new Set(emails)];
};
