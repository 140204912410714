import React, { useRef } from "react";
import { ImportFileListItemProps } from "../types";

import { Tooltip } from "react-tippy";
import { ProgressBar } from "react-bootstrap";
import prettyBytes from "pretty-bytes";
import ActionBar from "../components/ActionBar";

const ImportFileListItem = ({
  item,
  handleDelete,
  handleSubject,
}: ImportFileListItemProps) => {
  const handleFocus = (event: any) => event.target.select();
  const toolRef = useRef<any>();
  const displayDate = (_date: Date) => {
    let _dateLines = _date.toLocaleString().split(",");

    return _dateLines.length ? (
      <>
        {_dateLines[0]} <br /> {_dateLines[1]}
      </>
    ) : null;
  };

  return (
    <>
      <tr>
        <td className="impFileName">
          <Tooltip
            trigger="mouseenter"
            html={<div>{item.file.name}</div>}
            arrow={true}
            position="bottom"
            theme="light"
          >
            <span>{item.file.name}</span>
          </Tooltip>
        </td>
        <td className="impFileSize">
          <span>{prettyBytes(item.file.size)}</span>
        </td>
        <td className="impFileDate">
          <span>{displayDate(item.file.lastModifiedDate)}</span>
        </td>
        <td className="impFileSubject">
          <input
            type="text"
            placeholder="Subject"
            required
            value={item.subject}
            disabled={item.status !== "pending" && item.status !== "error"}
            onFocus={handleFocus}
            onChange={(e) => handleSubject(e.target.value, item.id)}
          />
        </td>
        <td className="impFileStatus">
          <div>
            {item.status === "uploading" && item.progress < 100 && (
              <div className="importUploadProgress">
                <ProgressBar animated now={item.progress} />
              </div>
            )}
            {item.status === "uploaded" && (
              <Tooltip
                trigger="mouseenter"
                html={<div>{item.status}</div>}
                arrow={true}
                position="bottom"
                theme="light"
              >
                <span>
                  <i className="material-icons">radio_button_checked</i>
                </span>
              </Tooltip>
            )}

            {item.status === "pending" && (
              <Tooltip
                trigger="mouseenter"
                html={<div>{item.status}</div>}
                arrow={true}
                position="bottom"
                theme="light"
              >
                <span>
                  <i className="material-icons">radio_button_unchecked</i>
                </span>
              </Tooltip>
            )}

            {item.status === "done" && (
              <Tooltip
                trigger="mouseenter"
                html={<div>{item.status}</div>}
                arrow={true}
                position="bottom"
                theme="light"
              >
                <span>
                  <i className="material-icons">check_circle</i>
                </span>
              </Tooltip>
            )}

            {item.status === "error" && (
              <>
                <Tooltip
                  trigger="mouseenter"
                  html={<div>Error: {item.error}</div>}
                  arrow={true}
                  position="top"
                  theme="light"
                >
                  <ActionBar
                    ref={toolRef}
                    position="left"
                    theme="light"
                    title="this is a tool tip"
                    disabled={item.status !== "error"}
                    interactive
                    html={
                      <div
                        className="actionBar"
                        onMouseLeave={() => {
                          if (toolRef.current) toolRef.current.hover(false);
                        }}
                        onMouseEnter={() => {
                          if (toolRef.current) toolRef.current.hover(true);
                        }}
                      >
                        <strong
                          className="navLink"
                          onClick={() => {
                            if (handleDelete) {
                              handleDelete(item.id);
                              if (toolRef.current) toolRef.current.close();
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Remove
                        </strong>
                      </div>
                    }
                  >
                    <span>
                      <i className="material-icons">error_outline</i>
                    </span>
                  </ActionBar>
                </Tooltip>
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default ImportFileListItem;
