import { useState, forwardRef, useImperativeHandle } from "react";
import { ActionBarProps } from "../types";

import { addDelay } from "../utils";

import { Tooltip } from "react-tippy";

const ActionBar = forwardRef((props: ActionBarProps, ref: any) => {
  const [open, setOpen] = useState<boolean>(false);

  let over: boolean = false;

  const setOver = (_over: boolean) => {
    over = _over;
  };

  const delayClose = () => {
    addDelay(
      300,
      () => {
        if (!over) setOpen(false);
      },
      () => {
        setOver(false);
      }
    );
  };
  // enable parent to call child function
  useImperativeHandle(ref, () => ({
    close() {
      if (props.disabled) return;
      setOver(false);
      setOpen(false);
    },
    hover(hover: boolean) {
      if (props.disabled) return;
      setOver(hover);

      if (!hover) {
        delayClose();
      }
    },
  }));
  return (
    <div
      onClick={() => {
        if (props.disabled) return;
        setOpen(true);
        setOver(true);
      }}
      onMouseEnter={() => {
        if (props.disabled) return;
        setOpen(true);
        setOver(true);
      }}
      onMouseLeave={() => {
        if (props.disabled) return;
        delayClose();
      }}
    >
      <Tooltip open={open} {...props}>
        {props.children}
      </Tooltip>
    </div>
  );
});

export default ActionBar;
