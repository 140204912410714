import React, { createContext, useReducer } from "react";
import { State, ProviderProps, Action } from "./types";
import reducers from "./reducers";

interface IContextProps {
  state: State;
  dispatch: (action: Action) => void;
}

const store = createContext({} as IContextProps);
//const localState = JSON.parse(localStorage.getItem("StateProvider") || "{}");

const { Provider } = store;

const StateProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducers, {
    isLoggedIn: false,
    isSetup: false,
    enablePolling: false,
    desktop: true,
    uniqueEmails: [],
    activeMessageId: "",
    activeMessageIndex: 0,
    projects: undefined,
    account: {},
    messages: {},
    teams: undefined,
    groups: undefined,
    groupMessages: {},
    managedUsers: {},
    managedGroups: {},
  });

  // useEffect(() => {
  //   localStorage.setItem("StateProvider", JSON.stringify(state));
  // }, [state]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
