/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col, InputGroup, FormGroup } from "react-bootstrap";

import { ManagedGroup } from "../types";

interface SettingsProps {
  show: boolean;
  cancel(): void;
  save(group: any): void;
}

export const CreateGroupDialog = ({
  show,
  cancel,
  save,
}: SettingsProps) => {
  const [groupName, setGroupName] = useState("");
  const [groupActive, setGroupActive] = useState(true);
  const [validated, setValidated] = useState(false);

  // handle component state changes
  useEffect(() => {

    // Set the defaults on show
    setGroupName("");
    setGroupActive(true);
    setValidated(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
 
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const group: ManagedGroup = { id: "", type: "group", name: groupName, isActive: groupActive };
      save(group);
      cancel();
    }

    setValidated(true);
  };

  return (
    <Modal
      show={show}
      animation={false}
      onHide={cancel}
      dialogClassName="new-modal"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="lg"
      centered>
      <Modal.Header>Create Group</Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} xs={7} name="groupName">
              <Form.Label>Group Name</Form.Label>
              <Form.Control 
                id="groupNameText"
                className="message-box"
                type="text"
                aria-label="Group Name"
                aria-colspan={3}
                required
                maxLength={50}
                onChange={(event: any) => {
                  setGroupName(event.target.value);
                }}>
              </Form.Control>
              <Form.Control.Feedback type="invalid">Group name is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
            <Form.Group as={Col}  xs={3} name="groupActive">
                
              <Form.Check
                aria-colspan={1}
                id="groupActiveCheck"
                type="checkbox"
                label="Group Active"
                aria-label="Group Active"
                checked={groupActive}
                onChange={(event: any) => {
                  setGroupActive(event.target.checked);
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Button className="ml-2" variant="primary" type="submit">
                Save Changes
              </Button>
              <Button className="ml-2 mr-3" variant="secondary" onClick={cancel}>
                Cancel
              </Button>
          </Form.Row>
        </Form>
      </Modal.Body>
     
  </Modal>
  );
};

export default CreateGroupDialog;
