import { protectedResources } from "../authConfig";
import { ManagedGroup } from "../types";

const callApiWithToken = async (
  accessToken: string,
  method: string,
  apiEndpoint: RequestInfo,
  bodyData?: object
) => {
  const headers: HeadersInit = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  let options: RequestInit = {
    method,
    headers,
    credentials: "include",
  };

  if (method !== "GET") {
    options = { ...options, body: JSON.stringify(bodyData) };
  }

  const handleErrors = (response: any) => {
    if (!response.ok) {
      return Promise.reject(response.statusText);
    }
    return response;
  };

  return fetch(apiEndpoint, options)
    .then(handleErrors)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const useAPI = () => {
  const Login = async (token: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiLogin.method,
      protectedResources.apiLogin.endpoint
    );
  };

  const Setup = async (token: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiSetup.method,
      protectedResources.apiSetup.endpoint
    );
  };

  const GetPublicMessage = async (id: string) => {
    return fetch(protectedResources.apiMessages.endpoint + "/aa-" + id).then(
      (response) => {
        return response.json();
      }
    );
  };

  const GetMessages = async (token: string, id?: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessages.method,
      protectedResources.apiMessages.endpoint + `${id ? "/" + id : ""}`
    );
  };

  const CreateMessage = async (token: string, bodyData: object) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessageCreate.method,
      protectedResources.apiMessageCreate.endpoint,
      bodyData
    );
  };

  const UpdateMessage = async (token: string, id: string, bodyData: object) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessageUpdate.method,
      protectedResources.apiMessageUpdate.endpoint + `/${id}`,
      bodyData
    );
  };

  const DeleteMessage = async (token: string, id: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessageDelete.method,
      protectedResources.apiMessageDelete.endpoint + `/${id}`
    );
  };

  const DownloadMessage = async (token: string, id: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessageDownload.method,
      protectedResources.apiMessageDownload.endpoint + `/${id}`
    );
  };

  const GetTranscriptUrl = async (token: string, id: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiTranscriptUrl.method,
      protectedResources.apiTranscriptUrl.endpoint + `/${id}`
    );
  };

  const GetMessagesByGroup = async (token: string, id: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessagesGroup.method,
      protectedResources.apiMessagesGroup.endpoint + `/${id}`
    );
  };

  const GetGroupMessagesForUser = async (token: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiMessagesGroup.method,
      protectedResources.apiMessagesGroup.endpoint
    );
  };

  const GetManagedUsers = async (token: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiManagedUsers.method,
      protectedResources.apiManagedUsers.endpoint
    );
  };

  const GetManagedGroups = async (token: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiManagedGroups.method,
      protectedResources.apiManagedGroups.endpoint
    );
  };

  const CreateGroup = async (token: string, group: ManagedGroup) => {
    return callApiWithToken(
      token,
      protectedResources.apiGroupCreate.method,
      protectedResources.apiGroupCreate.endpoint,
      group
    );
  };

  const DeleteGroup = async (token: string, id: string) => {
    return callApiWithToken(
      token,
      protectedResources.apiGroupDelete.method,
      protectedResources.apiGroupDelete.endpoint + `/${id}`
    );
  };

  const UpdateGroupMembership = async (
    token: string,
    id: string,
    members: object
  ) => {
    return callApiWithToken(
      token,
      protectedResources.apiGroupMembershipUpdate.method,
      protectedResources.apiGroupMembershipUpdate.endpoint + `/${id}`,
      members
    );
  };

  return {
    Login,
    Setup,
    GetMessages,
    GetPublicMessage,
    CreateMessage,
    UpdateMessage,
    DeleteMessage,
    DownloadMessage,
    GetMessagesByGroup,
    GetGroupMessagesForUser,
    GetManagedUsers,
    GetManagedGroups,
    CreateGroup,
    DeleteGroup,
    UpdateGroupMembership,
    GetTranscriptUrl,
  };
};
