import React, { useState, useEffect } from "react";
import { InfoPanelProps } from "../types";
import Highlighter from "react-highlight-words";

const InfoPanel = ({
  title,
  info,
  vttTranscript,
  player,
  keywordSearch,
}: InfoPanelProps) => {
  const [expanded, setExpanded] = useState(false);

  const [keywords, setKeywords] = useState<(string | RegExp)[]>([]);
  useEffect(() => {
    if (keywordSearch)
      setKeywords(keywordSearch.split(/\s/).filter((word: any) => word));
  }, [keywordSearch]);

  return (
    <article className="info-panel">
      <header>
        <div
          onClick={() => setExpanded(!expanded)}
          className="info-title alignLeft"
        >
          {title}
        </div>
        <button
          className={`info-btn ${expanded ? "ip-up" : "ip-down"}`}
          onClick={() => setExpanded(!expanded)}
        ></button>
      </header>
      {expanded && (
        <div className="transcript">
          {vttTranscript && vttTranscript.length > 0 ? (
            vttTranscript.map((vt: any, rowNum: number) => {
              return (
                <span
                  className="cues"
                  id={vt.id}
                  key={rowNum}
                  onClick={() => {
                    if (player) {
                      player.seek(vt.startTime);
                    }
                  }}
                >
                  <Highlighter
                    highlightClassName="highlight-keywords"
                    searchWords={keywords}
                    autoEscape={true}
                    textToHighlight={vt.text}
                    caseSensitive={false}
                    highlightStyle={{
                      fontWeight: "normal",
                      cursor: "pointer",
                    }}
                  />{" "}
                  &nbsp;
                </span>
              );
            })
          ) : (
            <p>{info}</p>
          )}
        </div>
      )}
    </article>
  );
};

export default InfoPanel;
