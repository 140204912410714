import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Col } from "react-bootstrap";
import { Group } from "../types";
import { store } from "../context/store";
import { useActions } from "../context/actions";
import { Message } from "../components/Message";
import { Modal } from "react-bootstrap";
import lunr from "lunr";
import "../styles/Search.css";

export const Search = () => {
  const { state } = useContext(store);
  const actions = useActions();
  const context = state;

  const [groups, setGroups] = useState<Group[] | undefined>(context.groups);
  const [noGroup, setNoGroup] = useState(false);
  const [noSearchPhrase, setNoSearchPhrase] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [gridData, setGridData] = useState<any | null>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [activeMessage, setActiveMessage] = useState<any | null>(null);
  const [lunarIndex, setLunarIndex] = useState<any | null>(null);

  useEffect(() => {
    if (!gridData && context.isLoggedIn && context.isSetup && context.groups) {
      actions.GetAllGroupMessagesForUser();
      setGroups(context.groups);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, context.isLoggedIn, context.isSetup]);

  useEffect(() => {
    if (context.groupMessages && context.groupMessages.length > 0) {
      const messagesIndex = lunr(function () {
        this.ref("Id");
        this.field("Subject");
        this.field("Transcript");

        context.groupMessages.forEach((m: any) => {
          this.add({
            Id: m.Id,
            Subject: m.Subject,
            Transcript: m.Transcript,
          });
        });
      });

      setLunarIndex(messagesIndex);
    }
  }, [context.groupMessages]);

  const displayDate = (_date: Date) => {
    let _dateLines = _date.toLocaleString().split(",");

    return _dateLines.length ? (
      <>
        {_dateLines[0]}
        <br /> {_dateLines[1]}
      </>
    ) : null;
  };

  const getMessagesForGroup = (groupId: any) => {
    if (!context || !context.groupMessages) {
      return [];
    }

    return context.groupMessages.filter((message: any) =>
      message.Targets?.some(
        (target: any) => target.Type === "group" && target.Id === groupId
      )
    );
  };

  const handleSelectAllChange = (event: any) => {
    setSelectAll(event.target.checked);

    // If SelectAll is checked, mark all groups as selected
    // otherwise, mark all groups as unselected
    const updatedGroups = groups?.map((group) => ({
      ...group,
      IsSelected: event.target.checked,
    }));

    setGroups(updatedGroups);
  };

  const handleSearchPhraseChange = (event: any) => {
    setSearchString(event.target.value);
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    const selectedGroups = groups?.filter((group) => group.IsSelected);
    if (!selectedGroups || selectedGroups.length === 0) {
      setNoGroup(true);
      return;
    }

    if (!searchString || searchString.length === 0) {
      setNoSearchPhrase(true);
      return;
    }
    setNoGroup(false);
    setNoSearchPhrase(false);

    if (!context || !context.groupMessages) {
      return [];
    }

    let messagesForSelectedGroups: any = [];
    selectedGroups.forEach((group) => {
      const messagesForGroup = getMessagesForGroup(group.Id);

      messagesForSelectedGroups = [
        ...messagesForSelectedGroups,
        ...messagesForGroup,
      ];
    });

    if (lunarIndex) {
      const results = lunarIndex.search(searchString);

      let filteredMessages = results
        .map((result: any) => {
          const matchedMessage = messagesForSelectedGroups.find(
            (message: any) => message.Id === result.ref
          );
          return matchedMessage
            ? { message: matchedMessage, score: result.score }
            : null;
        })
        .filter((item: any) => item !== null); // this will filter out any null objects

      // filter out duplicates
      filteredMessages = filteredMessages.filter(
        (message: any, index: any, self: any) =>
          index ===
          self.findIndex((msg: any) => msg.message.Id === message.message.Id)
      );

      setGridData(filteredMessages);
    }

    //Old search using only keywords in transcript and subject
    // filter messages by searchString in transcript or subject
    // let filteredMessages = messagesForSelectedGroups.filter(
    //   (message: any) =>
    //     message.Transcript?.toLowerCase().includes(
    //       searchString.toLowerCase()
    //     ) || message.Subject?.toLowerCase().includes(searchString.toLowerCase())
    // );
  };

  const handleClear = () => {
    setSearchString("");

    if (groups) {
      const updatedGroups = groups?.map((item, index) =>
        Object.assign(item, { IsSelected: false })
      );
      setGroups(updatedGroups);
    }

    setSelectAll(false);
    setGridData(null);
  };

  const handleGroupsOnChange = (grp: Group) => {
    const updatedGroups = groups?.map((item, index) =>
      item.Id === grp.Id
        ? Object.assign(item, { IsSelected: !item.IsSelected })
        : item
    );

    setGroups(updatedGroups);

    // If all groups are selected, also check the "Select All" checkbox
    const allSelected = updatedGroups?.every((group) => group.IsSelected);
    setSelectAll(allSelected ? true : false);
  };

  return (
    <>
      <div className="sidebar">
        <div className="boldBlack navTitle">Fireflies</div>
        <div className="filterLink mt-2" style={{ minWidth: 150 }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <span style={{ verticalAlign: "middle", color: "#3c4858" }}>
              Return
            </span>
            <i
              className="material-icons ml-1"
              style={{ verticalAlign: "middle" }}
            >
              keyboard_return
            </i>
          </Link>
        </div>
      </div>
      <main className="content">
        <h3>Advanced Group Search</h3>
        <p>
          Enter a search phrase and select at least one Group to search within.
          The Fireflies will be searched by Transcript and Subject and any
          matches are displayed below. You can use operators such as AND, OR,
          NOT, and parentheses to create complex search phrases.{" "}
        </p>

        <span>Search uses Lunr.js expressions. Here are a few examples:</span>
        <ul>
          <li>OR: "foo bar"</li>
          <li>AND: "+foo +bar"</li>
          <li>NOT: "+foo -bar"</li>
          <li>LIKE: "foo*"</li>
          <li>BOOST: "foo^10 bar"</li>
          <li>FUZZY: "foo~1"</li>
        </ul>
        <a href="https://lunrjs.com/guides/searching.html" target="_blank">
          Search Guide
        </a>
        <hr />
        {context && context.isLoggedIn && context.isSetup && context.groups && (
          <Form noValidate>
            <Form.Group controlId="groupsInSearch">
              <Form.Row>
                <Col>
                  <Form.Check
                    inline
                    key="select-all"
                    className={noGroup ? "" : ""}
                    label="Select All"
                    name="select-all"
                    type="switch"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    id="select-all"
                  />
                </Col>
              </Form.Row>

              <hr />
              <Form.Row>
                <Col>
                  {groups &&
                    groups.map((_group: Group, index: number) => (
                      <Form.Check
                        inline
                        key={_group.Id}
                        className={noGroup ? "" : ""}
                        label={_group.Name}
                        name={_group.Id}
                        type="switch"
                        checked={_group.IsSelected}
                        onChange={() => handleGroupsOnChange(_group)}
                        value={_group.Id}
                        id={_group.Id}
                      />
                    ))}
                </Col>
              </Form.Row>
              {noGroup && (
                <Form.Row>
                  <Col>
                    <span className="error">Select at least one Group</span>
                  </Col>
                </Form.Row>
              )}
              <hr />

              <Form.Row className="d-flex align-items-center">
                <Col>
                  <input
                    className="input"
                    type="text"
                    id="test"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSearch(event);
                      }
                    }}
                    placeholder="Enter advanced search here..."
                    value={searchString}
                    onChange={handleSearchPhraseChange}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="ml-1 mr-4"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <button
                    onClick={handleClear}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Clear
                  </button>
                </Col>
                <Col></Col>
              </Form.Row>
              {noSearchPhrase && (
                <Form.Row>
                  <Col>
                    <span className="error">Enter a search phrase</span>
                  </Col>
                </Form.Row>
              )}
            </Form.Group>
          </Form>
        )}
        {gridData && Array.isArray(gridData) && (
          <div className="mt-3">
            <h4>Search Results</h4>
            {gridData && gridData.length > 0 ? (
              <>
                <strong>{gridData.length} results</strong>
                <div className="mt-3">
                  <div className="row">
                    {gridData.map((row: any, index: number) => (
                      <div key={index} className="col-md-3 mb-4">
                        <div
                          className="card h-100 border shadow rounded card-hover"
                          style={{ width: "100%" }}
                        >
                          <div
                            onClick={(event) => {
                              event.preventDefault();
                              setActiveMessage(row.message);
                              setShowMessage(true);
                            }}
                            className="position-relative btn-link"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={row.message.ThumbnailUrl}
                              className="card-img-top"
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                event.preventDefault();
                                setActiveMessage(row);
                                setShowMessage(true);
                              }}
                              alt="Thumbnail"
                            />
                            <span className="material-icons play-icon">
                              play_circle_outline
                            </span>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setActiveMessage(row.message);
                                  setShowMessage(true);
                                }}
                              >
                                {row.message.Subject}
                              </div>
                            </h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                              Group:{" "}
                              {
                                row.message.Targets.filter(
                                  (t: any) => t.Type === "group"
                                )[0].Name
                              }
                            </h6>
                            <p className="card-text text-truncate">
                              {row.message.Transcript?.substring(0, 50)}
                            </p>
                            <p className="card-text">
                              {displayDate(row.message.CreatedDate)}{" "}
                            </p>
                            <p className="card-text">
                              <small className="text-muted">
                                Score: {row.score}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <strong>No results</strong>
            )}
          </div>
        )}
      </main>
      <Modal
        show={showMessage}
        onHide={() => {
          setShowMessage(false);
        }}
        animation={false}
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header className="img-grid" closeButton>
          <h5 className="modal-title">
            {activeMessage && showMessage && (
              <>
                {activeMessage.Subject}
                {activeMessage.From && <> &nbsp; ({activeMessage.From})</>}
              </>
            )}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {activeMessage && showMessage && (
            <Message
              message={activeMessage}
              seekTime={null}
              handleReply={null}
              handleViewedBy={null}
              keywordSearch={searchString}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
