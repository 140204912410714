import React, { useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useActions } from "../context/actions";
import { ImportFileList } from "../components/ImportFileList";
import { Button, Form, Col } from "react-bootstrap";
import { Group } from "../types";
import { store } from "../context/store";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string | undefined;
    webkitdirectory?: string | undefined;
  }
}

export const ImportFiles = () => {
  const inputFileMultipleRef = useRef<HTMLInputElement>(null);
  const inputFolderMultipleRef = useRef<HTMLInputElement>(null);

  const actions = useActions();
  const { state } = useContext(store);
  const context = state;

  const [groups, setGroups] = useState<Group[] | undefined>(context.groups);
  const [importFiles, setImportFiles] = useState<any[]>([]);
  const [noGroup, setNoGroup] = useState(false);
  const [target, setTarget] = useState<string>("public");
  const [useAI, setUseAI] = useState<boolean>(true);

  //Azure Storage config
  const { BlobServiceClient } = require("@azure/storage-blob");
  const { v1: uuidv1 } = require("uuid");
  const appConfig = require("@azure/app-configuration");

  const connectionString =
    process.env["REACT_APP_APPCONFIG_CONNECTION_STRING"] ||
    "<connection string>";

  const appConfigclient = new appConfig.AppConfigurationClient(
    connectionString
  );

  const getAzureAppConfigSetting = async (settingKey: string) => {
    let retrievedSetting = await appConfigclient.getConfigurationSetting({
      key: settingKey,
      label:
        process.env["REACT_APP_ENVIRONMENT"] === "DEV"
          ? null
          : process.env["REACT_APP_ENVIRONMENT"],
    });

    return retrievedSetting.value as string;
  };

  const uploadFile = async (f: any): Promise<string | undefined> => {
    // try {
    const uploadSASEndpoint: string = await getAzureAppConfigSetting(
      "FireflyWeb:UploadSASEndpoint"
    );

    console.log(`Uploading file...${f.file.name}`);

    setImportFiles((prevState) =>
      prevState.map((obj) =>
        obj.id === f.id ? Object.assign(obj, { status: "uploading" }) : obj
      )
    );

    // Create a unique name for the blob
    const messageId = uuidv1();

    const blobServiceClient = new BlobServiceClient(uploadSASEndpoint);
    const containerName = "incoming";
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = messageId + "=FF=" + context.account.UserId;

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    let blob: any = f.file; //localFileBlob;

    console.log(`Blob size ${blob.size}} - Type: ${blob.type}`);
    let fileBytes: number = parseInt(blob.size);
    let uploadedBytes: number = 0;

    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(blob, {
      blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20, // 20 concurrency
      onProgress: (ev: any) => {
        console.log(ev);
        uploadedBytes = ev.loadedBytes;

        setImportFiles((prevState) =>
          prevState.map((obj) =>
            obj.id === f.id
              ? Object.assign(obj, {
                  progress: Math.round((uploadedBytes / fileBytes) * 100),
                })
              : obj
          )
        );
      },
      blobHTTPHeaders: { blobContentType: blob.type },
    });

    console.log(uploadBlobResponse);

    setImportFiles((prevState) =>
      prevState.map((obj) =>
        obj.id === f.id ? Object.assign(obj, { status: "uploaded" }) : obj
      )
    );

    return messageId;
  };

  const handleGroupsOnChange = (grp: Group) => {
    const updatedCheckedState = groups?.map((item, index) =>
      item.Id === grp.Id
        ? Object.assign(item, { IsSelected: !item.IsSelected })
        : item
    );

    setGroups(updatedCheckedState);
  };

  const saveMessage = async (
    storageLocator: string | undefined,
    subject: string | undefined,
    id: number,
    currFilename: string,
    is4K: boolean
  ) => {
    var _targets =
      target === "public"
        ? [
            {
              type: "public",
              name: "public",
              id: "",
            },
          ]
        : target === "group"
        ? groups
            ?.filter((e) => e.IsSelected === true)
            .map((e) => ({
              type: "group",
              name: e.Name,
              id: e.Id,
            }))
        : [];

    let msgRequest: object = {
      id: storageLocator,
      userId: context.account.UserId,
      from: context.account.EmailAddress,
      subject: subject?.length === 0 ? currFilename : subject,
      recordingType: "IMPORT",
      incomingLocation: storageLocator,
      targets: _targets,
      parentId: null,
      is4K: is4K,
      aiEnabled: useAI,
    };

    console.log(msgRequest);

    actions
      .CreateMessage(msgRequest)
      .then(function (data) {
        console.log("Created message:", data);
      })
      .catch((err: any) => {
        setImportFiles((prevState) =>
          prevState.map((obj) =>
            obj.id === id
              ? Object.assign(obj, { status: "error", error: err.toString() })
              : obj
          )
        );
        console.log(err);
      });
  };

  const handleSelectFiles = () => {
    inputFileMultipleRef.current!.click();
  };

  const handleSelectFolders = () => {
    inputFolderMultipleRef.current!.click();
  };

  const is4KResolution = async (file: File): Promise<boolean> => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    return new Promise<boolean>((resolve) => {
      video.addEventListener("loadedmetadata", () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve(width >= 3840 && height >= 2160);
      });
    });
  };

  const handleFoldersChanged = async (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    let filesWithData: any[] = [];

    let maxId = 0;

    if (importFiles.length !== 0) {
      const ids = importFiles.map((obj) => {
        return obj.id;
      });

      maxId = Math.max(...ids);
    }

    for (const item of chosenFiles) {
      const isFound = importFiles.some((element) => {
        if (element.file.name === item.name) {
          return true;
        }

        return false;
      });

      if (isFound) return;

      let isFile4K = await is4KResolution(item);

      maxId++;
      filesWithData.push({
        id: maxId,
        file: item,
        subject: item.name,
        status: "pending",
        progress: 0,
        error: "",
        is4K: isFile4K,
      });
    }

    setImportFiles([...importFiles, ...filesWithData]);
  };

  const handleFilesChanged = async (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    let filesWithData: any[] = [];

    let maxId = 0;

    if (importFiles.length !== 0) {
      const ids = importFiles.map((obj) => {
        return obj.id;
      });

      maxId = Math.max(...ids);
    }

    for (const item of chosenFiles) {
      const isFound = importFiles.some((element) => {
        if (element.file.name === item.name) {
          return true;
        }

        return false;
      });

      if (isFound) return;

      let isFile4K = await is4KResolution(item);

      maxId++;
      filesWithData.push({
        id: maxId,
        file: item,
        subject: item.name,
        status: "pending",
        progress: 0,
        error: "",
        is4K: isFile4K,
      });
    }

    setImportFiles([...importFiles, ...filesWithData]);
  };

  const handleUpload = () => {
    //Get selected groups
    if (
      target === "group" &&
      groups?.filter((e) => e.IsSelected === true).length === 0
    ) {
      setNoGroup(true);
      return;
    } else {
      setNoGroup(false);
    }

    for (let i = 0; i < importFiles.length; i++) {
      //only upload those that are pending or previously errored out (retry)
      if (
        importFiles[i].status === "pending" ||
        importFiles[i].status === "error"
      ) {
        let currFilename = importFiles[i].file.name;
        // eslint-disable-next-line no-loop-func
        uploadFile(importFiles[i])
          .then((storageLocator) => {
            saveMessage(
              storageLocator,
              importFiles[i].subject,
              importFiles[i].id,
              currFilename,
              importFiles[i].is4K
            ).then(() => {
              console.log("Saved Message to DB...");

              setImportFiles((prevState) =>
                prevState.map((obj) =>
                  obj.id === importFiles[i].id
                    ? Object.assign(obj, { status: "done" })
                    : obj
                )
              );
            });
          })
          .catch((err: any) => {
            console.log(err.toString());
            setImportFiles((prevState) =>
              prevState.map((obj) =>
                obj.id === importFiles[i].id
                  ? Object.assign(obj, {
                      status: "error",
                      error: err.message,
                    })
                  : obj
              )
            );
          });
      }
    }
  };

  const handleClear = () => {
    setImportFiles([]);
    inputFileMultipleRef.current!.value = "";
    inputFolderMultipleRef.current!.value = "";
  };

  const handleDelete = (id: number) => {
    const listItems = importFiles.filter((item) => item.id !== id);
    setImportFiles(listItems);

    inputFileMultipleRef.current!.value = "";
    inputFolderMultipleRef.current!.value = "";
  };

  const handleSubject = (subject: string, id: number) => {
    setImportFiles((prevState) =>
      prevState.map((obj) =>
        obj.id === id ? Object.assign(obj, { subject: subject }) : obj
      )
    );
  };

  return (
    <>
      <div className="sidebar">
        <div className="boldBlack navTitle">Fireflies</div>
        <div className="filterLink mt-2" style={{ minWidth: 150 }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <span style={{ verticalAlign: "middle", color: "#3c4858" }}>
              Return
            </span>
            <i
              className="material-icons ml-1"
              style={{ verticalAlign: "middle" }}
            >
              keyboard_return
            </i>
          </Link>
        </div>
      </div>
      <main className="content">
        <h3>Bulk Import</h3>
        <p>
          Select one or more local video files to upload and create Fireflies
          from. Select whether these will be created as Public Fireflies or
          targeted to one or more Groups you belong to. These will be sent from
          yourself, and with a default Subject equal to the filename. You may
          optionally override the Subject per-file prior to Upload.
        </p>
        <p>
          Note: status of "done" here means the file has been successfully
          submitted to Firefly for encoding. It may still be several minutes
          until your FF has been created (at which point you will receive email
          confirmation). You can also view the status of these imported
          Fireflies in the My Public section, or within the specific Group.
        </p>
        <input
          type="file"
          multiple
          ref={inputFileMultipleRef}
          onChange={handleFilesChanged}
          accept="video/*"
          id="local-file-multiple"
          style={{ display: "none" }}
        />
        <Button
          variant="secondary"
          className="ml-1 mr-1 mt-1"
          onClick={handleSelectFiles}
        >
          Select Files...
        </Button>

        <input
          type="file"
          multiple
          ref={inputFolderMultipleRef}
          onChange={handleFoldersChanged}
          webkitdirectory=""
          directory=""
          accept="video/*"
          id="local-folder-multiple"
          style={{ display: "none" }}
        />
        <Button
          variant="secondary"
          className="ml-1 mr-1 mt-1"
          onClick={handleSelectFolders}
        >
          Select Folders...
        </Button>

        {importFiles.length ? (
          <>
            <hr />

            <Form noValidate>
              <Form.Group controlId="importMessages">
                <Form.Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Use OpenAI for Summarization"
                      type="switch"
                      id="useAISwitch"
                      style={{ marginBottom: 10 }}
                      checked={useAI}
                      onChange={(event: any) => {
                        setUseAI(event.target.checked);
                      }}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Control
                      as="select"
                      className="message-box"
                      onChange={(event: any) => {
                        setTarget(event.target.value);
                      }}
                      value={target}
                    >
                      <option value="public">Public</option>
                      {context && context.groups && (
                        <option value="group">Group</option>
                      )}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      className="ml-1"
                      onClick={handleUpload}
                    >
                      Upload
                    </Button>
                  </Col>
                  <Col></Col>
                </Form.Row>

                {target === "group" && (
                  <Form.Row>
                    <Col>
                      {context &&
                        context.groups &&
                        context.groups.map((_group: Group, index: number) => (
                          <Form.Check
                            inline
                            key={_group.Id}
                            className={noGroup ? "" : ""}
                            label={_group.Name}
                            name={_group.Id}
                            type="switch"
                            checked={_group.IsSelected}
                            onChange={() => handleGroupsOnChange(_group)}
                            value={_group.Id}
                            id={_group.Id}
                          />
                        ))}
                    </Col>
                  </Form.Row>
                )}
                {target === "group" && noGroup && (
                  <Form.Row>
                    <Col>
                      <span className="error">Select at least one Group</span>
                    </Col>
                  </Form.Row>
                )}
              </Form.Group>
            </Form>
            <hr />
            <span style={{ fontSize: "1.1em" }}>
              {importFiles.filter((e) => e.status === "done").length} /{" "}
              {importFiles.length} Files Imported
            </span>
            <Button
              variant="secondary"
              className="ml-3"
              onClick={handleClear}
              disabled={
                importFiles.filter((e) => e.status === "done").length !==
                  importFiles.length &&
                importFiles.filter((e) => e.status === "done").length !== 0
              }
            >
              Clear
            </Button>
            <hr />
            <ImportFileList
              items={importFiles}
              handleDelete={handleDelete}
              handleSubject={handleSubject}
            />
          </>
        ) : (
          <p style={{ marginTop: "2rem" }}>No files selected.</p>
        )}
      </main>
    </>
  );
};
