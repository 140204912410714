import { TooltipProps } from "react-tippy";
import type {
  CAPTURE_TYPE,
  RecordWebcamHook,
} from "./components/react-record-webcam/src";

// sort type declarations
export type sortType = "none" | "string" | "number" | "date" | "name";
export type sortDirection = "asc" | "desc";
export type sortColumn = {
  column: columnType;
  direction: sortDirection;
};
export type columnType = {
  title: string;
  value: string;
  className: string;
  sortType: sortType;
  visible: boolean;
};

// filter type declarations
export const ALL_FILTERS = ["Inbox", "Sent", "ADO", "Public"] as const;
export type filterTypes = typeof ALL_FILTERS;
export type filterType = filterTypes[number]; // union type

export enum ALL_HOTKEYS {
  New = "New",
  Record = "Record",
  Stop = "Stop",
  Pause = "Pause",
  Resume = "Resume",
  Retake = "Retake",
  Camera = "Camera",
  Screen = "Screen",
  Local = "Import",
  Empty = "Empty",
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  View = "View",
  Delete = "Delete",
  Reply = "Reply",
  Copy = "Copy",
  Inbox = "Inbox",
  Sent = "Sent",
  ADO = "ADO",
  Filter = "Filter",
  Check = "Check",
  CheckAll = "CheckAll",
  Download = "Download",
  Public = "Public",
  Audio = "Audio",
  PDF = "PDF",
}

export type hotKeyMap = {
  [index: string]: ALL_HOTKEYS;
};

export interface NewMessageProps {
  message?: any;
  suggestions?: string[];
  onSave?: (id: string) => void;
  autoRecordType?: CAPTURE_TYPE | null;
}

export interface InfoPanelProps {
  vttTranscript?: any[];
  title?: string;
  info?: any;
  player?: any;
  keywordSearch: any;
}
export interface FireFlyProps {
  recordWebcam: RecordWebcamHook;
  progress: number;
  uploading: boolean;
  hotKey: ALL_HOTKEYS;
  getLocalFile(blob: Blob, is4K: boolean): void;
  setAISetting(aiSetting: boolean): void;
  aiActive: boolean;
}

export interface MessageListProps {
  messages: any[];
  sort: sortColumn;
  hotKey: { value: ALL_HOTKEYS };
  isAllSelected: boolean;
  filter: string;
  checkCount: number;
  keywordSearch: string;
  handleSort: (column: columnType) => void;
  handleView: (id: string) => void;
  handleTree: (id: string) => void;
  handleReply: (id: string) => void;
  handleDelete: (id: string) => void;
  handleCheck: (checkName: string, isChecked: boolean) => void;
  handleDownload: (id: string) => void;
  showTranscripts: boolean;
  handleTranscriptPDF: (id: string) => void;
}

export interface ImportFileListProps {
  items: any[];
  handleDelete: (id: number) => void;
  handleSubject: (subject: string, id: number) => void;
}

export interface ImportFileListItemProps {
  item: any;
  handleDelete: (id: number) => void;
  handleSubject: (subject: string, id: number) => void;
}

export interface ManagedUserListProps {
  items: any[];
  checkCount: number;
  selectedReset: boolean;
  selectedGroup: string;
  isAllSelected: boolean;
  sort: sortColumn;
  keywordSearch: string;
  handleSort: (column: columnType) => void;
  handleCheck: (checkName: string, isChecked: boolean) => void;
}

export interface ManagedUserListItemProps {
  item: any;
  rowNum: number;
  active: any;
  setSelectedIndex: any;
  handleCheck: (checkName: string, isChecked: boolean) => void;
}

export interface ActionBarProps extends TooltipProps {
  children: any;
}

export interface TeamMember {
  Id: string;
  parentId: string;
  name: string;
  email: string;
}

export interface Group {
  Id: string;
  Name: string;
  IsSelected: boolean;
}

export interface ManagedGroup {
  id: string;
  type: string;
  name: string;
  isActive: boolean;
}
