import React, { useRef } from "react";
import { ManagedUserListItemProps } from "../types";

import { Tooltip } from "react-tippy";
import CheckBox from "./CheckBox";

const ManagedUserListItem = ({
  item,
  rowNum,
  active,
  setSelectedIndex,
  handleCheck,
}: ManagedUserListItemProps) => {
 
  const handleFocus = (event: any) => event.target.select();
  const toolRef = useRef<any>();
  const displayDate = (_date: Date) => {
    if (true){
      return _date.toLocaleString();
    }
    let _dateLines = _date.toLocaleString().split(",");

    return _dateLines.length ? (
      <>
        {_dateLines[0]}
        <br /> {_dateLines[1]}
      </>
    ) : null;
  };

  return (
    <>
      <tr 
        key={`${rowNum}_row`}
        className={` tableHeight ${active || item.checked ? "active" : ""}`}
        onClick={() => {
          setSelectedIndex(rowNum);
        }}
      >
        <td className="mgdUserCheck">
          {" "}
          <CheckBox
            key={`${rowNum}_cbx`}
            name={""}
            value={item.Id}
            tick={item.checked}
            overlay={false}
            onCheck={(e: any) => {
              setSelectedIndex(rowNum);
              handleCheck(item.Id, e.target.checked);
            }}
          />
          {" "}
        </td>
        <td className="mgdUserName">
          <Tooltip
            trigger="mouseenter"
            html={<div>{item.id}</div>}
            arrow={true}
            position="bottom"
            theme="light"
          >
            <span>{item.FullName}</span>
          </Tooltip>
        </td>
        <td className="mgdUserEmail">
          <span>{item.EmailAddress}</span>
        </td>
        <td className="mgdUserLastLogin">
          <span>{displayDate(item.LastLoginDate)}</span>
        </td>
      </tr>
    </>
  );
};

export default ManagedUserListItem;
