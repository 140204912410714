import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import VideoPlayer from "../components/video-player";
import InfoPanel from "../components/InfoPanel";
import loadingimg from "../assets/loading_spinner.png";
import OpenAILogo from "../assets/openai.png";
import AudioFFPlaceholder from "../assets/placeholder-audio.png";
import { useActions } from "../context/actions";

export const Message = ({
  message,
  handleReply,
  handleViewedBy,
  seekTime,
  keywordSearch,
}: any) => {
  const [videoSource, setVideoSource] = useState<any | null>(null);
  const [trackSource, setTrackSource] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const [player, setPlayer] = useState<any | null>(null);
  const actions = useActions();
  const [vttTranscript, setVttTranscript] = useState<any[]>([]);

  useEffect(() => {
    if (
      message &&
      typeof message === "object" &&
      Array.isArray(message.Targets) &&
      message.Targets.length > 0 &&
      message.Targets[0].Type === "public"
    ) {
      setIsPublic(true);
    }

    // for now we can't use this as the VTT file is not accessible
    // from within the MK asset container - so we load captions using a separate API call
    // let searchVal: string = "/manifest.ism/manifest";
    // let trackSrc: string = message.StreamingUrl.toString().replace(
    //   searchVal,
    //   "/transcript.vtt"
    // );

    if (message) {
      //call Transcript API to return the SAS Url for captions file
      actions.GetTranscriptUrl(message.Id).then((response: any) => {
        let trackSrc: string = response;

        setTrackSource([
          {
            id: "sub1",
            lang: "en",
            label: "English",
            url: trackSrc,
            kind: "subtitle",
          },
        ]);

        setVideoSource([
          {
            src: message.StreamingUrl,
            type: "application/vnd.ms-sstr+xml",
          },
        ]);
      });
    }

    if (handleViewedBy) {
      handleViewedBy(message);
    }

    console.log(message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayVTT = (t: any) => {
    setVttTranscript(t);
  };

  return (
    <>
      {!ready && (
        <div className="video-placeholder-frame">
          <div className="video-placeholder">
            <img className="center-me working" alt="FireFly" src={loadingimg} />
          </div>
        </div>
      )}
      <div
        className="video-player"
        style={{ display: `${ready ? "block" : "none"}` }}
      >
        {message && message.RecordingType === "AUDIO" && (
          <img
            className={`overlay`}
            src={AudioFFPlaceholder}
            alt="Audio-Only Firefly"
            style={{ zIndex: 1 }}
          />
        )}

        {videoSource && trackSource && (
          <VideoPlayer
            src={videoSource}
            track={trackSource}
            enablePlaybackSpeed={!isPublic}
            autoLoop={isPublic}
            seekTime={seekTime}
            displayVTT={displayVTT}
            onPlayer={(_player: any) => {
              setPlayer(_player);
            }}
            onEvent={(eventType: string) => {
              switch (eventType) {
                case "playing":
                case "ready":
                  setReady(true);
                  break;
              }
              console.log(eventType);
            }}
          />
        )}
      </div>

      {message.Summary && (
        <div className="summary-panel">
          <img
            src={OpenAILogo}
            alt="Generated by OpenAI"
            title="Generated by OpenAI"
          />{" "}
          {message.Summary}
        </div>
      )}

      {message &&
        typeof message === "object" &&
        Array.isArray(message.Targets) &&
        message.Targets.length > 0 &&
        message.Targets[0].Type === "project" &&
        videoSource &&
        handleReply && (
          <div className="row ado-panel">
            <div className="alignLeft">
              ADO Work Item #:{" "}
              <a
                href={`https://dev.azure.com/murphyassoc/${message.Targets[0].Name}/_workitems/edit/${message.Targets[0].Id}`}
                rel="noreferrer"
                target="_blank"
              >
                {message.Targets[0].Id}
              </a>
            </div>
          </div>
        )}

      <div className="row mt-2">
        {message &&
        typeof message === "object" &&
        message.Transcript &&
        message.Transcript !== "" ? (
          <div className="alignLeft">
            <InfoPanel
              title="View Transcript"
              info={message.Transcript}
              vttTranscript={vttTranscript}
              player={player}
              keywordSearch={keywordSearch}
            />
          </div>
        ) : (
          ""
        )}

        {message &&
          typeof message === "object" &&
          Array.isArray(message.Targets) &&
          message.Targets.length > 0 &&
          message.Targets[0].Type === "email" &&
          videoSource &&
          ready &&
          handleReply && (
            <div>
              <Button
                onClick={() => {
                  if (handleReply) handleReply(message.Id);
                }}
                variant="success"
                className="ml-1 ali"
                style={{ display: "inline-block" }}
              >
                Reply
              </Button>
            </div>
          )}
        {message &&
          typeof message === "object" &&
          Array.isArray(message.Targets) &&
          message.Targets.length > 0 &&
          message.Targets[0].Type === "project" &&
          videoSource &&
          handleReply && (
            <div className="row ml-2">
              <div>
                <Button
                  onClick={() => {
                    if (handleReply) handleReply(message.Id);
                  }}
                  variant="success"
                  className="ali"
                >
                  Reply
                </Button>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
