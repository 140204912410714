// @ts-ignore
import { RecordRTCPromisesHandler } from "recordrtc";
import {
  RecorderOptions,
  Recorder,
  CAPTURE_TYPE,
  CaptureConsrtaints,
} from "./types";

type CaptureUserMedia = {
  stream: MediaStream | undefined;
  recordRtc: any;
  secondaryStreams: MediaStream[] | undefined;
};

type StreamContainer = {
  stream: MediaStream | undefined;
  secondaryStreams: MediaStream[] | undefined;
};

const mergeAudioStreams = (
  desktopStream: MediaStream,
  voiceStream: MediaStream
) => {
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();
  let hasDesktop = false;
  let hasVoice = false;
  if (desktopStream && desktopStream.getAudioTracks().length > 0) {
    // If you don't want to share Audio from the desktop it should still work with just the voice.
    const source1 = context.createMediaStreamSource(desktopStream);
    const desktopGain = context.createGain();
    desktopGain.gain.value = 0.7;
    source1.connect(desktopGain).connect(destination);
    hasDesktop = true;
  }

  if (voiceStream && voiceStream.getAudioTracks().length > 0) {
    const source2 = context.createMediaStreamSource(voiceStream);
    const voiceGain = context.createGain();
    voiceGain.gain.value = 0.7;
    source2.connect(voiceGain).connect(destination);
    hasVoice = true;
  }

  return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
};

async function getScreenStream() {
  try {
    // @ts-ignore
    let screenStream = await navigator.mediaDevices.getDisplayMedia({
      audio: true,
      video: true,
    });

    let cameraMicStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    //merge the audio from the Mic and system audio from the screenshare into a new MediaStream
    const tracks = [
      ...screenStream.getVideoTracks(),
      ...mergeAudioStreams(screenStream, cameraMicStream),
    ];

    let newStream = new MediaStream(tracks);

    //need to return the original Streams used in the merge, as secondaryStreams so that we can close them later
    return {
      stream: newStream,
      secondaryStreams: [screenStream, cameraMicStream],
    };
  } catch (e) {
    throw new Error(e.message);
  }
}

async function captureUserMedia(
  options: RecorderOptions,
  captureType: CAPTURE_TYPE,
  constraints: CaptureConsrtaints
): Promise<CaptureUserMedia> {
  let streamContainer: StreamContainer = {
    stream: undefined,
    secondaryStreams: undefined,
  };
  let stream: MediaStream | undefined;
  let secondaryStreams: MediaStream[] | undefined;

  if (captureType === CAPTURE_TYPE.SCREEN) {
    streamContainer = await getScreenStream();
    stream = streamContainer.stream;
    secondaryStreams = streamContainer.secondaryStreams;
  } else {
    let primaryStream = await navigator.mediaDevices.getUserMedia(constraints);
    streamContainer = { stream: primaryStream, secondaryStreams: undefined };
    stream = primaryStream;
    secondaryStreams = streamContainer.secondaryStreams;
  }

  const recordRtc = new RecordRTCPromisesHandler(streamContainer.stream, {
    ...options,
  });

  return { stream, recordRtc, secondaryStreams };
}

async function recorder(
  options: RecorderOptions,
  captureType: CAPTURE_TYPE,
  constraints: CaptureConsrtaints
): Promise<Recorder> {
  if (!navigator.mediaDevices.getUserMedia) {
    throw new Error("Browser does not support getUserMedia");
  }

  const { stream, recordRtc, secondaryStreams } = await captureUserMedia(
    options,
    captureType,
    constraints
  );
  // extract the wrapped RTC object to be able to access setRecordingDuration
  const { recordRTC } = recordRtc;
  const { setRecordingDuration } = recordRTC;
  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    getBlob,
    getDataURL,
  } = recordRtc;

  return {
    stream,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    getBlob,
    getDataURL,
    setRecordingDuration,
    secondaryStreams,
  };
}

export { captureUserMedia };
export default recorder;
