import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { store } from "../context/store";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import fireflyNav from "../assets/fireflyNav.png";

import { RollOverImage } from "../components/RollOverImage";
import profile from "../assets/profile.png";
import profileHover from "../assets/profile_hover.png";

import { loginRequest, b2cPolicies } from "../authConfig";

export const NavigationBar = () => {
  const { instance } = useMsal();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { state } = globalState;
  // refer to the context state as just context to avoid
  // confusion between context and component state
  const context = state;

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 50em)");
    mediaQuery.addEventListener("change", setMQuery);

    // intialize
    setMQuery(mediaQuery);

    // this is the cleanup function to remove the listener
    return () => mediaQuery.removeEventListener("change", setMQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMQuery = (e) => {
    dispatch({
      type: "mediaquery",
      payload: e.matches,
    });
  };
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <Navbar bg="primary" expand="sm">
        <Navbar.Brand>
          <Link className="navLink" to="/list">
            <img width="200px" alt="FireFly" src={fireflyNav} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <AuthenticatedTemplate>
            <Nav className="ml-auto">
              <Nav.Link
                className="navLink mr-4"
                onClick={() => {
                  dispatch({ type: "logout" });
                  instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
            <RollOverImage
              altText="profile"
              enabled={true}
              title={
                context && context.isLoggedIn
                  ? context.account.EmailAddress
                  : ""
              }
              normal={profile}
              normalHover={profileHover}
              onClick={() => {
                instance
                  .loginPopup(b2cPolicies.authorities.editProfile)
                  .catch((error) => console.log(error));
              }}
            />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Nav className="ml-auto">
              <Nav.Link
                className="navLink mr-4"
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Login
              </Nav.Link>
            </Nav>
          </UnauthenticatedTemplate>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
